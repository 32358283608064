import { createSlice } from "@reduxjs/toolkit";

const { api_url } = require("../settings");

export const map = createSlice({
    name: "map",
    initialState: {
        display: true,
        fullScreen: false,
        layerCityBoundary: {
            enable: true,
            checked: true,
            initialDisplay: true,
            url: api_url + "maps/geojson/?table=boundary&schema_city=",
            zindex: 0,
            type: "single",
            popup: {
                default: {
                    content: {},
                    separators: {
                        decimal: [],
                        thousand: [],
                    },
                },
            },
            titleSideBar: "Zoning",
            style: {
                strokeColor: "#393E46",
                lineDash: [3, 8],
            },
            legend: {
                default: {
                    title: "Zoning",
                    subtitle: "",
                    attribute: "",
                    symbol: "rectangle",
                    legendCustomCssClass: "",
                    class: [
                        {
                            color: "rgba(0, 0, 0, 0)",
                            strokeWidth: 2,
                            label: "Boundaries",
                        },
                    ],
                },
            },
        },
    },
    reducers: {
        reverseMapDisplay: (state) => {
            state.display = !state.display;
        },
        reverseMapFullScreen: (state) => {
            state.fullScreen = !state.fullScreen;
        },
        resetMapFullScreen: (state) => {
            state.fullScreen = false;
        },
    },
});

export const { reverseMapDisplay, reverseMapFullScreen, resetMapFullScreen } =
    map.actions;

export default map.reducer;
