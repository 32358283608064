import { useEffect, useState } from "react";
import store from "../../store";
import { Box, Button, TextField } from "@mui/material";
import MuiToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import PopupInformation from "../PopupInformation";
import { styled } from "@mui/material/styles";
import { useSelector } from "react-redux";
import { capitalize } from "@mui/material";
import { Pie } from "react-chartjs-2";
import "../../styles/DigitalParticipation.css";

const { api_url } = require("../../settings");

const ToggleButton = styled(MuiToggleButton)(({ selectedcolor }) => ({
    '&.Mui-selected, &.Mui-selected:hover': {
      color: 'white',
      backgroundColor: selectedcolor,
    },
  }));

// Line form component
function FormLine(props) {
    const { content, bgColor, color, justify, width, radius} = props;
    return (
      <Box
        display = 'flex'
        justifyContent='center'
        p={0}
        m= {0}
        bgcolor="white"
        width={'100%'}
      >
        <Box
            display="flex"
            width={width?width:'75%'}
            bgcolor={bgColor}
            color={color}
            p={1}
            m={0}
            borderRadius= {radius?radius:2}
            justifyContent={justify}
        >
            {content}
        </Box>
    </Box>
    );
}

// Component for general informations
function InfoFormItem(props) {
    const { question, answers, colors, hint, userAnswers, setUserAnswers} = props;
    const [choice, setChoice] = useState([]);
    const handleChange = (event, choice, question) => {
        setChoice(choice);
        const newQuestions = userAnswers.infos_form.questions.map(q => {
            if (q.label === question) {
              return {...q, answer: choice};
            }
            return {...q}
        });

        setUserAnswers({
            ...userAnswers,
            infos_form: {
              questions:
                newQuestions
            }
          });
    };

    const content = <div>
        <label className="form-label">{question}</label>
        <ToggleButtonGroup
            color="primary"
            value={choice}
            exclusive
            onChange = {(event, choice) => {
                handleChange(event, choice, question);
            }}
            aria-label="Platform"
            >
            {answers.map((answer, idx_answer) => {
                let color = "#3e7aa4";
                const key = question + "_" + answer;
                if(colors) {
                    color = colors[idx_answer];
                }
                return <ToggleButton key={key} name={key} value={answer} selectedcolor={color}>{answer}</ToggleButton>
            })}
        </ToggleButtonGroup>
        {hint && <PopupInformation text={hint}/>}
    </div>

    return (
        <FormLine content={content}></FormLine>
    )
}

// Component for transport item
function ListTransportsUsed(props) {
    const { listTransportsUsed, userAnswers, setUserAnswers } = props;
    const [transports, setTransports] = useState([]);
    const handleChange = (event, choiceTransport) => {
        setTransports(choiceTransport);
        const newTransports =  [...choiceTransport]
        setUserAnswers({
            ...userAnswers,
            transports_used : newTransports
          });
    };
    const content = <div>
        <label className="form-label">{listTransportsUsed.label}</label>
        <ToggleButtonGroup
            color="primary"
            value={transports}
            exclusive={false}
            onChange = {(event, choiceTransport) => {
                handleChange(event, choiceTransport);
            }}
            aria-label="Platform"
            >
            {listTransportsUsed.answers.map((answer, idx_answer) => {
                let color = "#3e7aa4";
                const key = "transports_used_" + answer.label;
                if(listTransportsUsed.colors) {
                    color = listTransportsUsed.colors[idx_answer];
                }
                return <ToggleButton key={key} name={key} value={answer.label} selectedcolor={color}>{answer.label}</ToggleButton>
            })}
        </ToggleButtonGroup>
        {listTransportsUsed.hint && <PopupInformation text={listTransportsUsed.hint}/>}
    </div>

    return (
        <FormLine content={content}></FormLine>
    )
}

// Component for transport detail item
function ListTransportsUsedDetail(props) {
    const { listTransportsUsedDetail, userAnswers, setUserAnswers, mode} = props;
    const [transports, setTransports] = useState([]);
    const handleChange = (event, choiceTransport) => {
        setTransports(choiceTransport);
        const newTransportDetails= userAnswers.transports_used_detail.map(t => {
            if (t.mode === mode) {
              return {...t, answer: choiceTransport};
            }
            return {...t}
        });
        setUserAnswers({
            ...userAnswers,
            transports_used_detail : newTransportDetails
          });
    };

    const content = <div>
        <label className="form-label">{listTransportsUsedDetail.label}</label>
        <ToggleButtonGroup
            color="primary"
            value={transports}
            exclusive={false}
            onChange = {(event, choiceTransport) => {
                handleChange(event, choiceTransport);
            }}
            aria-label="Platform"
            >
            {listTransportsUsedDetail.answers.map((answer, idx_answer) => {
                let color = "#3e7aa4";
                const key = "transports_used_" + answer;
                if(listTransportsUsedDetail.colors) {
                    color = listTransportsUsedDetail.colors[idx_answer];
                }
                return <ToggleButton key={key} name={key} value={answer} selectedcolor={color}>{answer}</ToggleButton>
            })}
        </ToggleButtonGroup>
        {listTransportsUsedDetail.hint && <PopupInformation text={listTransportsUsedDetail.hint}/>}
    </div>

    return (
        <FormLine content={content}></FormLine>
    )
}

// Component for affirmations items
function AffirmationFormItem(props) {
    const { mode, affirmation, question, answers, colors, hint, userAnswers, setUserAnswers } = props;
    const [affirmationChoice, setAffirmationChoice] = useState('choice');
    const handleChange = (event, choice, question) => {
        setAffirmationChoice(choice);
        const newAffirmations = userAnswers.modes.questions.map(q => {
            if (q.mode === mode
             && q.affirmation === affirmation
             && q.subaffirmation === question) {
              return {...q, answer: choice};
            }
            return {...q}
        });
        setUserAnswers({
            ...userAnswers,
            modes: {
                questions :
                    newAffirmations
            }
          });
    };

    const content = <div>
        <label className="form-label form-affirmation-label">{question}</label>
        <ToggleButtonGroup
            color="primary"
            value={affirmationChoice}
            exclusive
            onChange = {(event, choice) => {
                handleChange(event, choice, question);
              }}
            aria-label="Platform"
            >
            {answers.map((answer, idx_answer) => {
                let color = "#3e7aa4";
                if(colors) {
                    color = colors[idx_answer];
                }
                return <ToggleButton key={idx_answer} value={answer} selectedcolor={color}>{answer}</ToggleButton>
            })}
        </ToggleButtonGroup>
        {hint && <PopupInformation text={hint}/>}
    </div>

    return (
        <FormLine content={content} width={'100%'} ></FormLine>
    )
}

// Component for why block
function WhyBlock(props) {
    const { mode, why, userAnswers, setUserAnswers } = props;
    const [whyChoice, setWhyChoice] = useState('choice');
    const handleChange = (event, choice) => {
        setWhyChoice(choice);
        const newAffirmations = userAnswers.modes.questions.map(q => {
            if (q.mode === mode
             && q.subaffirmation === "For what purpose ?") {
              return {...q, answer: choice};
            }
            return {...q}
        });
        setUserAnswers({
            ...userAnswers,
            modes: {
                questions :
                    newAffirmations
            }
          });
    };

    const content = <div>
        <label className="form-label">{why.label}</label>
        <ToggleButtonGroup
            color="primary"
            value={whyChoice}
            exclusive
            onChange = {(event, choice) => {
                handleChange(event, choice);
              }}
            aria-label="Platform"
            >
            {why.answers.map((answer, idx_answer) => {
                let color = "#3e7aa4";
                return <ToggleButton key={idx_answer} value={answer} selectedcolor={color}>{answer}</ToggleButton>
            })}
        </ToggleButtonGroup>
    </div>

    return (
        <FormLine content={content}></FormLine>
    )
}

// Component for mode titles
function ModeTitleItem(props) {
    const { mode } = props;
    const content = <div>
        <label className="mode-title">{mode.label}</label>
        <PopupInformation text="For each statement, select your level of agreement"/>
    </div>

    return (
        <FormLine content={content} color={"#ffffff"} bgColor={mode.color}></FormLine>
    )
}

// Component for affirmation block (multiple subaffirmations)
function AffirmationBlock(props) {
    const { mode, affirmation, userAnswers, setUserAnswers, currentCity } = props;
    const modesAvailableAnswers = store.getState().digitalParticipation.modes_answers;
    const modesColorsAnswers = store.getState().digitalParticipation.modes_colors_answers;

    const content = <div>
        <ul>
            {affirmation.subAffirmations.map((subAffirmation, idx_subAffirmation) => (
                <li key={idx_subAffirmation}>
                    <AffirmationFormItem
                        key={idx_subAffirmation}
                        mode={mode}
                        affirmation={affirmation.label}
                        question={subAffirmation}
                        answers={modesAvailableAnswers}
                        colors={modesColorsAnswers}
                        userAnswers={userAnswers}
                        setUserAnswers={setUserAnswers}
                    >
                    </AffirmationFormItem>
                </li>
            ))}
        </ul>
    </div>

    return (
        <Box
            display = 'flex'
            justifyContent='center'
            p={0}
            m= {1}
            bgcolor="#ffffff"
            className="block-affirmation"
        >
            <Box
                display="flex"
                width={'13%'}
                bgcolor={affirmation.color}
                color="white"
                p={'10px'}
                borderRadius= {"8px 0px 8px 8px"}
                className="box-vertical-center"
            >
                <div>{affirmation.label.replace("***", currentCity)}</div>

            </Box>

            <Box
                //display="flex"
                width={'60%'}
                alignItems="top"
                justifyContent="center"
                p={0}
                m={0}
            >
                <FormLine content={<div>{affirmation.label.replace("***", currentCity)}</div>} color={"#ffffff"} bgColor={affirmation.color} width={'100%'} radius={"0px 8px 8px 0px"}></FormLine>

                {content}
            </Box>
        </Box>
    )
}

// Component for mode titles
function ModalPartsResults(props) {
    const { results } = props;
    let content = undefined;
    if(Object.keys(results).length !== 0) {
        const labels = Object.keys(results);
        const data = [];
        labels.forEach(function(key) {
            data.push(results[key]);
        });
        const dataChart = {
            labels: labels,
            datasets: [
                {
                    label: 'Participation',
                    data: data,
                    backgroundColor: [
                    'rgba(255, 99, 132, 0.2)',
                    'rgba(54, 162, 235, 0.2)',
                    'rgba(255, 206, 86, 0.2)',
                    'rgba(75, 192, 192, 0.2)',
                    'rgba(153, 102, 255, 0.2)',
                    'rgba(255, 159, 64, 0.2)',
                    ],
                },
            ],
        };

        content = <div>
            So far, here are the results (%) of the participation concerning the modal split of daily trips :
            <Pie data={dataChart} />
        </div>
    }

    return (
        <FormLine justify={"center"} content={content}></FormLine>
    )
}

// Main component
export default function DigitalParticipation() {
    let currentCity = useSelector(
         (_state) => store.getState().cities.currentCity
    );
    currentCity = capitalize(currentCity)

    const modes = store.getState().digitalParticipation.modes;
    const listTransportsUsed = store.getState().digitalParticipation.transports_used;
    const listTransportsUsedDetail = store.getState().digitalParticipation.transports_used_detail;
    const why = store.getState().digitalParticipation.why;
    const infosForm = store.getState().digitalParticipation.infos_form;
    const [userAnswers, setUserAnswers] = useState({});
    const [displayThanksMessage, setDisplayThanksMessage] = useState(false);
    const [contactEmail, setContactEmail] = useState('');
    const [modalPartsResults, setModalPartsResults] = useState({});

    const initAnswers = () => {
        userAnswers['city'] = currentCity;
        userAnswers['infos_form'] = {questions: []};
        userAnswers['modes'] = {questions: []};
        userAnswers['transports_used'] = [];
        userAnswers['transports_used_detail'] = [];
        userAnswers['contact'] = '';
        for(const question of infosForm.questions) {
            userAnswers['infos_form'].questions.push({'label': question.label, answer : ''});
        }
        for(const mode of modes) {
            for(const affirmation of mode.affirmations) {
                for(const subAffirmation of affirmation.subAffirmations) {
                    userAnswers['modes'].questions.push({
                        mode: mode.label,
                        affirmation: affirmation.label,
                        subaffirmation: subAffirmation
                    });
                }
            }
            // Why
            userAnswers['modes'].questions.push({
                mode: mode.label,
                affirmation: '',
                subaffirmation: 'For what purpose ?'
            });
        }
        for(const transportDetail of listTransportsUsedDetail) {
            userAnswers['transports_used_detail'].push({
                label: transportDetail.label,
                mode: transportDetail.mode,
                mode_id: transportDetail.mode_id,
            });
        }
    }

    const getModFromTransport = (transport) => {
        for(const t of listTransportsUsed.answers) {
            if(t.label === transport) {
                return t.mode_form_id;
            }
        }
        return null;
    }

    const submitForm = () => {
        // TODO add captcha / i'm not a robot
        userAnswers.city = currentCity;
        const body = JSON.stringify({data: userAnswers});
        const headers = new Headers();
        headers.append('Content-Type', 'application/json');
        fetch(api_url + 'participation/add/', {
             method: 'POST',
             mode: 'cors',
             body: body,
             headers: headers,
        }).then(response => response.json())
        .then((data) => {
            setModalPartsResults(data.modal_parts);
            setDisplayThanksMessage(true);
        })
        .catch(e => console.log(e));
    }

    // Initialization
    useEffect(() => {
        initAnswers();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleContactChange = (e) => {
        let email = e.target.value.toLowerCase();
        setContactEmail(email);
        setUserAnswers({
            ...userAnswers,
            contact: email
        });
      };

    return (
        <div className="form-participation">
            {infosForm.questions.map((question, idx_question) => (
                <InfoFormItem
                    key={idx_question}
                    question={question.label}
                    answers={question.answers}
                    colors={question.colors}
                    hint={question.hint}
                    userAnswers={userAnswers}
                    setUserAnswers={setUserAnswers}
                >
                </InfoFormItem>
            ))}

            <ListTransportsUsed
                listTransportsUsed={listTransportsUsed}
                userAnswers={userAnswers}
                setUserAnswers={setUserAnswers}
            ></ListTransportsUsed>

            {/* Transport detail */}
            {userAnswers['transports_used']?.map((transport_used) => {
                let nbForm = 0; // Only one form per mode
                let item = listTransportsUsedDetail.map((transport, idx_transport) => {
                    if(transport.mode === transport_used && nbForm < 1) {
                        nbForm =+ 1;
                        return <ListTransportsUsedDetail
                            key={idx_transport}
                            mode={transport.mode}
                            listTransportsUsedDetail={transport}
                            userAnswers={userAnswers}
                            setUserAnswers={setUserAnswers}
                        ></ListTransportsUsedDetail>
                    }
                    return false;
                })
                return item;
            })}

            {/* Questions */}
            <ul>
                {modes.map((mode, idx_mode) => {
                    let item = undefined;

                    // Search mode_form_id associated to transports_used
                    const formToDisplay = []
                    if(userAnswers['transports_used']) {
                        for(const t of userAnswers['transports_used']) {
                            const mode_id = getModFromTransport(t);
                            if(!formToDisplay.includes(mode_id)) {
                                formToDisplay.push(mode_id);
                            }
                        }
                    }
                    if(formToDisplay?.includes(mode.id)) {
                        item =  <li key={idx_mode}>
                                <ModeTitleItem mode={mode}></ModeTitleItem>

                                <WhyBlock
                                    why={why}
                                    mode={mode.label}
                                    userAnswers={userAnswers}
                                    setUserAnswers={setUserAnswers}
                                ></WhyBlock>

                                <ul>
                                    {mode.affirmations.map((affirmation, idx_affirmation) => (
                                        <li key={idx_affirmation}>
                                            <AffirmationBlock
                                                currentCity={currentCity}
                                                mode={mode.label}
                                                affirmation={affirmation}
                                                userAnswers={userAnswers}
                                                setUserAnswers={setUserAnswers}
                                            ></AffirmationBlock>
                                        </li>
                                    ))}
                                </ul>
                            </li>
                        return item;
                    }
                    return false;
                })}
            </ul>

            <div className="contact-email">
                <FormLine justify={"center"} content={
                    <TextField
                        id="contact_email"
                        helperText="If you would like to be informed of more results, please enter your email"
                        value={contactEmail}
                        onChange={handleContactChange}
                    />
                } ></FormLine>
            </div>

            {!displayThanksMessage && <FormLine justify={"center"} content={
                <Button variant="contained" onClick={() => submitForm()}>Submit</Button>
            }></FormLine>}

            {displayThanksMessage &&
                <>
                    <FormLine justify={"center"}
                            content={<span>Thank you ! Your participation has been taken in account.</span>}></FormLine>
                    <ModalPartsResults results={modalPartsResults}></ModalPartsResults>
                </>
            }
        </div>
    );
}
