import { createSlice } from "@reduxjs/toolkit";

const { api_url, config } = require("../settings");
const { popupInformation } = require("../settings-popup-information");
const part = "MobilityIndicators";
const configChartsColorPalettes = config.charts.colorPalettes;
const yRight = {
    display: false,
    stacked: false,
    titleDisplay: false,
    titleText: "",
    titleColor: configChartsColorPalettes["2"].borderColor["4"],
    gridDisplay: false,
    beginAtZero: true,
    max: null,
};
const legendLabelsBoxHeightLine = 3; // Line, Area
const legendLabelsBoxHeightOther = 12; // Bar, Pie

export const dashboardMobilityIndicators = createSlice({
    name: "dashboardMobilityIndicators",
    initialState: {
        urbanStructure: {
            idSection: "urbanStructure",
            questionSection:
                "Is urban sprawl an issue? Towards a more compact city?",
            treeviews: {
                title: "Urban Structure",
                indicators: {
                    checked: 2, // 0 unchecked, 1 indeterminate, 2 checked
                    expand: true,
                },
                layers: {
                    checked: 2,
                    expand: true,
                },
            },
            layers: {
                densityPopulation: {
                    enable: true,
                    checked: true,
                    initialDisplay: true,
                    excludeCities: [],
                    url: api_url + "maps/geojson/?table=zones_cmp&schema_city=",
                    zindex: 0,
                    type: "graduated", // QGIS symbology name
                    // field_sqltable: name_label_popup
                    popup: {
                        default: {
                            content: {
                                zonenames: "Zone name: ",
                                pop_2015: "Population: ",
                                built_area: "Built up area (sq.kms): ",
                                density_15: "Net density (pop/sq.km):",
                            },
                            separators: {
                                decimal: ["built_area"],
                                thousand: ["pop_2015", "density_15"],
                            },
                        },
                        ahmedabad: {
                            content: {
                                asa_vill_t: "District : ",
                                pop21_fina: "Population: ",
                                "net area (": "Built up area (sq.kms): ",
                                "net densit": "Net density (pop/sq.km):",
                            },
                            separators: {
                                decimal: ["net area ("],
                                thousand: ["pop21_fina", "net densit"],
                            },
                        },
                        nagpur: {
                            content: {
                                nagpur_pop: "Population: ",
                                "net area": "Built up area (sq.kms): ",
                                density: "Net density (pop/sq.km):",
                            },
                            separators: {
                                decimal: ["net area"],
                                thousand: ["nagpur_pop", "density"],
                            },
                        },
                    },
                    titleSideBar: "Net population density",
                    style: {
                        strokeColor: "rgba(0, 0, 0, 0)",
                        lineDash: [],
                    },
                    legend: {
                        default: {
                            title: "Net population density",
                            subtitle: "(Pop/sq.km of built up areas)",
                            attribute: "density_15",
                            symbol: "rectangle",
                            legendCustomCssClass: "",
                            class: [
                                {
                                    color: "rgba(255, 255, 178, 0.7)",
                                    strokeWidth: 0,
                                    min: 0,
                                    max: 4999,
                                    label: "0 - 5,000",
                                },
                                {
                                    color: "rgba(254, 204, 92, 0.7)",
                                    strokeWidth: 0,
                                    min: 5000,
                                    max: 9999,
                                    label: "5,000 - 10,000",
                                },
                                {
                                    color: "rgba(253, 141, 60, 0.7)",
                                    strokeWidth: 0,
                                    min: 10000,
                                    max: 19999,
                                    label: "10,000 - 20,000",
                                },
                                {
                                    color: "rgba(240, 59, 32, 0.7)",
                                    strokeWidth: 0,
                                    min: 20000,
                                    max: 39999,
                                    label: "20,000 - 40,000",
                                },
                                {
                                    color: "rgba(189,0,38,0.7)",
                                    strokeWidth: 0,
                                    min: 40000,
                                    max: 60000,
                                    label: "40,000 - 60,000",
                                },
                            ],
                        },
                        ahmedabad: {
                            title: "Net population density",
                            subtitle: "(Pop/sq.km of built up areas)",
                            attribute: "net densit",
                            symbol: "rectangle",
                            legendCustomCssClass: "",
                            class: [
                                {
                                    color: "rgba(255, 255, 178, 0.7)",
                                    strokeWidth: 0,
                                    min: 0,
                                    max: 9999,
                                    label: "0 - 10,000",
                                },
                                {
                                    color: "rgba(254, 204, 92, 0.7)",
                                    strokeWidth: 0,
                                    min: 10000,
                                    max: 19999,
                                    label: "10,000 - 20,000",
                                },
                                {
                                    color: "rgba(253, 141, 60, 0.7)",
                                    strokeWidth: 0,
                                    min: 20000,
                                    max: 39999,
                                    label: "20,000 - 40,000",
                                },
                                {
                                    color: "rgba(240, 59, 32, 0.7)",
                                    strokeWidth: 0,
                                    min: 40000,
                                    max: 79999,
                                    label: "40,000 - 80,000",
                                },
                                {
                                    color: "rgba(189,0,38,0.7)",
                                    strokeWidth: 0,
                                    min: 80000,
                                    max: 281000,
                                    label: "80,000 - 280,000",
                                },
                            ],
                        },
                        nagpur: {
                            title: "Net population density",
                            subtitle: "(Pop/sq.km of built up areas)",
                            attribute: "density",
                            symbol: "rectangle",
                            legendCustomCssClass: "",
                            class: [
                                {
                                    color: "rgba(255, 255, 178, 0.7)",
                                    strokeWidth: 0,
                                    min: 0,
                                    max: 9999,
                                    label: "0 - 10,000",
                                },
                                {
                                    color: "rgba(254, 204, 92, 0.7)",
                                    strokeWidth: 0,
                                    min: 10000,
                                    max: 19999,
                                    label: "10,000 - 20,000",
                                },
                                {
                                    color: "rgba(253, 141, 60, 0.7)",
                                    strokeWidth: 0,
                                    min: 20000,
                                    max: 39999,
                                    label: "20,000 - 40,000",
                                },
                                {
                                    color: "rgba(240, 59, 32, 0.7)",
                                    strokeWidth: 0,
                                    min: 40000,
                                    max: 79999,
                                    label: "40,000 - 80,000",
                                },
                                {
                                    color: "rgba(189,0,38,0.7)",
                                    strokeWidth: 0,
                                    min: 80000,
                                    max: 121000,
                                    label: "80,000 - 120,000",
                                },
                            ],
                        },
                    },
                },
                zoning: {
                    enable: true,
                    checked: false,
                    initialDisplay: false,
                    excludeCities: ["nagpur"],
                    url: api_url + "maps/geojson/?table=zones&schema_city=",
                    zindex: 0,
                    type: "single",
                    popup: {
                        default: {
                            content: {
                                administra: "Municipality: ",
                            },
                            separators: {
                                decimal: [],
                                thousand: [],
                            },
                        },
                        ahmedabad: {
                            content: {
                                vill_t_nam: "Municipality: ",
                            },
                            separators: {
                                decimal: [],
                                thousand: [],
                            },
                        },
                    },
                    titleSideBar: "Zoning",
                    style: {
                        strokeColor: "dark",
                        lineDash: [],
                    },
                    legend: {
                        default: {
                            title: "Boundaries",
                            subtitle: "",
                            attribute: "",
                            symbol: "rectangle",
                            legendCustomCssClass:
                                "map-legend-symbol-border-black-1px",
                            class: [
                                {
                                    color: "rgba(0, 0, 0, 0)",
                                    strokeWidth: 1,
                                    label: "Zones",
                                },
                            ],
                        },
                    },
                },
                municipalities: {
                    enable: true,
                    checked: false,
                    initialDisplay: false,
                    excludeCities: ["nagpur"],
                    url:
                        api_url +
                        "maps/geojson/?table=municipalities&schema_city=",
                    zindex: 0,
                    type: "single",
                    popup: {
                        default: {
                            content: {
                                administra: "Municipality: ",
                            },
                            separators: {
                                decimal: [],
                                thousand: [],
                            },
                        },
                        ahmedabad: {
                            content: {
                                vill_t_nam: "Municipality: ",
                            },
                            separators: {
                                decimal: [],
                                thousand: [],
                            },
                        },
                    },
                    titleSideBar: "Municipalities",
                    style: {
                        strokeColor: "dark",
                        lineDash: [],
                    },
                    legend: {
                        default: {
                            title: "Boundaries",
                            subtitle: "",
                            attribute: "",
                            symbol: "rectangle",
                            legendCustomCssClass:
                                "map-legend-symbol-border-black-1px",
                            class: [
                                {
                                    color: "rgba(0, 0, 0, 0)",
                                    strokeWidth: 1,
                                    label: "Municipalities",
                                },
                            ],
                        },
                    },
                },
            },
            indicators: {
                densityPopulation: {
                    checked: true,
                    titleSideBar: "Population density",
                    titleIndicator: "Net density of population",
                    popupInformation: {
                        status: "false",
                        text: popupInformation[part]["densityPopulation"],
                    },
                    keyFigure: {
                        label: "inhabitants per sq.km",
                        url:
                            api_url +
                            "charts/data/?part=mobility-indicators&indicator=density_population&trend=projection&chart=population&chart_data=False&indicator_key_figure=True&schema_city=",
                        separator: "thousand",
                    },
                    charts: [
                        {
                            typeChart: "line",
                            multiYear: true,
                            titleChart: "Population",
                            titleDisplay: "true",
                            legendDisplay: "false",
                            legendCustom: "false",
                            legendLabelsBoxHeight: legendLabelsBoxHeightLine,
                            maintainAspectRatio: true,
                            heightChart: "",
                            widthChart: "",
                            scales: {
                                x: {
                                    display: true,
                                    stacked: false,
                                    titleDisplay: false,
                                    titleText: "",
                                    gridDisplay: false,
                                    beginAtZero: false,
                                },
                                y: {
                                    display: true,
                                    stacked: false,
                                    titleDisplay: true,
                                    titleText: "Total inhabitants",
                                    gridDisplay: true,
                                    beginAtZero: false,
                                    max: null,
                                },
                                yRight: yRight,
                            },
                            keyFigure: {
                                label: "",
                                url: "",
                                separator: "",
                                position: "",
                            },
                            datasets: [
                                {
                                    type: "line",
                                    label: "",
                                    data: [],
                                    url:
                                        api_url +
                                        "charts/data/?part=mobility-indicators&indicator=density_population&trend=projection&chart=population&schema_city=",
                                    idColorPalette:
                                        configChartsColorPalettes["7"],
                                    idColor: "0",
                                    borderWidth: 1,
                                    pointStyle: "circle",
                                    pointRadius: 3,
                                    spanGaps: true,
                                    legendCustomCssClass: "",
                                    fill: false,
                                    yAxisID: "y",
                                    order: 2,
                                    tooltipUnit: "",
                                },
                            ],
                        },
                        {
                            typeChart: "line",
                            multiYear: true,
                            titleChart: "Built up area",
                            titleDisplay: "true",
                            legendDisplay: "false",
                            legendCustom: "false",
                            legendLabelsBoxHeight: legendLabelsBoxHeightLine,
                            maintainAspectRatio: true,
                            heightChart: "",
                            widthChart: "",
                            scales: {
                                x: {
                                    display: true,
                                    stacked: false,
                                    titleDisplay: false,
                                    titleText: "",
                                    gridDisplay: false,
                                    beginAtZero: false,
                                },
                                y: {
                                    display: true,
                                    stacked: false,
                                    titleDisplay: true,
                                    titleText: "Square kilometres",
                                    gridDisplay: true,
                                    beginAtZero: true,
                                    max: null,
                                },
                                yRight: yRight,
                            },
                            keyFigure: {
                                label: "",
                                url: "",
                                separator: "",
                                position: "",
                            },
                            datasets: [
                                {
                                    type: "line",
                                    label: "",
                                    data: [],
                                    url:
                                        api_url +
                                        "charts/data/?part=mobility-indicators&indicator=density_population&trend=projection&chart=built_up_area&schema_city=",
                                    idColorPalette:
                                        configChartsColorPalettes["7"],
                                    idColor: "0",
                                    borderWidth: 1,
                                    pointStyle: "circle",
                                    pointRadius: 3,
                                    spanGaps: true,
                                    legendCustomCssClass: "",
                                    fill: false,
                                    yAxisID: "y",
                                    order: 2,
                                    tooltipUnit: "",
                                },
                            ],
                        },
                        {
                            typeChart: "line",
                            multiYear: true,
                            titleChart: "Net density",
                            titleDisplay: "true",
                            legendDisplay: "false",
                            legendCustom: "false",
                            legendLabelsBoxHeight: legendLabelsBoxHeightLine,
                            maintainAspectRatio: true,
                            heightChart: "",
                            widthChart: "",
                            scales: {
                                x: {
                                    display: true,
                                    stacked: false,
                                    titleDisplay: false,
                                    titleText: "",
                                    gridDisplay: false,
                                    beginAtZero: false,
                                },
                                y: {
                                    display: true,
                                    stacked: false,
                                    titleDisplay: true,
                                    titleText: "Inhabitants per sq.km",
                                    gridDisplay: true,
                                    beginAtZero: true,
                                    max: null,
                                },
                                yRight: yRight,
                            },
                            keyFigure: {
                                label: "",
                                url: "",
                                separator: "",
                                position: "",
                            },
                            datasets: [
                                {
                                    type: "line",
                                    label: "",
                                    data: [],
                                    url:
                                        api_url +
                                        "charts/data/?part=mobility-indicators&indicator=density_population&trend=projection&chart=net_density&schema_city=",
                                    idColorPalette:
                                        configChartsColorPalettes["7"],
                                    idColor: "0",
                                    borderWidth: 1,
                                    pointStyle: "circle",
                                    pointRadius: 3,
                                    spanGaps: true,
                                    legendCustomCssClass: "",
                                    fill: false,
                                    yAxisID: "y",
                                    order: 2,
                                    tooltipUnit: "",
                                },
                            ],
                        },
                    ],
                },
            },
        },
        mobilityDemand: {
            idSection: "mobilityDemand",
            questionSection: "How many trips are made in REPLACECITYNAME?",
            treeviews: {
                title: "Mobility Demand",
                indicators: {
                    checked: 0,
                    expand: false,
                },
                layers: {
                    checked: 2,
                    expand: true,
                },
            },
            layers: {
                networks: {
                    enable: true,
                    checked: false,
                    initialDisplay: true,
                    excludeCities: [],
                    url: api_url + "maps/geojson/?table=networks&schema_city=",
                    zindex: 2,
                    type: "graduated",
                    popup: {
                        default: {
                            content: {
                                category: "Category: ",
                                pcu: "Daily flows: ",
                            },
                            separators: {
                                decimal: [],
                                thousand: ["pcu"],
                            },
                        },
                        ahmedabad: {
                            content: {
                                road_hiera: "Category: ",
                                "2022_daily_flow": "Daily flows: ",
                            },
                            separators: {
                                decimal: [],
                                thousand: ["2022_daily_flow"],
                            },
                        },
                        nagpur: {
                            content: {
                                category: "Category: ",
                                flow: "Daily passengers: ",
                            },
                            separators: {
                                decimal: [],
                                thousand: ["flow"],
                            },
                        },
                    },
                    titleSideBar: "Road traffic flows",
                    style: {
                        radius: 8,
                        strokeColor: "dark",
                        lineDash: [],
                    },
                    legend: {
                        default: {
                            title: "Daily traffic volumes",
                            subtitle: "(Passenger Car Unit – PCU) ",
                            attribute: "pcu",
                            symbol: "line",
                            legendCustomCssClass: "",
                            class: [
                                {
                                    color: "rgba(44, 62, 80, 0.7)",
                                    strokeWidth: 1,
                                    min: 0,
                                    max: 19999,
                                    label: "0 - 20,000",
                                },
                                {
                                    color: "rgba(44, 62, 80, 0.7)",
                                    strokeWidth: 4,
                                    min: 20000,
                                    max: 34999,
                                    label: "20,000 - 35,000",
                                },
                                {
                                    color: "rgba(44, 62, 80, 0.7)",
                                    strokeWidth: 7,
                                    min: 35000,
                                    max: 49999,
                                    label: "35,000 - 50,000",
                                },
                                {
                                    color: "rgba(44, 62, 80, 0.7)",
                                    strokeWidth: 10,
                                    min: 50000,
                                    max: 69999,
                                    label: "50,000 - 70,000",
                                },
                                {
                                    color: "rgba(44, 62, 80, 0.7)",
                                    strokeWidth: 13,
                                    min: 70000,
                                    max: 100000,
                                    label: "70,000 - 1,00,000",
                                },
                            ],
                        },
                        ahmedabad: {
                            title: "Daily traffic volumes",
                            subtitle: "(Passenger Car Unit – PCU) ",
                            attribute: "2022_daily_flow",
                            symbol: "line",
                            legendCustomCssClass: "",
                            class: [
                                {
                                    color: "rgba(44, 62, 80, 0.7)",
                                    strokeWidth: 1,
                                    min: 0,
                                    max: 19999,
                                    label: "0 - 20,000",
                                },
                                {
                                    color: "rgba(44, 62, 80, 0.7)",
                                    strokeWidth: 4,
                                    min: 20000,
                                    max: 34999,
                                    label: "20,000 - 35,000",
                                },
                                {
                                    color: "rgba(44, 62, 80, 0.7)",
                                    strokeWidth: 7,
                                    min: 35000,
                                    max: 49999,
                                    label: "35,000 - 50,000",
                                },
                                {
                                    color: "rgba(44, 62, 80, 0.7)",
                                    strokeWidth: 10,
                                    min: 50000,
                                    max: 69999,
                                    label: "50,000 - 70,000",
                                },
                                {
                                    color: "rgba(44, 62, 80, 0.7)",
                                    strokeWidth: 13,
                                    min: 70000,
                                    max: 99999,
                                    label: "70,000 - 1,00,000",
                                },
                                {
                                    color: "rgba(44, 62, 80, 0.7)",
                                    strokeWidth: 16,
                                    min: 100000,
                                    max: 160000,
                                    label: "1,00,000 - 1,60,000",
                                },
                            ],
                        },
                        nagpur: {
                            title: "Daily traffic volumes",
                            subtitle: "(Passenger Car Unit – PCU) ",
                            attribute: "flow",
                            symbol: "line",
                            legendCustomCssClass: "",
                            class: [
                                {
                                    color: "rgba(44, 62, 80, 0.7)",
                                    strokeWidth: 1,
                                    min: 0,
                                    max: 19999,
                                    label: "0 - 20,000",
                                },
                                {
                                    color: "rgba(44, 62, 80, 0.7)",
                                    strokeWidth: 4,
                                    min: 20000,
                                    max: 34999,
                                    label: "20,000 - 35,000",
                                },
                                {
                                    color: "rgba(44, 62, 80, 0.7)",
                                    strokeWidth: 7,
                                    min: 35000,
                                    max: 49999,
                                    label: "35,000 - 50,000",
                                },
                                {
                                    color: "rgba(44, 62, 80, 0.7)",
                                    strokeWidth: 10,
                                    min: 50000,
                                    max: 69999,
                                    label: "50,000 - 70,000",
                                },
                            ],
                        },
                    },
                },
                roads: {
                    enable: true,
                    checked: false,
                    initialDisplay: true,
                    excludeCities: ["kochi", "ahmedabad"],
                    url: api_url + "maps/geojson/?table=roads&schema_city=",
                    zindex: 1,
                    type: "single",
                    popup: {
                        default: {
                            content: {
                            },
                            separators: {
                                decimal: [],
                                thousand: [],
                            },
                        },
                    },
                    titleSideBar: "Roads",
                    style: {
                        radius: 8,
                        strokeColor: "dark",
                        lineDash: [],
                    },
                    legend: {
                        default: {
                            title: "",
                            subtitle: "",
                            attribute: "",
                            symbol: "line",
                            legendCustomCssClass: "",
                            class: [
                                {
                                    color: "rgba(44, 62, 80, 0.7)",
                                    strokeWidth: 1,
                                    label: "Roads",
                                },
                            ],
                        },
                    },
                },
                desireLines: {
                    enable: true,
                    checked: false,
                    initialDisplay: true,
                    excludeCities: ["kochi", "ahmedabad", "nagpur"],
                    url:
                        api_url +
                        "maps/geojson/?table=desire_lines&schema_city=",
                    zindex: 1,
                    type: "single",
                    popup: {
                        default: {
                            content: {},
                            separators: {
                                decimal: [],
                                thousand: [],
                            },
                        },
                    },
                    titleSideBar: "Desire lines",
                    style: {
                        radius: 8,
                        strokeColor: "dark",
                        lineDash: [],
                    },
                    legend: {
                        default: {
                            title: "",
                            subtitle: "",
                            attribute: "",
                            symbol: "line",
                            legendCustomCssClass: "",
                            class: [
                                {
                                    color: "rgba(44, 62, 80, 0.7)",
                                    strokeWidth: 1,
                                    label: "Desire lines",
                                },
                            ],
                        },
                    },
                },
            },
            indicators: {
                modalSplit: {
                    checked: false,
                    titleSideBar: "Modal split",
                    titleIndicator: "Modal split",
                    popupInformation: {
                        status: "false",
                        text: popupInformation[part]["modalSplit"],
                    },
                    keyFigure: {
                        label: "daily trips",
                        url:
                            api_url +
                            "charts/data/?part=mobility-indicators&indicator=modal_split&trend=projection&chart=modal_split_area&chart_data=False&indicator_key_figure=True&schema_city=",
                        separator: "thousand",
                    },
                    charts: [
                        {
                            typeChart: "area",
                            multiYear: true,
                            titleChart: "Modal Split",
                            titleDisplay: "false",
                            legendDisplay: "true",
                            legendCustom: "true",
                            legendLabelsBoxHeight: legendLabelsBoxHeightOther,
                            maintainAspectRatio: false,
                            heightChart: "300",
                            widthChart: "400",
                            scales: {
                                x: {
                                    display: true,
                                    stacked: false,
                                    titleDisplay: false,
                                    titleText: "",
                                    gridDisplay: false,
                                    beginAtZero: false,
                                },
                                y: {
                                    display: true,
                                    stacked: true,
                                    titleDisplay: true,
                                    titleText: "Number of daily trips",
                                    gridDisplay: true,
                                    beginAtZero: false,
                                    max: null,
                                },
                                yRight: yRight,
                            },
                            keyFigure: {
                                label: "",
                                url: "",
                                separator: "",
                                position: "",
                            },
                            datasets: [
                                {
                                    type: "line",
                                    label: "Bicycle",
                                    data: [],
                                    url:
                                        api_url +
                                        "charts/data/?part=mobility-indicators&indicator=modal_split&trend=projection&chart=modal_split_area&chart_filter=Number of trips per mode - Bicycle&schema_city=",
                                    idColorPalette:
                                        configChartsColorPalettes["6"],
                                    idColor: "1",
                                    borderWidth: 1,
                                    pointStyle: "circle",
                                    pointRadius: 3,
                                    spanGaps: true,
                                    legendCustomCssClass:
                                        "icon-modal-split-bicycle",
                                    fill: true,
                                    yAxisID: "y",
                                    order: 2,
                                    tooltipUnit: "",
                                },
                                {
                                    type: "line",
                                    label: "Car",
                                    data: [],
                                    url:
                                        api_url +
                                        "charts/data/?part=mobility-indicators&indicator=modal_split&trend=projection&chart=modal_split_area&chart_filter=Number of trips per mode - Car&schema_city=",
                                    idColorPalette:
                                        configChartsColorPalettes["6"],
                                    idColor: "2",
                                    borderWidth: 1,
                                    pointStyle: "circle",
                                    pointRadius: 3,
                                    spanGaps: true,
                                    legendCustomCssClass:
                                        "icon-modal-split-car",
                                    fill: true,
                                    yAxisID: "y",
                                    order: 2,
                                    tooltipUnit: "",
                                },
                                {
                                    type: "line",
                                    label: "Auto Rickshaw",
                                    data: [],
                                    url:
                                        api_url +
                                        "charts/data/?part=mobility-indicators&indicator=modal_split&trend=projection&chart=modal_split_area&chart_filter=Number of trips per mode - Three-wheeler&schema_city=",
                                    idColorPalette:
                                        configChartsColorPalettes["6"],
                                    idColor: "0",
                                    borderWidth: 1,
                                    pointStyle: "circle",
                                    pointRadius: 3,
                                    spanGaps: true,
                                    legendCustomCssClass:
                                        "icon-modal-split-auto-rickshaw",
                                    fill: true,
                                    yAxisID: "y",
                                    order: 2,
                                    tooltipUnit: "",
                                },
                                {
                                    type: "line",
                                    label: "Walk",
                                    data: [],
                                    url:
                                        api_url +
                                        "charts/data/?part=mobility-indicators&indicator=modal_split&trend=projection&chart=modal_split_area&chart_filter=Number of trips per mode - Walk&schema_city=",
                                    idColorPalette:
                                        configChartsColorPalettes["6"],
                                    idColor: "5",
                                    borderWidth: 1,
                                    pointStyle: "circle",
                                    pointRadius: 3,
                                    spanGaps: true,
                                    legendCustomCssClass:
                                        "icon-modal-split-walk",
                                    fill: true,
                                    yAxisID: "y",
                                    order: 2,
                                    tooltipUnit: "",
                                },
                                {
                                    type: "line",
                                    label: "Two Wheeler",
                                    data: [],
                                    url:
                                        api_url +
                                        "charts/data/?part=mobility-indicators&indicator=modal_split&trend=projection&chart=modal_split_area&chart_filter=Number of trips per mode - Two-wheeler&schema_city=",
                                    idColorPalette:
                                        configChartsColorPalettes["6"],
                                    idColor: "4",
                                    borderWidth: 1,
                                    pointStyle: "circle",
                                    pointRadius: 3,
                                    spanGaps: true,
                                    legendCustomCssClass:
                                        "icon-modal-split-two-wheeler",
                                    fill: true,
                                    yAxisID: "y",
                                    order: 2,
                                    tooltipUnit: "",
                                },
                                {
                                    type: "line",
                                    label: "Public transport",
                                    data: [],
                                    url:
                                        api_url +
                                        "charts/data/?part=mobility-indicators&indicator=modal_split&trend=projection&chart=modal_split_area&chart_filter=Number of trips per mode - Public Transportation&schema_city=",
                                    idColorPalette:
                                        configChartsColorPalettes["6"],
                                    idColor: "3",
                                    borderWidth: 1,
                                    pointStyle: "circle",
                                    pointRadius: 3,
                                    spanGaps: true,
                                    legendCustomCssClass:
                                        "icon-modal-split-public-transport",
                                    fill: true,
                                    yAxisID: "y",
                                    order: 2,
                                    tooltipUnit: "",
                                },
                            ],
                        },
                        {
                            typeChart: "pie",
                            multiYear: false,
                            titleChart: "Modal split",
                            titleDisplay: "false",
                            legendDisplay: "true",
                            legendCustom: "false",
                            legendLabelsBoxHeight: legendLabelsBoxHeightOther,
                            maintainAspectRatio: true,
                            heightChart: "",
                            widthChart: "",
                            scales: {
                                x: {
                                    display: false,
                                    stacked: false,
                                    titleDisplay: false,
                                    titleText: "",
                                    gridDisplay: false,
                                    beginAtZero: false,
                                },
                                y: {
                                    display: false,
                                    stacked: true,
                                    titleDisplay: false,
                                    titleText: "",
                                    gridDisplay: false,
                                    beginAtZero: false,
                                    max: null,
                                },
                                yRight: yRight,
                            },
                            keyFigure: {
                                label: "% modal share of public transportation",
                                url:
                                    api_url +
                                    "charts/data/?part=mobility-indicators&indicator=modal_split&trend=projection&chart=modal_split_percentage&chart_data=false&indicator_key_figure=false&chart_key_figure=true&schema_city=",
                                separator: "",
                                position: "bottom",
                            },
                            datasets: [
                                {
                                    type: "pie",
                                    label: "",
                                    data: [],
                                    url:
                                        api_url +
                                        "charts/data/?part=mobility-indicators&indicator=modal_split&trend=projection&chart=modal_split_pourcentage&schema_city=",
                                    idColorPalette:
                                        configChartsColorPalettes["1"],
                                    idColor: "",
                                    borderWidth: 1,
                                    pointStyle: "circle",
                                    pointRadius: 3,
                                    spanGaps: true,
                                    legendCustomCssClass: "",
                                    fill: false,
                                    yAxisID: "y",
                                    order: 2,
                                    tooltipUnit: "%",
                                },
                            ],
                        },
                    ],
                },
                averageNumberTrips: {
                    checked: false,
                    titleSideBar: "Number of trips",
                    titleIndicator: "Average number of trips per inhabitant",
                    popupInformation: {
                        status: "false",
                        text: popupInformation[part]["averageNumberTrips"],
                    },
                    keyFigure: {
                        label: "trips per inhabitants",
                        url:
                            api_url +
                            "charts/data/?part=mobility-indicators&indicator=average_number_trips&trend=projection&chart=average_number_trips&chart_data=False&indicator_key_figure=True&schema_city=",
                        separator: "decimal",
                    },
                    charts: [
                        {
                            typeChart: "line",
                            multiYear: true,
                            titleChart: "Trips",
                            titleDisplay: "false",
                            legendDisplay: "false",
                            legendCustom: "false",
                            legendLabelsBoxHeight: legendLabelsBoxHeightLine,
                            maintainAspectRatio: false,
                            heightChart: "220",
                            widthChart: "300",
                            scales: {
                                x: {
                                    display: true,
                                    stacked: false,
                                    titleDisplay: false,
                                    titleText: "",
                                    gridDisplay: false,
                                    beginAtZero: false,
                                },
                                y: {
                                    display: true,
                                    stacked: false,
                                    titleDisplay: true,
                                    titleText: "Trips per day per inhabitant",
                                    gridDisplay: true,
                                    beginAtZero: true,
                                    max: null,
                                },
                                yRight: yRight,
                            },
                            keyFigure: {
                                label: "",
                                url: "",
                                separator: "",
                                position: "",
                            },
                            datasets: [
                                {
                                    type: "line",
                                    label: "",
                                    data: [],
                                    url:
                                        api_url +
                                        "charts/data/?part=mobility-indicators&indicator=average_number_trips&trend=projection&chart=average_number_trips&schema_city=",
                                    idColorPalette:
                                        configChartsColorPalettes["7"],
                                    idColor: "0",
                                    borderWidth: 1,
                                    pointStyle: "circle",
                                    pointRadius: 3,
                                    spanGaps: true,
                                    legendCustomCssClass: "",
                                    fill: false,
                                    yAxisID: "y",
                                    order: 2,
                                    tooltipUnit: "",
                                },
                            ],
                        },
                    ],
                },
                immobilityRate: {
                    checked: false,
                    titleSideBar: "Immobility rate",
                    titleIndicator: "Immobility rate",
                    popupInformation: {
                        status: "false",
                        text: popupInformation[part]["immobilityRate"],
                    },
                    keyFigure: {
                        label: "% of the population",
                        url:
                            api_url +
                            "charts/data/?part=mobility-indicators&indicator=immobility_rate&trend=projection&chart=immobility_rate&chart_data=False&indicator_key_figure=True&schema_city=",
                        separator: "",
                    },
                    charts: [
                        {
                            typeChart: "line",
                            multiYear: true,
                            titleChart: "Immobility rate",
                            titleDisplay: "false",
                            legendDisplay: "false",
                            legendCustom: "false",
                            legendLabelsBoxHeight: legendLabelsBoxHeightLine,
                            maintainAspectRatio: false,
                            heightChart: "220",
                            widthChart: "300",
                            scales: {
                                x: {
                                    display: true,
                                    stacked: false,
                                    titleDisplay: false,
                                    titleText: "",
                                    gridDisplay: false,
                                    beginAtZero: false,
                                },
                                y: {
                                    display: true,
                                    stacked: false,
                                    titleDisplay: true,
                                    titleText: "% of people staying at home",
                                    gridDisplay: true,
                                    beginAtZero: true,
                                    max: 100,
                                },
                                yRight: yRight,
                            },
                            keyFigure: {
                                label: "",
                                url: "",
                                separator: "",
                                position: "",
                            },
                            datasets: [
                                {
                                    type: "line",
                                    label: "",
                                    data: [],
                                    url:
                                        api_url +
                                        "charts/data/?part=mobility-indicators&indicator=immobility_rate&trend=projection&chart=immobility_rate&schema_city=",
                                    idColorPalette:
                                        configChartsColorPalettes["7"],
                                    idColor: "0",
                                    borderWidth: 1,
                                    pointStyle: "circle",
                                    pointRadius: 3,
                                    spanGaps: true,
                                    legendCustomCssClass: "",
                                    fill: false,
                                    yAxisID: "y",
                                    order: 2,
                                    tooltipUnit: "",
                                },
                            ],
                        },
                    ],
                },
                averageTripLength: {
                    checked: false,
                    titleSideBar: "Trip length",
                    titleIndicator: "Average trip length of mechanised modes",
                    popupInformation: {
                        status: "false",
                        text: popupInformation[part]["averageTripLength"],
                    },
                    keyFigure: {
                        label: "kms per trip",
                        url:
                            api_url +
                            "charts/data/?part=mobility-indicators&indicator=average_trip_length&trend=projection&chart=average_trip_length&chart_data=False&indicator_key_figure=True&schema_city=",
                        separator: "decimal",
                    },
                    charts: [
                        {
                            typeChart: "line",
                            multiYear: true,
                            titleChart: "",
                            titleDisplay: "true",
                            legendDisplay: "true",
                            legendCustom: "false",
                            legendLabelsBoxHeight: legendLabelsBoxHeightLine,
                            maintainAspectRatio: false,
                            heightChart: "250",
                            widthChart: "300",
                            scales: {
                                x: {
                                    display: true,
                                    stacked: false,
                                    titleDisplay: false,
                                    titleText: "",
                                    gridDisplay: false,
                                    beginAtZero: false,
                                },
                                y: {
                                    display: true,
                                    stacked: false,
                                    titleDisplay: true,
                                    titleText: "kms per trip",
                                    gridDisplay: true,
                                    beginAtZero: false,
                                    max: null,
                                },
                                yRight: yRight,
                            },
                            keyFigure: {
                                label: "",
                                url: "",
                                separator: "",
                                position: "",
                            },
                            datasets: [
                                {
                                    type: "line",
                                    label: "Average for mechanised modes",
                                    data: [],
                                    url:
                                        api_url +
                                        "charts/data/?part=mobility-indicators&indicator=average_trip_length&trend=projection&chart=average_trip_length&schema_city=",
                                    idColorPalette:
                                        configChartsColorPalettes["2"],
                                    idColor: "4",
                                    borderWidth: 2,
                                    pointStyle: "circle",
                                    pointRadius: 3,
                                    spanGaps: true,
                                    legendCustomCssClass: "",
                                    fill: false,
                                    yAxisID: "y",
                                    order: 2,
                                    tooltipUnit: " km",
                                },
                                {
                                    type: "line",
                                    label: "Private transport",
                                    data: [],
                                    url:
                                        api_url +
                                        "charts/data/?part=mobility-indicators&indicator=average_trip_length&trend=projection&chart=average_trip_length_evolution&chart_filter=Trip length (km) - Private modes&schema_city=",
                                    idColorPalette:
                                        configChartsColorPalettes["1"],
                                    idColor: "6",
                                    borderWidth: 1,
                                    pointStyle: "circle",
                                    pointRadius: 3,
                                    spanGaps: true,
                                    legendCustomCssClass: "",
                                    fill: false,
                                    yAxisID: "y",
                                    order: 2,
                                    tooltipUnit: " km",
                                },
                                {
                                    type: "line",
                                    label: "Public transport",
                                    data: [],
                                    url:
                                        api_url +
                                        "charts/data/?part=mobility-indicators&indicator=average_trip_length&trend=projection&chart=average_trip_length_evolution&chart_filter=Trip length (km) - Public Transportation&schema_city=",
                                    idColorPalette:
                                        configChartsColorPalettes["1"],
                                    idColor: "3",
                                    borderWidth: 1,
                                    pointStyle: "circle",
                                    pointRadius: 3,
                                    spanGaps: true,
                                    legendCustomCssClass: "",
                                    fill: false,
                                    yAxisID: "y",
                                    order: 2,
                                    tooltipUnit: " km",
                                },
                                {
                                    type: "line",
                                    label: "Bicycle",
                                    data: [],
                                    url:
                                        api_url +
                                        "charts/data/?part=mobility-indicators&indicator=average_trip_length&trend=projection&chart=average_trip_length_evolution&chart_filter=Trip length (km) - Bicycle&schema_city=",
                                    idColorPalette:
                                        configChartsColorPalettes["1"],
                                    idColor: "1",
                                    borderWidth: 1,
                                    pointStyle: "circle",
                                    pointRadius: 3,
                                    spanGaps: true,
                                    legendCustomCssClass: "",
                                    fill: false,
                                    yAxisID: "y",
                                    order: 2,
                                    tooltipUnit: " km",
                                },
                            ],
                        },
                    ],
                },
            },
        },
        transportSupply: {
            idSection: "transportSupply",
            questionSection:
                "What type of infrastructure does REPLACECITYNAME have?",
            treeviews: {
                title: "Transport Supply",
                indicators: {
                    checked: 0,
                    expand: false,
                },
                layers: {
                    checked: 2,
                    expand: true,
                },
            },
            layers: {
                roads: {
                    enable: true,
                    checked: false,
                    initialDisplay: false,
                    excludeCities: [],
                    url: api_url + "maps/geojson/?table=roads&schema_city=",
                    zindex: 1,
                    type: "single",
                    popup: {
                        default: {
                            content: {
                                category: "Category: ",
                            },
                            separators: {
                                decimal: [],
                                thousand: [],
                            },
                        },
                    },
                    titleSideBar: "Roads",
                    style: {
                        radius: 8,
                        strokeColor: "dark",
                        lineDash: [],
                    },
                    legend: {
                        default: {
                            title: "",
                            subtitle: "",
                            attribute: "",
                            symbol: "line",
                            legendCustomCssClass: "",
                            class: [
                                {
                                    color: "rgba(44, 62, 80, 0.7)",
                                    strokeWidth: 1,
                                    label: "Roads",
                                },
                            ],
                        },
                    },
                },
                metro: {
                    enable: true,
                    checked: false,
                    initialDisplay: true,
                    excludeCities: [],
                    url: api_url + "maps/geojson/?table=metro&schema_city=",
                    zindex: 6,
                    type: "single",
                    popup: {
                        default: {
                            content: {},
                            separators: {
                                decimal: [],
                                thousand: [],
                            },
                        },
                    },
                    titleSideBar: "Metro",
                    style: {
                        radius: 0,
                        strokeColor: "rgba(189, 0, 38, 1)",
                        lineDash: [],
                    },
                    legend: {
                        default: {
                            title: "",
                            subtitle: "",
                            attribute: "",
                            symbol: "line",
                            legendCustomCssClass: "",
                            class: [
                                {
                                    color: "rgba(189, 0, 38, 1)",
                                    strokeWidth: 3,
                                    label: "Metro",
                                },
                            ],
                        },
                    },
                },
                metroStations: {
                    enable: true,
                    checked: false,
                    initialDisplay: true,
                    excludeCities: ["ahmedabad"],
                    url:
                        api_url +
                        "maps/geojson/?table=metro_stations&schema_city=",
                    zindex: 7,
                    type: "single",
                    popup: {
                        default: {
                            content: {
                                name: "Name: ",
                            },
                            separators: {
                                decimal: [],
                                thousand: [],
                            },
                        },
                    },
                    titleSideBar: "Metro stations",
                    style: {
                        radius: 5,
                        strokeColor: "rgba(189, 0, 38, 1)",
                        lineDash: [],
                    },
                    legend: {
                        default: {
                            title: "",
                            subtitle: "",
                            attribute: "",
                            symbol: "point",
                            legendCustomCssClass: "",
                            class: [
                                {
                                    color: "rgba(189, 0, 38, 1)",
                                    strokeWidth: 0,
                                    label: "Metro stations",
                                },
                            ],
                        },
                    },
                },
                railway: {
                    enable: true,
                    checked: false,
                    initialDisplay: true,
                    excludeCities: [],
                    url: api_url + "maps/geojson/?table=railway&schema_city=",
                    zindex: 2,
                    type: "single",
                    popup: {
                        default: {
                            content: {},
                            separators: {
                                decimal: [],
                                thousand: [],
                            },
                        },
                    },
                    titleSideBar: "Railway",
                    style: {
                        radius: 0,
                        strokeColor: "rgba(254, 168, 47, 1)",
                        lineDash: [],
                    },
                    legend: {
                        default: {
                            title: "",
                            subtitle: "",
                            attribute: "",
                            symbol: "line",
                            legendCustomCssClass: "",
                            class: [
                                {
                                    color: "rgba(254, 168, 47, 1)",
                                    strokeWidth: 2,
                                    label: "Railway",
                                },
                            ],
                        },
                    },
                },
                railwayStations: {
                    enable: true,
                    checked: false,
                    initialDisplay: true,
                    excludeCities: [],
                    url:
                        api_url +
                        "maps/geojson/?table=railway_stations&schema_city=",
                    zindex: 3,
                    type: "single",
                    popup: {
                        default: {
                            content: {
                                name: "Name: ",
                            },
                            separators: {
                                decimal: [],
                                thousand: [],
                            },
                        },
                    },
                    titleSideBar: "Railway stations",
                    style: {
                        radius: 4,
                        strokeColor: "rgba(254, 168, 47, 1)",
                        lineDash: [],
                    },
                    legend: {
                        default: {
                            title: "",
                            subtitle: "",
                            attribute: "",
                            symbol: "point",
                            legendCustomCssClass: "",
                            class: [
                                {
                                    color: "rgba(254, 168, 47, 1)",
                                    strokeWidth: 0,
                                    label: "Railway stations",
                                },
                            ],
                        },
                    },
                },
                bus: {
                    enable: true,
                    checked: false,
                    initialDisplay: true,
                    excludeCities: ["ahmedabad", "nagpur"],
                    url: api_url + "maps/geojson/?table=bus&schema_city=",
                    zindex: 4,
                    type: "single",
                    popup: {
                        default: {
                            content: {},
                            separators: {
                                decimal: [],
                                thousand: [],
                            },
                        },
                    },
                    titleSideBar: "Bus",
                    style: {
                        radius: 0,
                        strokeColor: "rgb(63, 184, 216)",
                        lineDash: [],
                    },
                    legend: {
                        default: {
                            title: "",
                            subtitle: "",
                            attribute: "",
                            symbol: "line",
                            legendCustomCssClass: "",
                            class: [
                                {
                                    color: "rgb(63, 184, 216)",
                                    strokeWidth: 2,
                                    label: "Bus",
                                },
                            ],
                        },
                    },
                },
                busBrtsAhmedabad: {
                    enable: true,
                    checked: false,
                    initialDisplay: true,
                    excludeCities: ["kochi", "nagpur"],
                    url: api_url + "maps/geojson/?table=bus_brts&schema_city=",
                    zindex: 4,
                    type: "single",
                    popup: {
                        default: {
                            content: {},
                            separators: {
                                decimal: [],
                                thousand: [],
                            },
                        },
                    },
                    titleSideBar: "Bus - BRTS Network",
                    style: {
                        radius: 0,
                        strokeColor: "rgb(10, 27, 128)",
                        lineDash: [],
                    },
                    legend: {
                        default: {
                            title: "",
                            subtitle: "",
                            attribute: "",
                            symbol: "line",
                            legendCustomCssClass: "",
                            class: [
                                {
                                    color: "rgb(10, 27, 128)",
                                    strokeWidth: 3,
                                    label: "Bus - BRTS Network",
                                },
                            ],
                        },
                    },
                },
                busAmtsAhmedabad: {
                    enable: true,
                    checked: false,
                    initialDisplay: true,
                    excludeCities: ["kochi", "nagpur"],
                    url: api_url + "maps/geojson/?table=bus_amts&schema_city=",
                    zindex: 3,
                    type: "single",
                    popup: {
                        default: {
                            content: {},
                            separators: {
                                decimal: [],
                                thousand: [],
                            },
                        },
                    },
                    titleSideBar: "Bus - AMTS Network",
                    style: {
                        radius: 0,
                        strokeColor: "rgb(3, 36, 248)",
                        lineDash: [],
                    },
                    legend: {
                        default: {
                            title: "",
                            subtitle: "",
                            attribute: "",
                            symbol: "line",
                            legendCustomCssClass: "",
                            class: [
                                {
                                    color: "rgb(3, 36, 248)",
                                    strokeWidth: 2,
                                    label: "Bus - AMTS Network",
                                },
                            ],
                        },
                    },
                },
                busStops: {
                    enable: true,
                    checked: false,
                    initialDisplay: false,
                    excludeCities: ["ahmedabad", "nagpur"],
                    url: api_url + "maps/geojson/?table=bus_stops&schema_city=",
                    zindex: 5,
                    type: "single",
                    popup: {
                        default: {
                            content: {
                                bs_name: "Name: ",
                            },
                            separators: {
                                decimal: [],
                                thousand: [],
                            },
                        },
                    },
                    titleSideBar: "Bus stops",
                    style: {
                        radius: 3,
                        strokeColor: "rgb(63, 184, 216)",
                        lineDash: [],
                    },
                    legend: {
                        default: {
                            title: "",
                            subtitle: "",
                            attribute: "",
                            symbol: "point",
                            legendCustomCssClass: "",
                            class: [
                                {
                                    color: "rgb(63, 184, 216)",
                                    strokeWidth: 0,
                                    label: "Bus stops",
                                },
                            ],
                        },
                    },
                },
                ferry: {
                    enable: true,
                    checked: false,
                    initialDisplay: true,
                    excludeCities: ["ahmedabad", "nagpur"],
                    url: api_url + "maps/geojson/?table=ferry&schema_city=",
                    zindex: 8,
                    type: "single",
                    popup: {
                        default: {
                            content: {
                                name: "Name: ",
                                from: "From: ",
                                to: "To :",
                            },
                            separators: {
                                decimal: [],
                                thousand: [],
                            },
                        },
                    },
                    titleSideBar: "Ferry",
                    style: {
                        radius: 0,
                        strokeColor: "rgb(127, 141, 244)",
                        lineDash: [],
                    },
                    legend: {
                        default: {
                            title: "",
                            subtitle: "",
                            attribute: "",
                            symbol: "line",
                            legendCustomCssClass: "",
                            class: [
                                {
                                    color: "rgb(127, 141, 244)",
                                    strokeWidth: 3,
                                    label: "Ferry",
                                },
                            ],
                        },
                    },
                },
            },
            indicators: {
                kilometersRoads: {
                    checked: false,
                    titleSideBar: "Roads",
                    titleIndicator: "Road network extension",
                    popupInformation: {
                        status: "false",
                        text: popupInformation[part]["kilometersRoads"],
                    },
                    keyFigure: {
                        label: "kms of road per lakh population",
                        url:
                            api_url +
                            "charts/data/?part=mobility-indicators&indicator=kilometers_roads&trend=projection&chart=kilometers_roads&chart_data=False&indicator_key_figure=True&schema_city=",
                        separator: "thousand",
                    },
                    charts: [
                        {
                            typeChart: "bar",
                            multiYear: true,
                            titleChart: "",
                            titleDisplay: "false",
                            legendDisplay: "false",
                            legendCustom: "false",
                            legendLabelsBoxHeight: legendLabelsBoxHeightLine,
                            maintainAspectRatio: false,
                            heightChart: "280",
                            widthChart: "330",
                            scales: {
                                x: {
                                    display: true,
                                    stacked: false,
                                    titleDisplay: false,
                                    titleText: "",
                                    gridDisplay: false,
                                    beginAtZero: false,
                                },
                                y: {
                                    display: true,
                                    stacked: false,
                                    titleDisplay: true,
                                    titleText:
                                        "kms of road per lakh population ",
                                    gridDisplay: true,
                                    beginAtZero: true,
                                    max: null,
                                },
                                yRight: yRight,
                            },
                            keyFigure: {
                                label: "",
                                url: "",
                                separator: "",
                                position: "",
                            },
                            datasets: [
                                {
                                    type: "bar",
                                    label: "",
                                    data: [],
                                    url:
                                        api_url +
                                        "charts/data/?part=mobility-indicators&indicator=kilometers_roads&trend=projection&chart=kilometers_roads&chart_data=True&indicator_key_figure=False&schema_city=",
                                    idColorPalette:
                                        configChartsColorPalettes["0"],
                                    idColor: "0",
                                    borderWidth: 1,
                                    pointStyle: "circle",
                                    pointRadius: 3,
                                    spanGaps: true,
                                    legendCustomCssClass: "",
                                    fill: false,
                                    yAxisID: "y",
                                    order: 2,
                                    tooltipUnit: "",
                                },
                            ],
                        },
                    ],
                },
                kilometersMassTransitSystems: {
                    checked: false,
                    titleSideBar: "Mass transit systems",
                    titleIndicator: "Mass transit system extension",
                    popupInformation: {
                        status: "false",
                        text: popupInformation[part][
                            "kilometersMassTransitSystems"
                        ],
                    },
                    keyFigure: {
                        label: "kms of mass transit system infrastructures per lakh population",
                        url:
                            api_url +
                            "charts/data/?part=mobility-indicators&indicator=kilometers_mass_transit_systems&trend=projection&chart=kilometers_mass_transit_systems&chart_data=False&indicator_key_figure=True&schema_city=",
                        separator: "decimal",
                    },
                    charts: [
                        {
                            typeChart: "bar",
                            multiYear: true,
                            titleChart: "",
                            titleDisplay: "false",
                            legendDisplay: "false",
                            legendCustom: "false",
                            legendLabelsBoxHeight: legendLabelsBoxHeightOther,
                            maintainAspectRatio: false,
                            heightChart: "320",
                            widthChart: "300",
                            scales: {
                                x: {
                                    display: true,
                                    stacked: false,
                                    titleDisplay: false,
                                    titleText: "",
                                    gridDisplay: false,
                                    beginAtZero: false,
                                },
                                y: {
                                    display: true,
                                    stacked: false,
                                    titleDisplay: true,
                                    titleText:
                                        "kms of mass transit system per lakh population",
                                    gridDisplay: true,
                                    beginAtZero: true,
                                    max: null,
                                },
                                yRight: yRight,
                            },
                            keyFigure: {
                                label: "",
                                url: "",
                                separator: "",
                                position: "",
                            },
                            datasets: [
                                {
                                    type: "bar",
                                    label: "",
                                    data: [],
                                    url:
                                        api_url +
                                        "charts/data/?part=mobility-indicators&indicator=kilometers_mass_transit_systems&trend=projection&chart=kilometers_mass_transit_systems&chart_data=True&indicator_key_figure=False&schema_city=",
                                    idColorPalette:
                                        configChartsColorPalettes["1"],
                                    idColor: "3",
                                    borderWidth: 1,
                                    pointStyle: "circle",
                                    pointRadius: 3,
                                    spanGaps: true,
                                    legendCustomCssClass: "",
                                    fill: false,
                                    yAxisID: "y",
                                    order: 2,
                                    tooltipUnit: "",
                                },
                            ],
                        },
                    ],
                },
                pedestrianStreets: {
                    checked: false,
                    titleSideBar: "Pedestrian ways",
                    titleIndicator: "Pedestrian ways extension",
                    popupInformation: {
                        status: "false",
                        text: popupInformation[part]["pedestrianStreets"],
                    },
                    keyFigure: {
                        label: "kms of footpath per lakh population",
                        url:
                            api_url +
                            "charts/data/?part=mobility-indicators&indicator=pedestrian_streets&trend=projection&chart=pedestrian_streets&chart_data=False&indicator_key_figure=True&schema_city=",
                        separator: "decimal",
                    },
                    charts: [
                        {
                            typeChart: "bar",
                            multiYear: true,
                            titleChart: "",
                            titleDisplay: "false",
                            legendDisplay: "false",
                            legendCustom: "false",
                            legendLabelsBoxHeight: legendLabelsBoxHeightOther,
                            maintainAspectRatio: false,
                            heightChart: "240",
                            widthChart: "300",
                            scales: {
                                x: {
                                    display: true,
                                    stacked: false,
                                    titleDisplay: false,
                                    titleText: "",
                                    gridDisplay: false,
                                    beginAtZero: false,
                                },
                                y: {
                                    display: true,
                                    stacked: false,
                                    titleDisplay: true,
                                    titleText:
                                        "kms of footpath per lakh population",
                                    gridDisplay: true,
                                    beginAtZero: true,
                                    max: null,
                                },
                                yRight: yRight,
                            },
                            keyFigure: {
                                label: "",
                                url: "",
                                separator: "",
                                position: "",
                            },
                            datasets: [
                                {
                                    type: "bar",
                                    label: "",
                                    data: [],
                                    url:
                                        api_url +
                                        "charts/data/?part=mobility-indicators&indicator=pedestrian_streets&trend=projection&chart=pedestrian_streets&chart_data=True&indicator_key_figure=False&schema_city=",
                                    idColorPalette:
                                        configChartsColorPalettes["1"],
                                    idColor: "5",
                                    borderWidth: 1,
                                    pointStyle: "circle",
                                    pointRadius: 3,
                                    spanGaps: true,
                                    legendCustomCssClass: "",
                                    fill: false,
                                    yAxisID: "y",
                                    order: 2,
                                    tooltipUnit: "",
                                },
                            ],
                        },
                    ],
                },
                bicycleLanes: {
                    checked: false,
                    titleSideBar: "Bicycle lanes",
                    titleIndicator: "Bicycle lanes extension",
                    popupInformation: {
                        status: "false",
                        text: popupInformation[part]["bicycleLanes"],
                    },
                    keyFigure: {
                        label: "kms of bicycle lanes",
                        url:
                            api_url +
                            "charts/data/?part=mobility-indicators&indicator=bicycle_lanes&trend=projection&chart=bicycle_lanes&chart_data=False&indicator_key_figure=True&schema_city=",
                        separator: "decimal",
                    },
                    charts: [
                        {
                            typeChart: "bar",
                            multiYear: true,
                            titleChart: "",
                            titleDisplay: "false",
                            legendDisplay: "false",
                            legendCustom: "false",
                            legendLabelsBoxHeight: legendLabelsBoxHeightOther,
                            maintainAspectRatio: false,
                            heightChart: "270",
                            widthChart: "300",
                            scales: {
                                x: {
                                    display: true,
                                    stacked: false,
                                    titleDisplay: false,
                                    titleText: "",
                                    gridDisplay: false,
                                    beginAtZero: false,
                                },
                                y: {
                                    display: true,
                                    stacked: false,
                                    titleDisplay: true,
                                    titleText:
                                        "kms of bicycle lanes per lakh population",
                                    gridDisplay: true,
                                    beginAtZero: true,
                                    max: null,
                                },
                                yRight: yRight,
                            },
                            keyFigure: {
                                label: "",
                                url: "",
                                separator: "",
                                position: "",
                            },
                            datasets: [
                                {
                                    type: "bar",
                                    label: "",
                                    data: [],
                                    url:
                                        api_url +
                                        "charts/data/?part=mobility-indicators&indicator=bicycle_lanes&trend=projection&chart=bicycle_lanes&chart_data=True&indicator_key_figure=False&schema_city=",
                                    idColorPalette:
                                        configChartsColorPalettes["1"],
                                    idColor: "1",
                                    borderWidth: 1,
                                    pointStyle: "circle",
                                    pointRadius: 3,
                                    spanGaps: true,
                                    legendCustomCssClass: "",
                                    fill: false,
                                    yAxisID: "y",
                                    order: 2,
                                    tooltipUnit: "",
                                },
                            ],
                        },
                    ],
                },
                busSystemSupply: {
                    checked: false,
                    titleSideBar: "Bus system supply",
                    titleIndicator: "Bus system supply",
                    popupInformation: {
                        status: "false",
                        text: popupInformation[part]["busSystemSupply"],
                    },
                    keyFigure: {
                        label: "bus per lakh population",
                        url:
                            api_url +
                            "charts/data/?part=mobility-indicators&indicator=bus_system_supply&trend=projection&chart=public_transport_trips&chart_data=False&indicator_key_figure=True&schema_city=",
                        separator: "thousand",
                    },
                    charts: [
                        {
                            typeChart: "line",
                            multiYear: true,
                            titleChart: "Number of bus per lakh population",
                            titleDisplay: "true",
                            legendDisplay: "false",
                            legendCustom: "false",
                            legendLabelsBoxHeight: legendLabelsBoxHeightLine,
                            maintainAspectRatio: false,
                            heightChart: "210",
                            widthChart: "300",
                            scales: {
                                x: {
                                    display: true,
                                    stacked: false,
                                    titleDisplay: false,
                                    titleText: "",
                                    gridDisplay: false,
                                    beginAtZero: false,
                                },
                                y: {
                                    display: true,
                                    stacked: false,
                                    titleDisplay: true,
                                    titleText: "Bus per lakh population",
                                    gridDisplay: true,
                                    beginAtZero: true,
                                    max: null,
                                },
                                yRight: yRight,
                            },
                            keyFigure: {
                                label: "",
                                url: "",
                                separator: "",
                                position: "",
                            },
                            datasets: [
                                {
                                    type: "line",
                                    label: "",
                                    data: [],
                                    url:
                                        api_url +
                                        "charts/data/?part=mobility-indicators&indicator=bus_system_supply&trend=projection&chart=public_transport_trips&chart_data=True&indicator_key_figure=False&schema_city=",
                                    idColorPalette:
                                        configChartsColorPalettes["7"],
                                    idColor: "0",
                                    borderWidth: 1,
                                    pointStyle: "circle",
                                    pointRadius: 3,
                                    spanGaps: true,
                                    legendCustomCssClass: "",
                                    fill: false,
                                    yAxisID: "y",
                                    order: 2,
                                    tooltipUnit: "",
                                },
                            ],
                        },
                        {
                            typeChart: "line",
                            multiYear: true,
                            titleChart:
                                "Bus seat kilometres per lakh population",
                            titleDisplay: "true",
                            legendDisplay: "false",
                            legendCustom: "false",
                            legendLabelsBoxHeight: legendLabelsBoxHeightLine,
                            maintainAspectRatio: false,
                            heightChart: "260",
                            widthChart: "300",
                            scales: {
                                x: {
                                    display: true,
                                    stacked: false,
                                    titleDisplay: false,
                                    titleText: "",
                                    gridDisplay: false,
                                    beginAtZero: false,
                                },
                                y: {
                                    display: true,
                                    stacked: false,
                                    titleDisplay: true,
                                    titleText:
                                        "Bus seat kilometres per lakh population",
                                    gridDisplay: true,
                                    beginAtZero: true,
                                    max: null,
                                },
                                yRight: yRight,
                            },
                            keyFigure: {
                                label: "bus seat kilometres per lakh population ",
                                url:
                                    api_url +
                                    "charts/data/?part=mobility-indicators&indicator=bus_system_supply&trend=projection&chart=bus_seat_kilometers&chart_data=false&indicator_key_figure=false&chart_key_figure=true&schema_city=",
                                separator: "decimal",
                                position: "bottom",
                            },
                            datasets: [
                                {
                                    type: "line",
                                    label: "",
                                    data: [],
                                    url:
                                        api_url +
                                        "charts/data/?part=mobility-indicators&indicator=bus_system_supply&trend=projection&chart=bus_seat_kilometers&chart_data=True&indicator_key_figure=False&schema_city=",
                                    idColorPalette:
                                        configChartsColorPalettes["7"],
                                    idColor: "0",
                                    borderWidth: 1,
                                    pointStyle: "circle",
                                    pointRadius: 3,
                                    spanGaps: true,
                                    legendCustomCssClass: "",
                                    fill: false,
                                    yAxisID: "y",
                                    order: 2,
                                    tooltipUnit: "",
                                },
                            ],
                        },
                    ],
                },
                iptLicences: {
                    checked: false,
                    titleSideBar: "IPT system",
                    titleIndicator:
                        "Intermediate Public Transport (IPT) system",
                    popupInformation: {
                        status: "false",
                        text: popupInformation[part]["iptLicences"],
                    },
                    keyFigure: {
                        label: "IPT vehicles per lakh population",
                        url:
                            api_url +
                            "charts/data/?part=mobility-indicators&indicator=ipt_licences&trend=projection&chart=ipt_licences&chart_data=False&indicator_key_figure=True&schema_city=",
                        separator: "thousand",
                    },
                    charts: [
                        {
                            typeChart: "bar",
                            multiYear: true,
                            titleChart:
                                "Intermediate Public Transport (IPT) system",
                            titleDisplay: "false",
                            legendDisplay: "false",
                            legendCustom: "false",
                            legendLabelsBoxHeight: legendLabelsBoxHeightOther,
                            maintainAspectRatio: false,
                            heightChart: "220",
                            widthChart: "300",
                            scales: {
                                x: {
                                    display: true,
                                    stacked: false,
                                    titleDisplay: false,
                                    titleText: "",
                                    gridDisplay: false,
                                    beginAtZero: false,
                                },
                                y: {
                                    display: true,
                                    stacked: false,
                                    titleDisplay: true,
                                    titleText:
                                        "IPT vehicles per lakh population",
                                    gridDisplay: true,
                                    beginAtZero: true,
                                    max: null,
                                },
                                yRight: yRight,
                            },
                            keyFigure: {
                                label: "",
                                url: "",
                                separator: "",
                                position: "",
                            },
                            datasets: [
                                {
                                    type: "bar",
                                    label: "",
                                    data: [],
                                    url:
                                        api_url +
                                        "charts/data/?part=mobility-indicators&indicator=ipt_licences&trend=projection&chart=ipt_licences&chart_data=True&indicator_key_figure=False&schema_city=",
                                    idColorPalette:
                                        configChartsColorPalettes["1"],
                                    idColor: "0",
                                    borderWidth: 1,
                                    pointStyle: "circle",
                                    pointRadius: 3,
                                    spanGaps: true,
                                    legendCustomCssClass: "",
                                    fill: false,
                                    yAxisID: "y",
                                    order: 2,
                                    tooltipUnit: "",
                                },
                            ],
                        },
                    ],
                },
            },
        },
        motorisation: {
            idSection: "motorisation",
            questionSection: "question to be defined",
            treeviews: {
                title: "Motorisation",
                indicators: {
                    checked: 0,
                    expand: false,
                },
                layers: {
                    checked: 0,
                    expand: true,
                },
            },
            layers: {
                layerTest: {
                    enable: false,
                    checked: false,
                    initialDisplay: false,
                    excludeCities: [],
                    url: "",
                    zindex: 0,
                    type: "single",
                    popup: {
                        default: {
                            content: {},
                            separators: {
                                decimal: [],
                                thousand: [],
                            },
                        },
                    },
                    titleSideBar: "Layer test",
                    style: {
                        radius: 0,
                        strokeColor: "",
                        lineDash: [],
                    },
                    legend: {
                        default: {
                            title: "",
                            subtitle: "",
                            attribute: "",
                            symbol: "line",
                            legendCustomCssClass: "",
                            class: [
                                {
                                    color: "",
                                    strokeWidth: 0,
                                    label: "Legend test",
                                },
                            ],
                        },
                    },
                },
            },
            indicators: {
                carsMotorcycles: {
                    checked: false,
                    titleSideBar: "Number of cars and motorcycles",
                    titleIndicator: "Motor vehicles per 1'000 inhabitants",
                    popupInformation: {
                        status: "false",
                        text: popupInformation[part]["carsMotorcycles"],
                    },
                    keyFigure: {
                        label: "cars per 1'000 inhabitants",
                        url:
                            api_url +
                            "charts/data/?part=mobility-indicators&indicator=carsMotorcycles&trend=projection&chart=motorcycles&chart_data=false&indicator_key_figure=true&chart_key_figure=false&schema_city=",
                        separator: "thousand",
                    },
                    charts: [
                        {
                            typeChart: "bar",
                            multiYear: true,
                            titleChart: "",
                            titleDisplay: "false",
                            legendDisplay: "true",
                            legendCustom: "false",
                            legendLabelsBoxHeight: legendLabelsBoxHeightOther,
                            maintainAspectRatio: false,
                            heightChart: "200",
                            widthChart: "330",
                            scales: {
                                x: {
                                    display: true,
                                    stacked: false,
                                    titleDisplay: false,
                                    titleText: "",
                                    gridDisplay: false,
                                    beginAtZero: false,
                                },
                                y: {
                                    display: true,
                                    stacked: false,
                                    titleDisplay: true,
                                    titleText: "Vehicles per 1'000 population",
                                    gridDisplay: true,
                                    beginAtZero: false,
                                    max: null,
                                },
                                yRight: yRight,
                            },
                            keyFigure: {
                                label: "two-wheelers per 1'000 inhabitants",
                                url:
                                    api_url +
                                    "charts/data/?part=mobility-indicators&indicator=carsMotorcycles&trend=projection&chart=motorcycles&chart_data=false&indicator_key_figure=false&chart_key_figure=true&schema_city=",
                                separator: "thousand",
                                position: "top",
                            },
                            datasets: [
                                {
                                    type: "bar",
                                    label: "Cars",
                                    data: [],
                                    url:
                                        api_url +
                                        "charts/data/?part=mobility-indicators&indicator=carsMotorcycles&trend=projection&chart=cars&chart_data=True&indicator_key_figure=False&schema_city=",
                                    idColorPalette:
                                        configChartsColorPalettes["6"],
                                    idColor: "2",
                                    borderWidth: 1,
                                    pointStyle: "circle",
                                    pointRadius: 3,
                                    spanGaps: true,
                                    legendCustomCssClass: "",
                                    fill: false,
                                    yAxisID: "y",
                                    order: 2,
                                    tooltipUnit: "",
                                },
                                {
                                    type: "bar",
                                    label: "Motorcycles",
                                    data: [],
                                    url:
                                        api_url +
                                        "charts/data/?part=mobility-indicators&indicator=carsMotorcycles&trend=projection&chart=motorcycles&chart_data=True&chart_multi_year=True&indicator_key_figure=False&schema_city=",
                                    idColorPalette:
                                        configChartsColorPalettes["6"],
                                    idColor: "4",
                                    borderWidth: 1,
                                    pointStyle: "circle",
                                    pointRadius: 3,
                                    spanGaps: true,
                                    legendCustomCssClass: "",
                                    fill: false,
                                    yAxisID: "y",
                                    order: 2,
                                    tooltipUnit: "",
                                },
                            ],
                        },
                    ],
                },
            },
        },
        externalities: {
            idSection: "externalities",
            questionSection: "question to be defined",
            treeviews: {
                title: "Externalities",
                indicators: {
                    checked: 0,
                    expand: false,
                },
                layers: {
                    checked: 2,
                    expand: true,
                },
            },
            layers: {
                layerTest: {
                    enable: false,
                    checked: false,
                    initialDisplay: false,
                    excludeCities: [],
                    url: "",
                    zindex: 0,
                    type: "single",
                    popup: {
                        default: {
                            content: {},
                            separators: {
                                decimal: [],
                                thousand: [],
                            },
                        },
                    },
                    titleSideBar: "Layer test",
                    style: {
                        radius: 0,
                        strokeColor: "",
                        lineDash: [],
                    },
                    legend: {
                        default: {
                            title: "",
                            subtitle: "",
                            attribute: "",
                            symbol: "line",
                            legendCustomCssClass: "",
                            class: [
                                {
                                    color: "",
                                    strokeWidth: 0,
                                    label: "Legend test",
                                },
                            ],
                        },
                    },
                },
            },
            indicators: {
                trafficFatalities: {
                    checked: false,
                    titleSideBar: "Traffic fatalities",
                    titleIndicator: "Traffic fatalities",
                    popupInformation: {
                        status: "false",
                        text: popupInformation[part]["trafficFatalities"],
                    },
                    keyFigure: {
                        label: "traffic fatalities per lakh population",
                        url:
                            api_url +
                            "charts/data/?part=mobility-indicators&indicator=traffic_fatalities&trend=projection&chart=traffic_fatalities&chart_data=False&indicator_key_figure=True&schema_city=",
                        separator: "decimal",
                    },
                    charts: [
                        {
                            typeChart: "line",
                            multiYear: true,
                            titleChart: "",
                            titleDisplay: "false",
                            legendDisplay: "false",
                            legendCustom: "false",
                            legendLabelsBoxHeight: legendLabelsBoxHeightLine,
                            maintainAspectRatio: true,
                            heightChart: "220",
                            widthChart: "300",
                            scales: {
                                x: {
                                    display: true,
                                    stacked: false,
                                    titleDisplay: false,
                                    titleText: "",
                                    gridDisplay: false,
                                    beginAtZero: false,
                                },
                                y: {
                                    display: true,
                                    stacked: false,
                                    titleDisplay: true,
                                    titleText:
                                        "Traffic fatalities per lakh population",
                                    gridDisplay: true,
                                    beginAtZero: true,
                                    max: null,
                                },
                                yRight: yRight,
                            },
                            keyFigure: {
                                label: "",
                                url: "",
                                separator: "",
                                position: "",
                            },
                            datasets: [
                                {
                                    type: "line",
                                    label: "",
                                    data: [],
                                    url:
                                        api_url +
                                        "charts/data/?part=mobility-indicators&indicator=traffic_fatalities&trend=projection&chart=traffic_fatalities&chart_data=True&chart_multi_year=True&indicator_key_figure=False&schema_city=",
                                    idColorPalette:
                                        configChartsColorPalettes["7"],
                                    idColor: "0",
                                    borderWidth: 1,
                                    pointStyle: "circle",
                                    pointRadius: 3,
                                    spanGaps: true,
                                    legendCustomCssClass: "",
                                    fill: false,
                                    yAxisID: "y",
                                    order: 2,
                                    tooltipUnit: "",
                                },
                            ],
                        },
                    ],
                },
                airQuality: {
                    checked: false,
                    titleSideBar: "Air Quality",
                    titleIndicator: "Air quality (PM10)",
                    popupInformation: {
                        status: "false",
                        text: popupInformation[part]["airQuality"],
                    },
                    keyFigure: {
                        label: "µg/m3",
                        url:
                            api_url +
                            "charts/data/?part=mobility-indicators&indicator=air_quality&trend=projection&chart=air_quality&chart_data=False&indicator_key_figure=True&schema_city=",
                        separator: "decimal",
                    },
                    charts: [
                        {
                            typeChart: "line",
                            multiYear: true,
                            titleChart: "",
                            titleDisplay: "false",
                            legendDisplay: "false",
                            legendCustom: "false",
                            legendLabelsBoxHeight: legendLabelsBoxHeightLine,
                            maintainAspectRatio: false,
                            heightChart: "220",
                            widthChart: "300",
                            scales: {
                                x: {
                                    display: true,
                                    stacked: false,
                                    titleDisplay: false,
                                    titleText: "",
                                    gridDisplay: false,
                                    beginAtZero: false,
                                },
                                y: {
                                    display: true,
                                    stacked: false,
                                    titleDisplay: true,
                                    titleText: "µg/m3 (PM10)",
                                    gridDisplay: true,
                                    beginAtZero: true,
                                    max: null,
                                },
                                yRight: yRight,
                            },
                            keyFigure: {
                                label: "",
                                url: "",
                                separator: "",
                                position: "",
                            },
                            datasets: [
                                {
                                    type: "line",
                                    label: "",
                                    data: [],
                                    url:
                                        api_url +
                                        "charts/data/?part=mobility-indicators&indicator=air_quality&trend=projection&chart=air_quality&chart_data=True&indicator_key_figure=False&schema_city=",
                                    idColorPalette:
                                        configChartsColorPalettes["7"],
                                    idColor: "0",
                                    borderWidth: 1,
                                    pointStyle: "circle",
                                    pointRadius: 3,
                                    spanGaps: true,
                                    legendCustomCssClass: "",
                                    fill: false,
                                    yAxisID: "y",
                                    order: 2,
                                    tooltipUnit: "",
                                },
                            ],
                        },
                    ],
                },
                greenhouseGasEmissions: {
                    checked: false,
                    titleSideBar: "Greenhouse gas emissions",
                    titleIndicator:
                        "Greenhouse gas emissions for the transport sector",
                    popupInformation: {
                        status: "false",
                        text: popupInformation[part]["greenhouseGasEmissions"],
                    },
                    keyFigure: {
                        label: "eq. Kg CO2 emissions per inhabitant per year",
                        url:
                            api_url +
                            "charts/data/?part=mobility-indicators&indicator=greenhouse_gas_emissions&trend=projection&chart=greenhouse_gas_emissions&chart_data=False&indicator_key_figure=True&schema_city=",
                        separator: "thousand",
                    },
                    charts: [
                        {
                            typeChart: "bar",
                            multiYear: true,
                            titleChart: "",
                            titleDisplay: "false",
                            legendDisplay: "false",
                            legendCustom: "false",
                            legendLabelsBoxHeight: legendLabelsBoxHeightOther,
                            maintainAspectRatio: false,
                            heightChart: "220",
                            widthChart: "300",
                            scales: {
                                x: {
                                    display: true,
                                    stacked: false,
                                    titleDisplay: false,
                                    titleText: "",
                                    gridDisplay: false,
                                    beginAtZero: false,
                                },
                                y: {
                                    display: true,
                                    stacked: false,
                                    titleDisplay: true,
                                    titleText: "Kg C02 / year",
                                    gridDisplay: true,
                                    beginAtZero: true,
                                    max: null,
                                },
                                yRight: yRight,
                            },
                            keyFigure: {
                                label: "",
                                url: "",
                                separator: "",
                                position: "",
                            },
                            datasets: [
                                {
                                    type: "bar",
                                    label: "",
                                    data: [],
                                    url:
                                        api_url +
                                        "charts/data/?part=mobility-indicators&indicator=greenhouse_gas_emissions&trend=projection&chart=greenhouse_gas_emissions&chart_data=True&indicator_key_figure=False&schema_city=",
                                    idColorPalette:
                                        configChartsColorPalettes["0"],
                                    idColor: "0",
                                    borderWidth: 1,
                                    pointStyle: "circle",
                                    pointRadius: 3,
                                    spanGaps: true,
                                    legendCustomCssClass: "",
                                    fill: false,
                                    yAxisID: "y",
                                    order: 2,
                                    tooltipUnit: "",
                                },
                            ],
                        },
                    ],
                },
            },
        },
        economicsMobility: {
            idSection: "economicsMobility",
            questionSection: "question to be defined",
            treeviews: {
                title: "Economics",
                indicators: {
                    checked: 0,
                    expand: false,
                },
                layers: {
                    checked: 2,
                    expand: true,
                },
            },
            layers: {
                layerTest: {
                    enable: false,
                    checked: false,
                    initialDisplay: false,
                    excludeCities: [],
                    url: "",
                    zindex: 0,
                    type: "single",
                    popup: {
                        default: {
                            content: {},
                            separators: {
                                decimal: [],
                                thousand: [],
                            },
                        },
                    },
                    titleSideBar: "Layer test",
                    style: {
                        radius: 0,
                        strokeColor: "",
                        lineDash: [],
                    },
                    legend: {
                        default: {
                            title: "",
                            subtitle: "",
                            attribute: "",
                            symbol: "line",
                            legendCustomCssClass: "",
                            class: [
                                {
                                    color: "",
                                    strokeWidth: 0,
                                    label: "Legend test",
                                },
                            ],
                        },
                    },
                },
            },
            indicators: {
                householdBudgetTransportation: {
                    checked: false,
                    titleSideBar: "Household dedicated budget",
                    titleIndicator:
                        "Household budget dedicated to transportation in low-income families",
                    popupInformation: {
                        status: "false",
                        text: popupInformation[part][
                            "householdBudgetTransportation"
                        ],
                    },
                    keyFigure: {
                        label: "% of the budget dedicated to transportation ",
                        url:
                            api_url +
                            "charts/data/?part=mobility-indicators&indicator=household_budget_transportation&trend=projection&chart=household_budget_transportation&chart_data=False&indicator_key_figure=True&schema_city=",
                        separator: "",
                    },
                    charts: [
                        {
                            typeChart: "line",
                            multiYear: true,
                            titleChart: "Household budget transportation",
                            titleDisplay: "false",
                            legendDisplay: "false",
                            legendCustom: "false",
                            legendLabelsBoxHeight: legendLabelsBoxHeightLine,
                            maintainAspectRatio: true,
                            heightChart: "",
                            widthChart: "",
                            scales: {
                                x: {
                                    display: true,
                                    stacked: false,
                                    titleDisplay: false,
                                    titleText: "",
                                    gridDisplay: false,
                                    beginAtZero: false,
                                },
                                y: {
                                    display: true,
                                    stacked: false,
                                    titleDisplay: true,
                                    titleText: "%",
                                    gridDisplay: true,
                                    beginAtZero: true,
                                    max: null,
                                },
                                yRight: yRight,
                            },
                            keyFigure: {
                                label: "",
                                url: "",
                                separator: "",
                                position: "",
                            },
                            datasets: [
                                {
                                    type: "line",
                                    label: "",
                                    data: [],
                                    url:
                                        api_url +
                                        "charts/data/?part=mobility-indicators&indicator=household_budget_transportation&trend=projection&chart=household_budget_transportation&schema_city=",
                                    idColorPalette:
                                        configChartsColorPalettes["7"],
                                    idColor: "0",
                                    borderWidth: 1,
                                    pointStyle: "circle",
                                    pointRadius: 3,
                                    spanGaps: true,
                                    legendCustomCssClass: "",
                                    fill: false,
                                    yAxisID: "y",
                                    order: 2,
                                    tooltipUnit: "",
                                },
                            ],
                        },
                    ],
                },
                budgetInvestmentPublicTransport: {
                    checked: false,
                    titleSideBar: "Investment in Public Transport",
                    titleIndicator:
                        "Annual investment budget in Public Transport",
                    popupInformation: {
                        status: "false",
                        text: popupInformation[part][
                            "investmentPublicTransport"
                        ],
                    },
                    keyFigure: {
                        label: "INR per inhabitant",
                        url:
                            api_url +
                            "charts/data/?part=mobility-indicators&indicator=investment_public_transport&trend=projection&chart=investment_public_transport&chart_data=False&indicator_key_figure=True&schema_city=",
                        separator: "thousand",
                    },
                    charts: [
                        {
                            typeChart: "bar",
                            multiYear: true,
                            titleChart: "",
                            titleDisplay: "false",
                            legendDisplay: "false",
                            legendCustom: "false",
                            legendLabelsBoxHeight: legendLabelsBoxHeightLine,
                            maintainAspectRatio: true,
                            heightChart: "",
                            widthChart: "",
                            scales: {
                                x: {
                                    display: true,
                                    stacked: false,
                                    titleDisplay: false,
                                    titleText: "",
                                    gridDisplay: false,
                                    beginAtZero: false,
                                },
                                y: {
                                    display: true,
                                    stacked: false,
                                    titleDisplay: true,
                                    titleText: "INR per inhabitant",
                                    gridDisplay: true,
                                    beginAtZero: true,
                                    max: null,
                                },
                                yRight: yRight,
                            },
                            keyFigure: {
                                label: "",
                                url: "",
                                separator: "",
                                position: "",
                            },
                            datasets: [
                                {
                                    type: "bar",
                                    label: "",
                                    data: [],
                                    url:
                                        api_url +
                                        "charts/data/?part=mobility-indicators&indicator=investment_public_transport&trend=projection&chart=investment_public_transport&chart_data=True&indicator_key_figure=False&schema_city=",
                                    idColorPalette:
                                        configChartsColorPalettes["7"],
                                    idColor: "0",
                                    borderWidth: 1,
                                    pointStyle: "circle",
                                    pointRadius: 3,
                                    spanGaps: true,
                                    legendCustomCssClass: "",
                                    fill: false,
                                    yAxisID: "y",
                                    order: 2,
                                    tooltipUnit: "",
                                },
                            ],
                        },
                    ],
                },
                publicTransportAnnualBudgetOperationsAdministration: {
                    checked: false,
                    titleSideBar: "Public Transport annual budget",
                    titleIndicator:
                        "Annual budget for operations and administration of Public Transport",
                    popupInformation: {
                        status: "false",
                        text: popupInformation[part][
                            "publicTransportAnnualBudgetOperationsAdministration"
                        ],
                    },
                    keyFigure: {
                        label: "INR per inhabitant",
                        url:
                            api_url +
                            "charts/data/?part=mobility-indicators&indicator=public_transport_annual_budget_operations_administration&trend=projection&chart=public_transport_annual_budget_operations_administration&chart_data=False&indicator_key_figure=True&schema_city=",
                        separator: "thousand",
                    },
                    charts: [
                        {
                            typeChart: "bar",
                            multiYear: true,
                            titleChart: "",
                            titleDisplay: "false",
                            legendDisplay: "false",
                            legendCustom: "false",
                            legendLabelsBoxHeight: legendLabelsBoxHeightLine,
                            maintainAspectRatio: true,
                            heightChart: "",
                            widthChart: "",
                            scales: {
                                x: {
                                    display: true,
                                    stacked: false,
                                    titleDisplay: false,
                                    titleText: "",
                                    gridDisplay: false,
                                    beginAtZero: false,
                                },
                                y: {
                                    display: true,
                                    stacked: false,
                                    titleDisplay: true,
                                    titleText: "INR per inhabitant",
                                    gridDisplay: true,
                                    beginAtZero: true,
                                    max: null,
                                },
                                yRight: yRight,
                            },
                            keyFigure: {
                                label: "",
                                url: "",
                                separator: "",
                                position: "",
                            },
                            datasets: [
                                {
                                    type: "bar",
                                    label: "",
                                    data: [],
                                    url:
                                        api_url +
                                        "charts/data/?part=mobility-indicators&indicator=public_transport_annual_budget_operations_administration&trend=projection&chart=public_transport_annual_budget_operations_administration&chart_data=True&indicator_key_figure=False&schema_city=",
                                    idColorPalette:
                                        configChartsColorPalettes["7"],
                                    idColor: "0",
                                    borderWidth: 1,
                                    pointStyle: "circle",
                                    pointRadius: 3,
                                    spanGaps: true,
                                    legendCustomCssClass: "",
                                    fill: false,
                                    yAxisID: "y",
                                    order: 2,
                                    tooltipUnit: "",
                                },
                            ],
                        },
                    ],
                },
            },
        },
    },
    reducers: {
        // Reverse the checked status of the indicator/layer checkbox
        reverseCheckedStatusIndicatorLayer: (state, props) => {
            const idSection = props.payload.idSection;
            const idIndicatorLayer = props.payload.idIndicatorLayer;
            const typeTreeView = props.payload.typeTreeView;
            if (typeTreeView === "indicators") {
                state[idSection].indicators[idIndicatorLayer].checked =
                    !state[idSection].indicators[idIndicatorLayer].checked;
            } else {
                state[idSection].layers[idIndicatorLayer].checked =
                    !state[idSection].layers[idIndicatorLayer].checked;
            }
        },
        // Update the checked status of the layer checkbox
        updateCheckedStatusLayer: (state, props) => {
            const idSection = props.payload.idSection;
            const idLayer = props.payload.idLayer;
            const idCheckedStatus = props.payload.idCheckedStatus;
            state[idSection].layers[idLayer].checked = idCheckedStatus;
        },
        // Update the checked status of the treeview checkbox: unchecked, indeterminate, checked
        updateCheckedStatusTreeview: (state, props) => {
            const idSection = props.payload.idSection;
            const idCheckedStatus = props.payload.idCheckedStatus;
            const typeTreeView = props.payload.typeTreeView;
            state[idSection].treeviews[typeTreeView].checked = idCheckedStatus;
        },
        // Update the expand status of the treeview checkbox
        updateExpandStatusTreeviewIndicators: (state, props) => {
            const idSection = props.payload.idSection;
            const idExpandStatus = props.payload.idExpandStatus;
            const typeTreeView = props.payload.typeTreeView;
            state[idSection].treeviews[typeTreeView].expand = idExpandStatus;
        },
    },
});

export const {
    reverseCheckedStatusIndicatorLayer,
    updateCheckedStatusLayer,
    updateCheckedStatusTreeview,
    updateExpandStatusTreeviewIndicators,
} = dashboardMobilityIndicators.actions;

export default dashboardMobilityIndicators.reducer;
