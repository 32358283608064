import { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router";
import React, { useEffect } from "react";
import FormParticipation from "./digital-participation/FormParticipation";
import store from "../store";
import { Box } from "@mui/material";
import { updateCurrentCity } from "../stores/Cities";
import { capitalize } from "@mui/material";
import "../styles/DigitalParticipation.css";

export default function DigitalParticipation() {
  const dispatch = useDispatch();
  const urlParams = useParams();
  const navigate = useNavigate();

  const listCities = Object.keys(store.getState().cities.listCities);
  const [currCity, setCurrCity] = useState(store.getState().cities.currentCity);

  useEffect(() => {
    const urlParamCityName = urlParams.cityName;
    const currentCity = store.getState().cities.currentCity;
    if (
        urlParamCityName !== currentCity &&
        listCities.includes(urlParamCityName)
    ) {
        // Update of the current city in the store
        dispatch(updateCurrentCity(urlParamCityName));
    } else {
        // Invalid city and no city yet explored
        if (currentCity === "") {
            navigate("/home");
        }
        // Redirection to the previous city explored
        else if (urlParamCityName !== currentCity) {
            navigate("/sump/digital-participation/" + currentCity);
        }
    }
  }, [urlParams, listCities, dispatch, navigate]);


  store.subscribe(() => {
    setCurrCity(capitalize(store.getState().cities.currentCity));
  });

  return (
    <div className="intro-text">
    <Box
        display = 'flex'
        justifyContent='center'
        p={1}
        m= {1}
        bgcolor="white"
    >
      <Box
        display="flex"
        width={'75%'}
        bgcolor="#3e7aa4"
        color="#ffffff"
        p={1}
        borderRadius= {2}
      >
        <div>
          <p>Tell us about your recent experience in the streets of <b>{currCity}</b></p>
          <p>This survey will be open until *February 28th, 2023*. Please take 5 minutes to provide your feedback before that time. Your answers are anonymous and will be analysed for our upcoming report on Urban Mobility User Perception.</p>
          <p>For this survey, we are focusing on 3 modes of transport: Bus, Bicycle and Walk.</p>
          <p>This feedback is very helpful for the Urban Mobility Observatory and will be updated every year. Thank you so much for taking the time to complete this survey.</p>
        </div>
      </Box>
      </Box>
          <FormParticipation />
      </div>
  );
}
