import { React, Component } from "react";
import "chart.js/auto";
import "../styles/Dashboard.css";
import "../styles/Charts.css";
import parse from "html-react-parser";
import store from "../store";
import { formatIndianNumberChart } from "../utils";
import { Chart as ReactChart } from "react-chartjs-2";

const { config } = require("../settings");

class Charts extends Component {
    constructor(props) {
        super(props);

        this.part = props.part;
        this.configCharts = config.charts;
        this.xAxisMinYear =
            this.part === "mobility-indicators"
                ? this.configCharts.xAxisMinYearMobilityIndicators
                : this.configCharts.xAxisMinYearSumpTrajectory;
        this.xAxisMaxYear =
            this.part === "mobility-indicators"
                ? this.configCharts.xAxisMaxYearMobilityIndicators
                : this.configCharts.xAxisMaxYearSumpTrajectory;

        const chartsData = props.data.charts;
        const keyFigureIndicator = props.data.keyFigure;

        this.state = {
            chartsData,
            data: [],
            keyFigureIndicator,
            currentYear: store.getState().years.currentYear,
            currentCity: store.getState().cities.currentCity,
        };

        for (let [key, value] of Object.entries(this.state.chartsData)) {
            this.getData(key, value);
        }

        this.getDataKeyFigureIndicator();
    }

    // When the year of the indicators changes, update the charts and key figures
    componentDidUpdate(_prevProps, prevState) {
        const storeCurrentYear = store.getState().years.currentYear;
        const storeCurrentCity = store.getState().cities.currentCity;
        if (
            prevState.currentYear !== storeCurrentYear ||
            prevState.currentCity !== storeCurrentCity
        ) {
            this.setState({
                currentYear: storeCurrentYear,
                currentCity: storeCurrentCity,
            });
            // Update of the charts
            for (let [key, value] of Object.entries(this.state.chartsData)) {
                this.getData(key, value);
            }
            // Update of the key figures
            this.getDataKeyFigureIndicator();
        }
    }

    // Fetch data from the api
    fetchData = async (
        url,
        multiYearIndicator = true,
        keyFigureIndicator = false,
        keyFigureChart = false
    ) => {
        let urlCityOptions = url + this.state.currentCity;

        urlCityOptions += `&chart_multi_year=${multiYearIndicator}`;
        // Not a multi-year chart, specify the year
        if (!multiYearIndicator) {
            urlCityOptions += `&chart_year=${this.state.currentYear}`;
        }
        // Specify the year of the key figure
        if (keyFigureIndicator || keyFigureChart) {
            urlCityOptions += `&indicator_key_figure_year=${this.state.currentYear}`;
        }
        // Specify there is a key number associated with the chart
        if (keyFigureChart) {
            urlCityOptions += `&chart_key_figure=true`;
        }

        const response = await fetch(urlCityOptions);
        const data = await response.json();
        return data;
    };

    // API call to get the indicator key figure data and update the state
    getDataKeyFigureIndicator() {
        let keyFigureIndicator = this.state.keyFigureIndicator;
        let keyFigureIndicatorUrl = keyFigureIndicator.url;
        let keyFigureIndicatorSeparator = keyFigureIndicator.separator;
        // Fetch if a city has been selected
        if (keyFigureIndicatorUrl !== "" && this.state.currentCity !== "") {
            this.fetchData(keyFigureIndicatorUrl, false, true, false)
                .then((resultApi) => {
                    this.setState({
                        keyFigureIndicator: {
                            ...keyFigureIndicator,
                            data: formatIndianNumberChart(
                                resultApi["data_indicator_key_figure"],
                                keyFigureIndicatorSeparator
                            ),
                        },
                    });
                })
                // API unavailable
                .catch((_error) => {
                    this.setState({
                        keyFigureIndicator: {
                            ...keyFigureIndicator,
                            data: "",
                        },
                    });
                });
        }
    }

    // API call to get the chart data and update the state
    getData(key, chart) {
        const datasetsChart = chart.datasets;
        let dataChart = {
            labels: [],
            datasets: [],
            keyFigure: {},
            error: "",
        };
        let data = this.state.data;

        const arrayChartSingleColor = ["line", "bar", "area"];
        // Fetch if a city has been selected
        if (this.state.currentCity.length !== 0) {
            for (let dataset of Object.values(datasetsChart)) {
                this.fetchData(
                    dataset.url,
                    chart.multiYear,
                    false,
                    chart.keyFigure.url !== "" ? true : false
                )
                    .then((resultApi) => {
                        let resultApiData = resultApi.data;
                        let resultApiDataChartKeyFigure =
                            resultApi.data_chart_key_figure;
                        let lengthData = resultApiData.length;
                        let typeChart = chart.typeChart;
                        let labelDataset = dataset.label;

                        // Different rendering according to the charts (single color for line & bar charts)
                        let backgroundColorChart = "";
                        let borderColorChart = "";
                        if (arrayChartSingleColor.includes(typeChart)) {
                            let datasetIdColorPalette = dataset.idColorPalette;
                            let datasetIdColor = dataset.idColor;
                            backgroundColorChart =
                                datasetIdColorPalette.backgroundColor[
                                    datasetIdColor
                                ];
                            // Use of the same color as the background for the border of the real data
                            if (labelDataset.includes("observed after")) {
                                borderColorChart = backgroundColorChart;
                            } else {
                                borderColorChart =
                                    dataset.idColorPalette.borderColor[
                                        datasetIdColor
                                    ];
                            }
                        } else {
                            let datasetIdColorPalette = dataset.idColorPalette;
                            backgroundColorChart =
                                datasetIdColorPalette.backgroundColor.slice(
                                    0,
                                    lengthData
                                );
                            borderColorChart =
                                dataset.idColorPalette.borderColor.slice(
                                    0,
                                    lengthData
                                );
                        }

                        // Use labels from the response api except for the reality data
                        // So that the points are well placed on the x-axis
                        if (!dataset.url.includes("trend=reality")) {
                            dataChart.labels = resultApi.labels;
                        }

                        dataChart.datasets.push({
                            type: dataset.type,
                            label: labelDataset,
                            data: resultApiData,
                            backgroundColor: backgroundColorChart,
                            borderColor: borderColorChart,
                            fill: dataset.fill,
                            spanGaps: dataset.spanGaps,
                            borderWidth: dataset.borderWidth,
                            pointStyle: dataset.pointStyle,
                            pointRadius: dataset.pointRadius,
                            yAxisID: dataset.yAxisID,
                            order: dataset.order,
                            tooltipUnit: dataset.tooltipUnit,
                        });
                        dataChart.keyFigure = {
                            label: chart.keyFigure.label,
                            data: formatIndianNumberChart(
                                resultApiDataChartKeyFigure,
                                chart.keyFigure.separator
                            ),
                            position: chart.keyFigure.position,
                        };
                    })
                    // An error occurred at the time of API call
                    .catch((_error) => {
                        dataChart.error = "Error when calling the API";
                    })
                    // Update of the state
                    .finally(() => {
                        data[key] = dataChart;
                        this.setState({
                            data: data,
                        });
                    });
            }
        }
    }

    render() {
        // Customization of the legend by using icons instead of colored squares
        const renderChartLegendCustom = (chart) => {
            let descriptionLegendCustom = "";

            const datasetsChart = chart.datasets;
            for (let dataset of Object.values(datasetsChart)) {
                descriptionLegendCustom += `<div class="chart-legend-custom-row">
                <p class="chart-legend-custom-icon ${dataset.legendCustomCssClass}"></p>
                <p> ${dataset.label}</p>
                </div> `;
            }

            return parse(descriptionLegendCustom);
        };

        // Generic function to generate a chart
        const renderChart = (chart, dataChart) => {
            // Regenerate the object expected by the data variable of Chart
            const datasetsValid = [];
            dataChart.datasets.forEach((dataset) =>
                datasetsValid.push({
                    type: dataset.type,
                    label: dataset.label,
                    data: dataset.data,
                    backgroundColor: dataset.backgroundColor,
                    borderColor: dataset.borderColor,
                    borderWidth: dataset.borderWidth,
                    fill: dataset.fill,
                    spanGaps: dataset.spanGaps,
                    pointStyle: dataset.pointStyle,
                    pointRadius: dataset.pointRadius,
                    yAxisID: dataset.yAxisID,
                    order: dataset.order,
                    tooltipUnit: dataset.tooltipUnit,
                })
            );
            let dataChartValid = {
                labels: dataChart.labels,
                datasets: datasetsValid,
            };

            // The order of the datasets is displayed as the order defined in the store
            let chartDatasets = chart.datasets;
            if (chartDatasets.length > 1) {
                let chartDatasetsLabelsSort = [];
                chartDatasets.forEach((dataset) =>
                    chartDatasetsLabelsSort.push(dataset.label)
                );
                let datasetsValidSort = [];
                for (let label of chartDatasetsLabelsSort) {
                    for (let dataset of datasetsValid) {
                        if (label === dataset.label) {
                            datasetsValidSort.push(dataset);
                            break;
                        }
                    }
                }
                dataChartValid.datasets = datasetsValidSort;
            }

            // Key figure associated with the chart //
            let dataChartKeyFigureData = dataChart.keyFigure.data;
            let dataChartKeyFigureLabel = dataChart.keyFigure.label;
            let dataChartKeyFigurePosition = dataChart.keyFigure.position;
            if (
                dataChartKeyFigureLabel !== undefined &&
                dataChartKeyFigureLabel.startsWith("%")
            ) {
                dataChartKeyFigureData =
                    Number(dataChartKeyFigureData).toLocaleString() + "%";
                dataChartKeyFigureLabel = dataChartKeyFigureLabel.substring(1);
            }
            let contentDivDataChartKeyFigure = (
                <div className="dashboard-key-figure-container dashboard-key-figure-chart-container">
                    <p className="dashboard-key-figure-data">
                        {dataChartKeyFigureData}
                    </p>
                    <p className="dashboard-key-figure-label">
                        {dataChartKeyFigureLabel}
                    </p>
                </div>
            );
            return (
                <div>
                    {/* Key figure associated with the chart if the position is equal to top */}
                    {dataChartKeyFigureLabel !== "" &&
                    dataChartKeyFigurePosition === "top" ? (
                        contentDivDataChartKeyFigure
                    ) : (
                        ""
                    )}
                    {/* Custom Legend */}
                    {chart.legendCustom === "true" &&
                        dataChart.error === "" && (
                            <div className="chart-legend-custom-container ">
                                {renderChartLegendCustom(chart)}
                            </div>
                        )}
                    {/* Display the chart if there was no api error and if there is data */}
                    {dataChart.labels.length !== 0 &&
                    dataChartValid.datasets["0"].data.length ? (
                        <div className="">
                            <ReactChart
                                type={
                                    chart.typeChart === "area"
                                        ? "line"
                                        : chart.typeChart
                                }
                                data={dataChartValid}
                                height={chart.heightChart}
                                width={chart.widthChart}
                                options={{
                                    responsive: true,
                                    maintainAspectRatio:
                                        chart.maintainAspectRatio,
                                    interaction: {
                                        mode: "index",
                                    },
                                    locale: "en-IN",
                                    plugins: {
                                        legend: {
                                            display:
                                                chart.legendDisplay ===
                                                    "true" &&
                                                chart.legendCustom !== "true",
                                            position: "top",
                                            labels: {
                                                boxHeight:
                                                    chart.legendLabelsBoxHeight,
                                                boxWidth: 30,
                                            },
                                            onClick: (e) => e.stopPropagation(),
                                        },
                                        title: {
                                            display:
                                                chart.titleDisplay === "true",
                                            text: chart.titleChart,
                                            font: {
                                                size: "14",
                                            },
                                        },
                                        tooltip: {
                                            callbacks: {
                                                title: function (tooltipItem) {
                                                    let title =
                                                        tooltipItem[0].label;
                                                    return title.replace(
                                                        ",",
                                                        ""
                                                    );
                                                },
                                                // Add a unit at the end of the tooltip label
                                                label: function (tooltipItem) {
                                                    let tooltipItemDataset =
                                                        tooltipItem.dataset;
                                                    let tooltipLabel =
                                                        tooltipItemDataset.label;
                                                    if (tooltipLabel !== "") {
                                                        tooltipLabel =
                                                            tooltipLabel +
                                                            " : ";
                                                    }
                                                    let tooltipFormattedValue =
                                                        tooltipItem.formattedValue;

                                                    return (
                                                        tooltipLabel +
                                                        tooltipFormattedValue +
                                                        tooltipItemDataset.tooltipUnit
                                                    );
                                                },
                                            },
                                        },
                                    },
                                    scales: {
                                        x: {
                                            type: "linear",
                                            display: chart.scales.x.display,
                                            stacked: chart.scales.x.stacked,
                                            title: {
                                                display:
                                                    chart.scales.x.titleDisplay,
                                                text: chart.scales.x.titleText,
                                            },
                                            grid: {
                                                display:
                                                    chart.scales.x.gridDisplay,
                                            },
                                            beginAtZero:
                                                chart.scales.x.beginAtZero,
                                            min: this.xAxisMinYear,
                                            max: this.xAxisMaxYear,
                                            ticks: {
                                                // Conversion of dates to integers (to avoid rendering 2,010 for example)
                                                callback: function (date) {
                                                    return Number.isInteger(
                                                        date
                                                    )
                                                        ? date
                                                        : null;
                                                },
                                            },
                                        },
                                        y: {
                                            type: "linear",
                                            display: chart.scales.y.display,
                                            stacked: chart.scales.y.stacked,
                                            position: "left",
                                            title: {
                                                display:
                                                    chart.scales.y.titleDisplay,
                                                text: chart.scales.y.titleText,
                                            },
                                            grid: {
                                                display:
                                                    chart.scales.y.gridDisplay,
                                            },
                                            beginAtZero:
                                                chart.scales.y.beginAtZero,
                                            max: chart.scales.y.max,
                                        },
                                        yRight: {
                                            type: "linear",
                                            display:
                                                chart.scales.yRight.display,
                                            stacked:
                                                chart.scales.yRight.stacked,
                                            position: "right",
                                            title: {
                                                display:
                                                    chart.scales.yRight
                                                        .titleDisplay,
                                                text: chart.scales.yRight
                                                    .titleText,
                                                color: chart.scales.yRight
                                                    .titleColor,
                                            },
                                            grid: {
                                                display:
                                                    chart.scales.yRight
                                                        .gridDisplay,
                                                color: "#ff0000",
                                            },
                                            beginAtZero:
                                                chart.scales.yRight.beginAtZero,
                                        },
                                    },
                                    clip: false,
                                }}
                            />
                        </div>
                    ) : (
                        <div className="api-error-no-data-container">
                            <p className="api-error-no-data-description">
                                {dataChart.error !== ""
                                    ? dataChart.error
                                    : "No data to display for the selected year"}
                            </p>
                            <p className="api-error-no-data-icon"></p>
                        </div>
                    )}
                    {/* Key figure associated with the chart if the position is equal to bottom */}
                    {dataChartKeyFigureLabel !== "" &&
                    dataChartKeyFigurePosition === "bottom" ? (
                        contentDivDataChartKeyFigure
                    ) : (
                        ""
                    )}
                </div>
            );
        };

        const listCharts = [];
        for (let [key, value] of Object.entries(this.state.chartsData)) {
            if (this.state.data[key]) {
                listCharts.push(
                    <div key={key}>
                        {renderChart(value, this.state.data[key])}
                    </div>
                );
            }
        }

        // Generic function to generate the key figure associated with the indicator
        const renderKeyFigureIndicator = () => {
            let keyFigureIndicator = this.state.keyFigureIndicator;
            let keyFigureIndicatorData = keyFigureIndicator.data;
            let KeyFigureIndicatorLabel = keyFigureIndicator.label;

            if (keyFigureIndicatorData && keyFigureIndicatorData !== "None" && keyFigureIndicatorData !== "NaN") {
                // No space between the key figure value and the percentage character
                if (KeyFigureIndicatorLabel.startsWith("%")) {
                    keyFigureIndicatorData =
                        Number(keyFigureIndicatorData).toLocaleString() + "%";
                    KeyFigureIndicatorLabel =
                        KeyFigureIndicatorLabel.substring(1);
                }
                return (
                    <div className="dashboard-key-figure-container">
                        <p className="dashboard-key-figure-data">
                            {keyFigureIndicatorData}
                        </p>
                        <p className="dashboard-key-figure-label">
                            {KeyFigureIndicatorLabel}
                        </p>
                    </div>
                );
            }
        };

        return (
            <div>
                {renderKeyFigureIndicator()}
                <div className="dashboard-charts-container">{listCharts}</div>
            </div>
        );
    }
}

export default Charts;
