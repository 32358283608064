// Formatting of Indian numbers in the charts
export const formatIndianNumberChart = (data, separator) => {
    if (separator === "") {
        return data;
    } else if (separator === "decimal") {
        return data.replace(",", ".");
    }
    // Thousand separator
    else {
        return Intl.NumberFormat("en-IN", {
            maximumSignificantDigits: 3,
        }).format(data);
    }
};

// Formatting of Indian numbers in the map popup
export const formatIndianNumberPopup = (data, field, separators) => {
    data = data.toString();
    if (separators.decimal.includes(field)) {
        return data.replace(",", ".");
    } else if (separators.thousand.includes(field)) {
        return Intl.NumberFormat("en-IN", {
            maximumSignificantDigits: 3,
        }).format(data);
    }
    return data;
};
