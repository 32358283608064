import { createSlice } from "@reduxjs/toolkit";

const { config } = require("../settings");
const configCities = config.cities;

export const cities = createSlice({
  name: "cities",
  initialState: {
    listCities: configCities,
    currentCity: "",
  },
  reducers: {
    updateCurrentCity: (state, props) => {
      const cityName = props.payload;
      state.currentCity = cityName;
    },
  },
});

export const { updateCurrentCity } = cities.actions;

export default cities.reducer;
