import { createSlice } from "@reduxjs/toolkit";

const { config } = require("../settings");
const configYears = config.years;

export const years = createSlice({
  name: "years",
  initialState: {
    listYears: configYears,
    currentYear: "2015",
  },
  reducers: {
    updateCurrentYear: (state, props) => {
      const year = props.payload;
      state.currentYear = year;
    },
  },
});

export const { updateCurrentYear } = years.actions;

export default years.reducer;
