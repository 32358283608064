import { Component } from "react";
import "../styles/MapLegend.css";
import parse from "html-react-parser";
import store from "../store";

class mapLegend extends Component {
    constructor(props) {
        super(props);
        this.state = store.getState().dashboardMobilityIndicators;


        store.subscribe(() => {
            this.setState(store.getState().dashboardMobilityIndicators);
        });
    }

    render() {
        const listLegends = [];
        const currentCity = store.getState().cities.currentCity;
        for (let section of Object.values(this.state)) {
            if (section.treeviews.indicators.checked !== 0) {
                Object.values(section.layers).forEach((layer, index) => {
                    // Check that the layer is checked
                    // Check that the layer is not excluded for this city
                    if (layer.checked === true && !layer.excludeCities.includes(currentCity)) {
                        let layerLegend = layer.legend;
                        let layerLegendKeys = Object.keys(layerLegend);
                        // Check if there is a specific configuration for this city
                        if (layerLegendKeys.includes(currentCity) === true) {
                            layerLegend = layerLegend[currentCity];
                        } else {
                            layerLegend = layerLegend["default"];
                        }

                        if (layerLegend !== "undefined") {
                            let descriptionLegend = "";
                            const layerLegendSymbol = layerLegend.symbol;
                            const layerLegendCustomCssClass = layerLegend.legendCustomCssClass;
                            // map-legend-symbol-border-dark-1px
                            for (const row of layerLegend.class) {
                                let layerLegendStyleSymbol = "";
                                // Variation of the size if the stroke width property is different from 0
                                if (row.strokeWidth !== 0) {
                                    layerLegendStyleSymbol = `background-color: ${row.color}; height: ${row.strokeWidth}px`;
                                }
                                // Point layer have the stroke width property equal to 0
                                else
                                    layerLegendStyleSymbol = `background-color: ${row.color}`;
                                descriptionLegend += `<div class="map-legend-row">
                              <p class="map-legend-symbol map-legend-symbol-${layerLegendSymbol} ${layerLegendCustomCssClass}" style="${layerLegendStyleSymbol}"></p>
                              <p> ${row.label}</p>
                              </div> `;
                            }
                            listLegends.push(
                                <div
                                    key={section.idSection + index}
                                    className="map-legend-container"
                                >
                                    {/* Hide the title if no value */}
                                    {layerLegend.title !== "" ? (
                                        <div className="map-legend-row">
                                            <p className="map-legend-title">
                                                {layerLegend.title}
                                            </p>
                                        </div>
                                    ) : (
                                        ""
                                    )}
                                    {/* Hide the subtitle if no value */}
                                    {layerLegend.subtitle !== "" ? (
                                        <div className="map-legend-row">
                                            <p className="map-legend-subtitle">
                                                {layerLegend.subtitle}
                                            </p>
                                        </div>
                                    ) : (
                                        ""
                                    )}
                                    {parse(descriptionLegend)}
                                </div>
                            );
                        }
                    }
                });
            }
        }

        const visibilityMapLegend =
            store.getState().map.display === true ? "visible" : "hidden";
        return (
            <div
                className="map-legends-container"
                style={{ visibility: visibilityMapLegend }}
            >
                {listLegends}
            </div>
        );
    }
}

export default mapLegend;
