import TreeView from "@mui/lab/TreeView";
import TreeItem from "@mui/lab/TreeItem";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import { useDispatch } from "react-redux";
import {
    reverseCheckedStatusIndicatorLayer,
    updateCheckedStatusLayer,
    updateCheckedStatusTreeview,
    updateExpandStatusTreeviewIndicators,
} from "../stores/DashboardMobilityIndicators";
import { reverseMapDisplay, resetMapFullScreen } from "../stores/Map";
import "../styles/TreeViewCheckbox.css";
import { maxWidth } from "@mui/system";
import store from "../store";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import CenterFocusStrongIcon from "@mui/icons-material/CenterFocusStrong";
import Tooltip from "@mui/material/Tooltip";

export default function TreeViewCheckbox(props) {
    const dispatch = useDispatch();

    const typeTreeView = props.type;
    const typesTreeView = ["indicators", "layers"];
    const checkedTreeView = props.data.treeviews[typeTreeView].checked;
    const titleTreeView = props.data.treeviews.title;
    const idSection = props.data.idSection;
    const storeMobilityIndicators =
        store.getState().dashboardMobilityIndicators;
    const indicators = storeMobilityIndicators[idSection].indicators;
    const layers = storeMobilityIndicators[idSection].layers;
    const currentCity = store.getState().cities.currentCity;

    // Counting checked layers and adjusting the map display
    const countLayersCheckedDisplayMap = () => {
        const storeMapDisplay = store.getState().map.display;
        let arrayCheckedStatusTreeviewsLayers = [];
        for (let section of Object.values(
            store.getState().dashboardMobilityIndicators
        )) {
            // At least one indicator of the theme is checked
            if (section.treeviews.indicators.checked !== 0) {
                arrayCheckedStatusTreeviewsLayers.push(
                    section.treeviews.layers.checked
                );
            }
        }
        // Display of the map according to its status
        // No layer is checked
        if (
            arrayCheckedStatusTreeviewsLayers.every(
                (statusChecked) => statusChecked === 0
            ) === true
        ) {
            if (storeMapDisplay === true) {
                dispatch(reverseMapDisplay());
                dispatch(resetMapFullScreen());
            }
        }
        // At least one layer is checked
        else {
            if (storeMapDisplay === false) {
                dispatch(reverseMapDisplay());
            }
        }
    };

    // Update the status of the checkbox of the treeview layers according to the status of the checkboxes of the layers
    const updateCheckedStatusTreeviewLayer = (focusSection) => {
        let idCheckedStatus = "";
        let arrayCheckedStatusLayer = [];
        for (let value of Object.values(
            store.getState().dashboardMobilityIndicators[focusSection].layers
        )) {
            // Check that the layer is not excluded for this city
            if (!value.excludeCities.includes(currentCity)) {
                arrayCheckedStatusLayer.push(value.checked);
            }
        }
        let checkerStatusLayer = arrayCheckedStatusLayer.every(
            (statusChecked) => statusChecked === true
        );
        if (checkerStatusLayer === true) {
            idCheckedStatus = 2;
        } else {
            checkerStatusLayer = arrayCheckedStatusLayer.every(
                (statusChecked) => statusChecked === false
            );

            if (checkerStatusLayer === true) {
                idCheckedStatus = 0;
            } else {
                idCheckedStatus = 1;
            }
        }
        dispatch(
            updateCheckedStatusTreeview({
                idSection: focusSection,
                idCheckedStatus: idCheckedStatus,
                typeTreeView: "layers",
            })
        );
    };

    // When the user clicks on the checkbox of the treeview, do not trigger the expand/collapse
    // When the users clicks on the treeview, expand/collapse it
    const handleToggle = (event) => {
        if (event.target.type === "checkbox") {
            const currentCheckedTreeView =
                storeMobilityIndicators[idSection].treeviews[typeTreeView]
                    .checked;
            let idCheckedStatus = "";
            // Indicator/layer treeview
            let typeKeys =
                typeTreeView === "indicators"
                    ? storeMobilityIndicators[idSection].indicators
                    : storeMobilityIndicators[idSection].layers;
            // Reverse the status of all indicator and layer checkboxes if the treeview checkbox is checked/unchecked
            if ([0, 2].includes(currentCheckedTreeView)) {
                // The treeviews of the indicators also influence those of the layers
                if (typeTreeView === "indicators") {
                    for (let type of typesTreeView) {
                        for (let [key, value] of Object.entries(
                            storeMobilityIndicators[idSection][type]
                        )) {
                            if (
                                (currentCheckedTreeView === 2 &&
                                    value.checked === true) ||
                                (currentCheckedTreeView === 0 &&
                                    value.checked === false)
                            ) {
                                // No change of the checked status when it concerns a layer checkbox with the initialDisplay property equal to false
                                if (
                                    type === "indicators" ||
                                    (type === "layers" &&
                                        value.initialDisplay === true)
                                ) {
                                    dispatch(
                                        reverseCheckedStatusIndicatorLayer({
                                            idSection: idSection,
                                            idIndicatorLayer: key,
                                            typeTreeView: type,
                                        })
                                    );
                                }
                                // When the treeview of indicators is unchecked
                                // Checked status to false for checkboxes of layers whose status is equal to true and initialDisplay property equal to false
                                else if (
                                    type === "layers" &&
                                    value.initialDisplay === false
                                ) {
                                    if (currentCheckedTreeView === 2) {
                                        dispatch(
                                            updateCheckedStatusLayer({
                                                idSection: idSection,
                                                idLayer: key,
                                                idCheckedStatus: false,
                                            })
                                        );
                                    }
                                }
                            }
                        }
                    }
                }
                // The layers treeviews does not influence the indicators treeviews
                else {
                    for (let key of Object.keys(
                        storeMobilityIndicators[idSection].layers
                    )) {
                        dispatch(
                            reverseCheckedStatusIndicatorLayer({
                                idSection: idSection,
                                idIndicatorLayer: key,
                                typeTreeView: typeTreeView,
                            })
                        );
                    }
                }
                idCheckedStatus = currentCheckedTreeView === 2 ? 0 : 2;
            }
            // Reverse the status of all unchecked indicator and layer checkboxes if the treeview checkbox is indeterminate
            else {
                for (let [key, value] of Object.entries(typeKeys)) {
                    if (value.checked === false) {
                        dispatch(
                            reverseCheckedStatusIndicatorLayer({
                                idSection: idSection,
                                idIndicatorLayer: key,
                                typeTreeView: typeTreeView,
                            })
                        );
                    }
                }
                idCheckedStatus = 2;
            }
            // The treeviews of the indicators also influence those of the layers
            if (typeTreeView === "indicators") {
                for (let type of typesTreeView) {
                    dispatch(
                        updateCheckedStatusTreeview({
                            idSection: idSection,
                            idCheckedStatus: idCheckedStatus,
                            typeTreeView: type,
                        })
                    );
                }
            }
            // The layers treeviews do not influence the indicators treeviews
            else {
                dispatch(
                    updateCheckedStatusTreeview({
                        idSection: idSection,
                        idCheckedStatus: idCheckedStatus,
                        typeTreeView: typeTreeView,
                    })
                );
            }

            // Adjusting the map display
            countLayersCheckedDisplayMap();
        }
        // Expand/Collapse the treeview by updating the expand value in the store
        else {
            const currentExpandTreeView =
                storeMobilityIndicators[idSection].treeviews[typeTreeView]
                    .expand;
            const idExpandStatus =
                currentExpandTreeView === true ? false : true;
            dispatch(
                updateExpandStatusTreeviewIndicators({
                    idSection: idSection,
                    idExpandStatus: idExpandStatus,
                    typeTreeView: typeTreeView,
                })
            );
        }

        // Update the status of the checkbox of the treeview layers according to the status of the checkboxes of the layers
        updateCheckedStatusTreeviewLayer(idSection);
    };

    // Update check status in the store when the user clicks on the checkbox of an indicator/layer
    const handleClick = (event) => {
        const idIndicatorLayer = event.target.id;

        // Update the checked status of the indicator
        dispatch(
            reverseCheckedStatusIndicatorLayer({
                idSection: idSection,
                idIndicatorLayer: idIndicatorLayer,
                typeTreeView: typeTreeView,
            })
        );

        // Retrieve the checked status of each indicator/layer and update the status of the treeview checkbox
        let arrayCheckedStatusIndicatorLayer = [];
        let typeKeys =
            typeTreeView === "indicators"
                ? store.getState().dashboardMobilityIndicators[idSection]
                      .indicators
                : store.getState().dashboardMobilityIndicators[idSection]
                      .layers;
        for (let value of Object.values(typeKeys)) {
            // Check that the layer is not excluded for this city
            if (!(typeTreeView === "layers" && value.excludeCities.includes(currentCity))) {
                arrayCheckedStatusIndicatorLayer.push(value.checked);
            }
        }
        let idCheckedStatus = "";
        let checkerStatusIndicator = arrayCheckedStatusIndicatorLayer.every(
            (statusChecked) => statusChecked === true
        );
        if (checkerStatusIndicator === true) {
            idCheckedStatus = 2;
        } else {
            let checkerStatusIndicator = arrayCheckedStatusIndicatorLayer.every(
                (statusChecked) => statusChecked === false
            );
            if (checkerStatusIndicator === true) {
                idCheckedStatus = 0;
            } else {
                idCheckedStatus = 1;
            }
        }
        dispatch(
            updateCheckedStatusTreeview({
                idSection: idSection,
                idCheckedStatus: idCheckedStatus,
                typeTreeView: typeTreeView,
            })
        );

        // Adjusting the map display
        countLayersCheckedDisplayMap();
    };

    // Focus on a theme
    // Update of the checked status of the treeview & indicators & layers checkboxes
    // Update of the expand status of the treeview
    const handleFocus = () => {
        const focusSection = idSection;
        for (let section of Object.values(
            store.getState().dashboardMobilityIndicators
        )) {
            // Collapse the treeviews of other themes
            if (
                section.idSection !== focusSection &&
                section.treeviews.indicators.expand !== false
            ) {
                dispatch(
                    updateExpandStatusTreeviewIndicators({
                        idSection: section.idSection,
                        idExpandStatus: false,
                        typeTreeView: "indicators",
                    })
                );
            }

            // Uncheck the checkboxes of other themes
            if (
                section.idSection !== focusSection &&
                section.treeviews.indicators.checked !== 0
            ) {
                // Indicators & Layers
                for (let type of typesTreeView) {
                    dispatch(
                        updateCheckedStatusTreeview({
                            idSection: section.idSection,
                            idCheckedStatus: 0,
                            typeTreeView: type,
                        })
                    );
                    for (let [key, value] of Object.entries(section[type])) {
                        if (value.checked === true) {
                            dispatch(
                                reverseCheckedStatusIndicatorLayer({
                                    idSection: section.idSection,
                                    idIndicatorLayer: key,
                                    typeTreeView: type,
                                })
                            );
                        }
                    }
                }
            }
            // Check the expand status and the checkboxes of the theme to focus
            else if (section.idSection === focusSection) {
                for (let type of typesTreeView) {
                    dispatch(
                        updateExpandStatusTreeviewIndicators({
                            idSection: section.idSection,
                            idExpandStatus: true,
                            typeTreeView: type,
                        })
                    );

                    dispatch(
                        updateCheckedStatusTreeview({
                            idSection: focusSection,
                            idCheckedStatus: 2,
                            typeTreeView: type,
                        })
                    );

                    for (let [key, value] of Object.entries(section[type])) {
                        if (value.checked === false) {
                            if (type === "indicators") {
                                dispatch(
                                    reverseCheckedStatusIndicatorLayer({
                                        idSection: focusSection,
                                        idIndicatorLayer: key,
                                        typeTreeView: type,
                                    })
                                );
                            }
                            // Update the status of the checkbox layers
                            // By default, all layers are displayed except those with the initialDisplay = false property
                            else if (
                                type === "layers" &&
                                value.initialDisplay === true
                            ) {
                                dispatch(
                                    reverseCheckedStatusIndicatorLayer({
                                        idSection: focusSection,
                                        idIndicatorLayer: key,
                                        typeTreeView: type,
                                    })
                                );
                            }
                        }
                    }

                    // Update the status of the checkbox of the treeview layers according to the status of the checkboxes of the layers
                    if (type === "layers") {
                        updateCheckedStatusTreeviewLayer(focusSection);
                    }
                }
            }

            // Adjusting the map display
            countLayersCheckedDisplayMap();
        }
    };

    return (
        <div className="treeview-container">
            {/* Focus button on a theme */}
            {typeTreeView === "indicators" ? (
                <Box>
                    <Tooltip title="Focus on this theme" placement="bottom">
                        <IconButton
                            aria-label="focus"
                            onClick={handleFocus}
                            className="button-focus"
                        >
                            <CenterFocusStrongIcon />
                        </IconButton>
                    </Tooltip>
                </Box>
            ) : (
                ""
            )}
            <TreeView
                aria-label="file system navigator"
                defaultCollapseIcon={<ExpandMoreIcon />}
                defaultExpandIcon={<ChevronRightIcon />}
                expanded={
                    store.getState().dashboardMobilityIndicators[idSection]
                        .treeviews[typeTreeView].expand === false
                        ? []
                        : ["0"]
                }
                onNodeToggle={handleToggle}
            >
                <TreeItem
                    nodeId="0"
                    label={
                        <FormControlLabel
                            label={titleTreeView}
                            control={
                                <Checkbox
                                    checked={checkedTreeView === 2}
                                    indeterminate={checkedTreeView === 1}
                                />
                            }
                        />
                    }
                >
                    {/* Treeview indicators or layers (map) */}
                    {/* Filter on treeview layers : ignore the layers excluded for the current city */}
                    {typeTreeView === "indicators"
                        ? Object.keys(indicators).map((keyName, i) => (
                              <FormControlLabel
                                  key={i}
                                  label={indicators[keyName].titleSideBar}
                                  control={
                                      <Checkbox
                                          id={keyName}
                                          checked={indicators[keyName].checked}
                                          onClick={handleClick}
                                          className="indicator-check"
                                          sx={{ width: maxWidth }}
                                      />
                                  }
                              />
                          ))
                        :
                        Object.keys(layers)
                              .filter((layer) => layers[layer].excludeCities.includes(currentCity) === false)
                              .map((keyName, i) => (
                                  <FormControlLabel
                                      key={i}
                                      label={layers[keyName].titleSideBar}
                                      control={
                                          <Checkbox
                                              id={keyName}
                                              checked={layers[keyName].checked}
                                              onClick={handleClick}
                                              className="indicator-check"
                                              sx={{ width: maxWidth }}
                                          />
                                      }
                                  />
                              ))}
                </TreeItem>
            </TreeView>
        </div>
    );
}
