const popupInformation = {
    MobilityIndicators: {
        densityPopulation: {
            speech: `<b>Net population density</b> is the number of people living in a built up area or developed area – i.e residential area. It is different from Gross density which takes into account the whole land area, including urban and non-urban areas.
            \n Net population density is calculated by dividing Population by Built up area and is expressed as people per square kilometre.
            \n It is a good indicator to see if the urban area is sprawling (low density) or if the city is getting more compact (high density). A city that is well connected will consume less land area. Densification and diversification of land use around transit nodes foster the compact city. It helps to reduce travel times and GHG emissions.`,
            source: `Population data census of India (1991, 2001, 2011).
            \n Built up area estimated through analysis of satellite images.`,
        },
        averageNumberTrips: {
            speech: "The <b>Average number of trips per inhabitant</b> is calculated by dividing the total number of trips (for a representative day) by the total population of the study area. Assuming that anyone going out of the house for any purpose (work, study, shopping, etc) will make the round trip within the day, an average of 2.0 trips per person is expected. In some cities, a higher number can be observed (up to 4.0 trips per capita). Sometimes, the ratio is lower than 2.0 trips per person as some parts of the population may not make significant trips every day (children under 6 years old, the elderly, etc).",
            source: "Population data census and Household surveys or traffic counts to estimate daily trips.",
        },
        averageTripLength: {
            speech: "<b>Average trip length for mechanised modes</b> takes into consideration all modes except walk. It is ponderated according to the total number of mechanised trips. The more compact the city, the fewer kilometres are realised on average per trip.",
            source: "Household surveys.",
        },
        modalSplit: {
            speech: "To monitor the modal split is a useful indicator in developing sustainable transport. Public transports and non-motorised modes (cycling and walking) may be considered as more sustainable transport compared to individual motorised transport. Therefore, the indicator has a focus on increasing the share of these modes. The <b>Modal share for Public Transportation</b> usually represents between 10 to 60% of all modes. ",
            source: "The data to derive this indicator are surveys or traffic counts of daily trips made in the city divided into different transport modes.",
        },
        immobilityRate: {
            speech: `<b>Immobility rate</b> is derivated from the average number of trips per inhabitant and is expressed as percentage per day of population staying at home. If the average of 2.0 trips per person per day is reached, then the immobility rate is equal to 0%. Beyond 2.0 trips per person per day, there is no interest in looking at the immobility rate indicator.
            \nIn Indian cities, the Immobility rate usually ranges between 10 to 50%. It is not of big surprise regarding the high percentage of population under 14 years old in India (29% in 2022) and the possible current low mobility of women and elderly people.`,
            source: "Immobility rate is derivated from the average number of trips per inhabitant.",
        },
        kilometersRoads: {
            speech: "To create the <b>Road network extension</b> indicator, the kilometres of road network encompassed within the metropolitan ares are divided by the population in lakh. In Indian cities, it usually ranges between 50 and 250 km / lakh population.",
            source: "Annual statistics from the metropolitan area.",
        },
        kilometersMassTransitSystems: {
            speech: "To create the <b>Mass transit system extension</b> indicator, the kilometres of mass transit network (metro, BRT, tramway) encompassed within the metropolitan ares are divided by the population in lakh. In Indian cities, it usually ranges between 0 and 10 km / lakh population.",
            source: "Annual statistics from the metropolitan area.",
        },
        pedestrianStreets: {
            speech: "To create the <b>Pedestrian streets extension</b> indicator, the kilometres of walkways encompassed within the metropolitan ares are divided by the population in lakh. In Indian cities, it usually ranges between 0 and 50 km / lakh population.",
            source: "Annual statistics from the metropolitan area.",
        },
        bicycleLanes: {
            speech: "To create the <b>Bicycle lanes extension</b> indicator, the kilometres of road network encompassed within the metropolitan ares are divided by the population in lakh. In Indian cities, it usually ranges between 0 and 50 km / lakh population.",
            source: "Annual statistics from the metropolitan area.",
        },
        busSystemSupply: {
            speech: `To evaluate the bus system supply of a city, a first indicator to consider is the <b>Number of bus per lakh population.</b> A city that provides a good bus system with a fleet adapted to the needs of the local population will get higher values for this indicator.
            \nAnother indicator is the <b>Available seat kilometres of the bus transport system</b> for the total metropolitan population. This ratio is calculated with the average number of seats per urban bus, the kilometres travelled every day for the entire fleet and the population in lakh. `,
            source: "Annual statistics from the metropolitan area (Transport Authority or bus operators).",
        },
        iptLicences: {
            speech: "The <b>Number of active IPT vehicles per lakh population</b> is an indicator that considers small capacity vehicles, usually feeders and last mile connectivity modes such as auto-rickshaws or tempos (micro-bus). ",
            source: "Annual statistics from the metropolitan area.",
        },
        carsMotorcycles: {
            speech: "The ratio of the number of registered vehicles, whether two-wheelers or four-wheelers, per thousand population is an interesting indicator to assess the importance of the private vehicle in the society. It usually follows the trends of the Gross Domestic Product (GDP): the wealthier the territory, the more individual vehicles there will be. The <b>Number of four-wheelers per thousand inhabitants</b> is differentiated from the <b>Number of two-wheelers per thousand inhabitants</b> as India is experiencing an exponential increase in the number of two-wheelers since the last decade.",
            source: "Annual statistics from the metropolitan area.",
        },
        trafficFatalities: {
            speech: "<b>Traffic fatalities</b> are a critical indicator of urban mobility. It is calculated with the number of fatalities in the metropolitan area per lakh population. It can range between 2 and 30 persons killed per 100,000 inhabitants.",
            source: "Official statistics.",
        },
        airQuality: {
            speech: `Air pollution levels in most of the urban areas have been a matter of serious concern. The <b>Air quality</b> indicator considers respirable suspended particulates with particle sizes less than 10 microns, or PM10. These particules can get deep into the lungs and cause a broad range of health effects, in particular, respiratory and cardiovascular illnesses.
            \nThe data is based on an air quality index where the weighed values of individual air pollution related parameters (for example, pollutant concentrations) are transformed into a single number. It is measured in µg/m3.`,
            source: "Annual average coming from local measurement stations.",
        },
        greenhouseGasEmissions: {
            speech: "GHG emissions are estimated by taking into account the type of vehicle and its energy consumption (Diesel, Petrol, CNG, Hybrid, Electric), associated with an emission factor per energy type. The emissions per mode are then combined with the total number of passengers per kilometre. The number is then divided by the total population to obtain a ratio of <b>GHG per inhabitant per year.</b> The indicator is expressed in equivalent kgCO2 / year / inhabitant.",
            source: `- Official data on energy consumption and emission factor.
            \n- Average trip length, modal split and occupancy per mode to calculate the number of vehicle km per mode.`,
        },
        householdBudgetTransportation: {
            speech: `Transport costs can represent a significant share of the household budget, especially for low income households. Affordability is a commonly recognized feature of a sustainable transport system. The <b>Household budget dedicated to transportation in low-income families</b> is derived from two elements. The first is data is on the costs of using the different modes of transport (including the cost of public transport tickets and the cost of combustible for private vehicles) ponderated by the average number of trips per mode. And the second is half of the average monthly household income (to capture the lower quartile). The indicator is calculated as the ratio between the two, or a percentage of the household income.
            It can range between 5 and 30% of the household income. When superior to 15%, it is considered as unbearable (households must make choices between transport, food or paying the rent for example).`,
            source: "Official statistics on monthly incomes and estimation of the average fare for each mode.",
        },
        investmentPublicTransport: {
            speech: "The <b>Annual investment budget in Public Transport</b> indicator considers the public spendings on transportation in the last decade (10 years). The indicator is a ratio of this amount per inhabitant and per year. ",
            source: "Public transport companies.",
        },
        publicTransportAnnualBudgetOperationsAdministration: {
            speech: "The <b>Annual budget for operations and administration of Public Transport</b> indicator is the difference between the revenues and the operational costs of the Public Transport system of the metropolitan area, divided by the population.",
            source: "Public transport companies.",
        },
    },
    SumpTrajectory: {
        populationMobility: {
            speech:  `Evolution of the population (number of inhabitants) and mobility (number of trips) in the metropolitan area up to 2050. When the number of trips exceeds the number of inhabitants, the average number of trips per inhabitant is superior to 1.0.
            \nPopulation projection are based on tendential population growth rate. Mobility projection are based on SUMP objectives.`,
            source: "",
        },
        dailyNumberTrips: {
            speech: "Details of the daily number of trips per mode up to 2050. Projections are based on the SMUP modal split objectives. Public transports and non-motorised modes (cycling and walking) shall be favoured.",
            source: "",
        },
        modalShareEvolutionModes: {
            speech: "Evolution of the use of individual motorised modes vs the use of public transportation. Projection based on SUMP objectives to curve the current tendency and favour public transport.",
            source: "",
        },
        motorizationLevel: {
            speech: "Evolution of the numbers of four-wheelers and two-wheelers compared with the evolution of GDP per person. Variation of the GDP based on tendential growth rate. Even if the economic growth slows down in the future, India might still experience a large increase of the fleet of private vehicles.",
            source: "",
        },
        evolutionCostTransportation: {
            speech: `Level of expenditures associated to each mode of transport compared with household budget dedicated to transport. Projections based on assumptions on GDP per inhabitant and average household size for the household budget, and on assumptions of costs per mode of transport (including the cost of public transport tickets and the cost of combustible for private vehicles).
            \nEven though the average monthly expenditure on transport will most likely keep on increasing, the household revenues are expected to evolve faster, lowering then the share of the budget dedicated to transportation.`,
            source: "",
        },
        co2Emissions: {
            speech: "CO2 emissions per mode (expressed in Kg CO2/year) and evolution of the emissions per inhabitant up to 2050. Projection based on SUMP objectives to curve the current tendency and improve the Public Transport sector and the transition towards e-vehicles.",
            source: "",
        },
    },
    SumpManagement: {
        Gantt: {
            speech: "This table presents the progress of the Comprehensive Mobility Plan (CMP) or Sustainable Urban Mobility Plan (SUMP) based on an annual update. Each action is filled with color according to its level of completion.",
            source: "",
        }
    }
};

module.exports = {
    popupInformation,
};
