import { createSlice } from "@reduxjs/toolkit";

export const digitalParitipation = createSlice({
    name: "digitalParitipation",
    initialState: {
        infos_form: {
            questions: [
                {
                    label: "I am",
                    answers: ["A woman", "A man", "Other"],
                },
                {
                    label: "I am (years old)",
                    answers: ["0-14", "15-29", "30-44", "45-59", "60+"],
                },
                {
                    label: "My household is composed of",
                    answers: ["Single person", "2 members", "3 members", "4 members", "5 members", "6 members and +"],
                },
                {
                    label: "My household owns a vehicle",
                    answers: ["No", "1 vehicle", "2 vehicles", "3 vehicles", "4 vehicles", "5 vehicles and +"],
                },
            ]
        },
        transports_used : {
                label: "For my daily trips, I go by",
                answers: [
                    {
                        label: "Walk",
                        mode_form_id : "Walk",
                    },
                    {
                        label: "Bicycle",
                        mode_form_id : "Bicycle",
                    },
                    {
                        label: "Bus",
                        mode_form_id : "Bus",
                    },
                    {
                        label: "Other public transport",
                        mode_form_id : "Others",
                    },
                    {
                        label: "My own vehicle (family vehicle)",
                        mode_form_id : "Others",
                    },
                    {
                        label: "Others",
                        mode_form_id : "Others",
                    },
                ],
                colors: ["#59bcda", "#70ad47", "#548135", "#a23890", "#ffc000"],
                hint: "Select the mode(s) you use for your daily trips.<br/>A short questionnaire will appear according to your choice(s). This survey focuses on 3 modes of transport: Bus, Bicycle and Walk.<br/>For each statement, select your level of agreement.",
                exclusive: false,
        },
        modes_answers : ["Strongly disagree", "Disagree", "Somewhat disagree", "Somewhat agree", "Agree", "Strongly agree"],
        modes_colors_answers : ["#fa7d32", "#ffc000", "#ffe899", "#d3ecb9", "#92d050", "#70ab2e"],
        transports_used_detail : [
            {
                mode: "Public transport",
                mode_id: "Others",
                label: "I use",
                answers: ["Light rail", "Metro", "Train", "Ferry/boat"],
                colors: ["#d50270", "#fd5fb2", "#a23890", "#cf6fc0", "#f1b1e7"],
            },
            {
                mode: "My own vehicle (family vehicle)",
                mode_id: "Others",
                label: "I use",
                answers: ["Car", "Electric car", "Two-wheeler", "Electric two-wheeler"],
                colors: ["#d50270", "#fd5fb2", "#a23890", "#cf6fc0"],
            },
            {
                mode: "Others",
                mode_id: "Others",
                label: "I use",
                answers: ["Taxi", "Auto rickshaw", "My work transport system", "My school transport system"],
                colors: ["#ffc000", "#ffd966", "#2a99bc", "#0070c0"],
            },
        ],
        why : {
            label : "For what purpose ?",
            answers : ["Work", "School/Education", "Others"]
        },
        modes: [
            {
                id: "Bicycle",
                label: "I go by Bicycle",
                color: "#70ad47",
                affirmations : [
                    {
                        label: "Cycling in *** is pleasant",
                        color: "#3f79a3",
                        subAffirmations: [
                            "I can move around quickly and directly",
                            "When cycling, people driving motorised vehicles respect me",
                            "When cycling, I find that motorised traffic (volume and speed) is not annoying",
                            "The use of bicycles is becoming more inclusive (in terms of age, gender, income group)",
                        ],
                    },
                    {
                        label: "It is safe to use a bicycle in ***",
                        color: "#4076a2",
                        subAffirmations: [
                            "I can cycle safely on main roads",
                            "I can cycle safely on residential streets",
                            "It is not dangerous to cross a crossroads or roundabout",
                            "Cycling is safe for children and elderly people",
                        ],
                    },
                    {
                        label: "The efforts made by the municipality to promote cycling are significant",
                        color: "#4274a1",
                        subAffirmations: [
                            "Cycling paths exist",
                            "Cycling paths are under project",
                            "Parking my bicycle at a train or public transport station is easy",
                            "The Public Bicycle Sharing system exists and is affordable",
                            "Communication in favour of cycling is being undertaken by the city",
                        ],
                    },
                ],
            },
            {
                id: "Bus",
                label: "I go by Bus",
                color: "#548135",
                affirmations : [
                    {
                        label: "Using the bus in *** is pleasant",
                        color: "#3f79a3",
                        subAffirmations: [
                            "The vehicle is comfortable and adapted to all type of users (disabled, pregnant, eldery)",
                            "The vehicle is clean",
                            "The ticket price is acceptable",
                            "The waiting time is acceptable",
                            "It is easy to access a bus station, even for disabled, pregnant or eldery persons",
                        ],
                    },
                    {
                        label: "It is safe to take the bus in ***",
                        color: "#4076a2",
                        subAffirmations: [
                            "It is safe to wait at a station, whether by day or night",
                            "It is safe inside the bus, whether by day or night",
                            "The driver/collector can be helpful in case of harassment",
                            "It is safe for women",
                            "There are no road accidents involving buses",
                        ],
                    },
                    {
                        label: "The efforts made by the municipality to develop public transport are significant",
                        color: "#4274a1",
                        subAffirmations: [
                            "All parts of the agglomeration are accessible by bus",
                            "Bus lanes exist to favour public transport in the heavy traffic",
                            "Low-emission vehicles progressively replace the old fleet",
                            "It is easy to transfer from/to another type of transport (private vehicle, rickshaw, taxi, public transport…)",
                            "Fare integration system with other public transport modes exist or is under implementation",
                        ],
                    },
                ],
            },
            {
                id: "Walk",
                label: "I go by Walk",
                color: "#548135",
                affirmations : [
                    {
                        label: "Walking in *** is pleasant",
                        color: "#496a9b",
                        subAffirmations: [
                            "Walkways are large and confortable along main roads",
                            "Walkways are large and confortable in residential areas",
                            "When walking, I find that motorised traffic (volume and speed) is not annoying",
                        ],
                    },
                    {
                        label: "It is safe to walk in ***",
                        color: "#4b6799",
                        subAffirmations: [
                            "I can walk safely along main roads",
                            "I can walk safely along residential streets",
                            "It is not dangerous to cross a crossroads or roundabout",
                            "The street are safe for children and elderly people",
                        ],
                    },
                    {
                        label: "The efforts made by the municipality to promote the walk are significant",
                        color: "#4c6598",
                        subAffirmations: [
                            "Pedestrian areas and parks exist in the city",
                            "New pedestrian areas or walkways are under implementation",
                            "Communication in favour of walking is being under taken by the city",
                        ],
                    },
                ],
            },
            {
                id: "Others",
                label: "I go by Others tranports",
                color: "#ffc000",
                affirmations : [
                    {
                        label: "I do not use the bus because",
                        color: "#535b92",
                        subAffirmations: [
                            "It is unsafe",
                            "It is unreliable",
                            "It is not affordable",
                            "The bus stop is too far",
                            "It is excessive time consuming",
                            "It is uncomfortable (heat, crowd, …)",
                            "It is not disabled friendly",
                        ],
                    },
                    {
                        label: "I do not use the bicycle because",
                        color: "#555891",
                        subAffirmations: [
                            "It is unsafe",
                            "It is unreliable",
                            "It is not affordable",
                            "It is excessive time consuming",
                            "It is uncomfortable (heat, crowd, …)",
                            "It is not disabled friendly",
                        ],
                    },
                    {
                        label: "I do not walk because",
                        color: "#565690",
                        subAffirmations: [
                            "It is unsafe",
                            "It is unreliable",
                            "It is excessive time consuming",
                            "It is uncomfortable (heat, crowd, …)",
                            "It is not disabled friendly",
                        ],
                    },
                ],
            },
        ],
    },
});

export default digitalParitipation.reducer;
