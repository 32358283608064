// api : default to 127.0.0.1:9001
var api_port = process.env.REACT_APP_API_FLASK_SERVER_TEST_PORT || "9001";
var api_host = process.env.REACT_APP_API_FLASK_SERVER_HOST || "localhost";
let api_url = "";
if (api_host === "localhost") {
    api_url = "http://" + api_host + ":" + api_port + "/api/";
} else {
    api_url = "https://" + api_host + "/api/";
}

const config = {
    home: {
        speech: `<p>The Urban Mobility Observatories present a complete analysis of interesting mobility data coming from indian cities. <b>Ahmedabad</b>, <b>Kochi</b> and <b>Nagpur</b> are the three first cities to benefit from this tool.</p>
        <p>A set of mobility indicators has been defined to measure the evolution of the urban mobility characteristics and to follow-up the implementation of the Comprehensive Mobility Plan (CMP) or Sustainable Urban Mobility Plan (SUMP) in each city. This Measure, Report, Verify (MRV) tool focuses on the reduction of transport-related GHG emissions, as per MobiliseYourCity objectives (<a target="_blank" href="https://www.mobiliseyourcity.net/node/318">more here</a>).</p>
        <p>The Urban Mobility Observatories comprise the following sections:</p>
        <ul>
            <li>- <b>MOBILITY INDICATORS</b>, with a set of 20 indicators to understand the past trends</li>
            <li>- <b>SUMP TRAJECTORY</b>, to look at the future trends in terms of mobility and verify that the objectives are met for 2050</li>
            <li>- <b>SUMP MANAGEMENT</b>, to follow-up the implementation of the SUMP or CMP action plan</li>
            <li>- <b>DIGITAL PARTICIPATION</b>, to collect your feedback and perception on the current mobility conditions</li>
        </ul>
        <p>Thank you for visiting.</p>`
    },
    cities: {
        "ahmedabad": {
            center: [72.48, 22.98],
            zoom: 10.4,
        },
        "kochi": {
            center: [76.3, 10],
            zoom: 11,
        },
        "nagpur": {
            center: [79.06, 21.1],
            zoom: 11,
        }
    },
    years: ["1991", "2001", "2011", "2015", "2022"],
    // https://materialui.co/flatuicolors/
    charts: {
        xAxisMinYearMobilityIndicators: 1991,
        xAxisMaxYearMobilityIndicators: 2022,
        xAxisMinYearSumpTrajectory: 1991,
        xAxisMaxYearSumpTrajectory: 2050,
        colorPalettes: {
            0: {
                backgroundColor: ["rgba(101, 69, 132, 0.4)"],
                borderColor: ["rgb(101, 69, 132)"],
                borderWidth: 1,
            },
            // Colors transmitted notably for the modal split chart
            // Other were added for cartographic needs
            1: {
                backgroundColor: [
                    "rgba(255, 200, 0, 0.4)",
                    "rgba(0, 176, 80, 0.4)",
                    "rgba(250, 50, 150, 0.4)",
                    "rgba(63, 184, 216, 0.4)",
                    "rgba(152, 46, 132, 0.4)",
                    "rgba(146, 208, 80, 0.4)",
                    "rgba(189, 0, 38, 0.4)",
                    "rgba(127, 141, 244, 0.4)",
                    "rgba(254, 168, 47, 0.4)"
                ],
                borderColor: [
                    "rgb(255, 200, 0)",
                    "rgb(0, 176, 80)",
                    "rgb(250, 50, 150)",
                    "rgb(63, 184, 216)",
                    "rgb(152, 46, 132)",
                    "rgb(146, 208, 80)",
                    "rgb(189, 0, 38)",
                    "rgb(127, 141, 244)",
                    "rgb(254, 168, 47)",
                ],
                borderWidth: 1,
            },
            2: {
                backgroundColor: [
                    "rgba(26, 188, 156, 0.4)",
                    "rgba(46, 204, 113, 0.4)",
                    "rgba(52, 152, 219, 0.4)",
                    "rgba(241, 196, 15, 0.4)",
                    "rgba(230, 126, 34, 0.4)",
                    "rgba(231, 76, 60, 0.4)",
                    "rgba(155, 89, 182, 0.4)",
                    "rgba(52, 73, 94, 0.4)",
                    "rgba(236, 240, 241, 0.4)",
                    "rgba(149, 165, 166, 0.4)",
                    "rgba(240, 98, 146, 0.4)",
                ],
                borderColor: [
                    "rgb(26, 188, 156)",
                    "rgb(46, 204, 113)",
                    "rgb(52, 152, 219)",
                    "rgb(241, 196, 15)",
                    "rgb(230, 126, 34)",
                    "rgb(231, 76, 60)",
                    "rgb(155, 89, 182)",
                    "rgb(52, 73, 94)",
                    "rgb(236, 240, 241)",
                    "rgb(149, 165, 166)",
                    "rgb(240, 98, 146)",
                ],
                borderWidth: 1,
            },
            3: {
                backgroundColor: [
                    "rgba(22, 160, 133, 0.4)",
                    "rgba(39, 174, 96, 0.4)",
                    "rgba(41, 128, 185, 0.4)",
                    "rgba(243, 156, 18, 0.4)",
                    "rgba(211, 84, 0, 0.4)",
                    "rgba(192, 57, 43, 0.4)",
                    "rgba(142, 68, 173, 0.4)",
                    "rgba(44, 62, 80, 0.4)",
                    "rgba(189, 195, 199, 0.4)",
                    "rgba(127, 140, 141, 0.4)",
                ],
                borderColor: [
                    "rgb(22, 160, 133)",
                    "rgb(39, 174, 96)",
                    "rgb(41, 128, 185)",
                    "rgb(243, 156, 18)",
                    "rgb(211, 84, 0)",
                    "rgb(192, 57, 43)",
                    "rgb(142, 68, 173)",
                    "rgb(44, 62, 80)",
                    "rgb(189, 195, 199)",
                    "rgb(127, 140, 141)",
                ],
                borderWidth: 1,
            },
            4: {
                backgroundColor: [
                    "rgba(26, 188, 156, 0.4)",
                    "rgba(39, 174, 96, 0.4)",
                    "rgba(52, 152, 219, 0.4)",
                    "rgba(243, 156, 18, 0.4)",
                    "rgba(230, 126, 34, 0.4)",
                    "rgba(192, 57, 43, 0.4)",
                    "rgba(142, 68, 173, 0.4)",
                    "rgba(52, 73, 94, 0.4)",
                    "rgba(236, 240, 241, 0.4)",
                    "rgba(127, 140, 141, 0.4)",
                ],
                borderColor: [
                    "rgb(26, 188, 156)",
                    "rgb(39, 174, 96)",
                    "rgb(52, 152, 219)",
                    "rgb(243, 156, 18)",
                    "rgb(230, 126, 34)",
                    "rgb(192, 57, 43)",
                    "rgb(142, 68, 173)",
                    "rgb(52, 73, 94)",
                    "rgb(236, 240, 241)",
                    "rgb(127, 140, 141)",
                ],
                borderWidth: 1,
            },
            5: {
                backgroundColor: [
                    "rgba(230, 126, 34, 0.3)",
                ],
                borderColor: [
                    "rgb(230, 126, 34)",
                ],
                borderWidth: 1,
            },
            /* Same as 1 but with no opacity for the background color */
            6: {
                backgroundColor: [
                    "rgb(255, 200, 0)",
                    "rgb(0, 176, 80)",
                    "rgb(250, 50, 150)",
                    "rgb(63, 184, 216)",
                    "rgb(152, 46, 132)",
                    "rgb(146, 208, 80)",
                    "rgb(189, 0, 38)",
                    "rgb(127, 141, 244)",
                    "rgb(254, 168, 47)",
                ],
                borderColor: [
                    "rgb(255, 200, 0)",
                    "rgb(0, 176, 80)",
                    "rgb(250, 50, 150)",
                    "rgb(63, 184, 216)",
                    "rgb(152, 46, 132)",
                    "rgb(146, 208, 80)",
                    "rgb(189, 0, 38)",
                    "rgb(127, 141, 244)",
                    "rgb(254, 168, 47)",
                ],
                borderWidth: 1,
            },
            /* Same as 0 but with no opacity for the background color */
            7: {
                backgroundColor: ["rgb(101, 69, 132)"],
                borderColor: ["rgb(101, 69, 132)"],
                borderWidth: 1,
            },
            8: {
                backgroundColor: ["rgb(96, 51, 21)"],
                borderColor: ["rgb(96, 51, 21)"],
                borderWidth: 1,
            },
        },
    },
};

const credits = {
    speech: `<h1 class="home-title">Credits - Urban Mobility Observatories</h1>

            <h2>Contents developed by DVDH, CEPT and Transitec</h2>
            <p>
            The Urban Mobility Observatories tool is supported by Ministry of Housing and Urban Affairs (MoHUA) and the MobiliseYourCity (MYC) program in India. Activities are funded by a grant made available by the European Union (EU) & operated by Agence Française de Développement (AFD).
            Data was collected and modelised by the consortium DVDH, CEPT and Transitec in 2022.
            </p>

            <h2>Layout and development by Oslandia</h2>
            <p>
            Contents management system: ReactJS / Python Flask / OpenLayers / ChartJS / Postgres / Postgis
            </p>

            <h2>For more information</h2>
            <p>
            <i>Des Villes et Des Hommes</i>. Consulting firm in sustainable urban mobility.<br/>
            87 Quai des Queyries, 33100 Bordeaux, France | <a href="https://dvdh.fr/">https://dvdh.fr/</a>
            </p>
            <p>
            <i>Center of Excellence in Urban Transport</i>. CRDF, CEPT University.<br/>
            Ahmedabad 380009, India | <a href="https://crdf.org.in/">https://crdf.org.in/</a>
            </p>
            <p>
            <i>Transitec</i>. Optimized mobility.<br/>
            28, rue Sainte Foy, 75002 Paris, France | <a href="https://transitec.net/">https://transitec.net/</a>
            </p>
            <p>
            <i>Oslandia</i>. Geographical Information Systems.<br/>
            4 Place Louis Armand, 75012 Paris, France | <a href="https://oslandia.com/">https://oslandia.com/</a>
            </p>
    `
}

module.exports = {
    api_url,
    config,
    credits,
};
