import Box from "@mui/material/Box";
import Checkbox from "@mui/material/Checkbox";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import MapIcon from "@mui/icons-material/Map";
import BarChartIcon from "@mui/icons-material/BarChart";
import "../../styles/SideBar.css";
import TreeViewCheckbox from "../TreeViewCheckbox";
import store from "../../store";
import { useDispatch } from "react-redux";
import { reverseMapDisplay } from "../../stores/Map";
import { updateCurrentYear } from "../../stores/Years";
import { useState } from "react";
import { useSelector } from "react-redux";

const drawerWidth = 280;

export default function SideBarMobilityIndicators() {
    const dispatch = useDispatch();
    const handleClickMap = () => {
        dispatch(reverseMapDisplay());
    };

    let storeMobilityIndicators = useSelector(
        (_state) => store.getState().dashboardMobilityIndicators
    );
    let storeMapDisplay = useSelector((_state) => store.getState().map.display);

    // Hide view the map if no layer is checked
    let arrayCheckedStatusTreeviewsLayers = [];
    for (let section of Object.values(
        store.getState().dashboardMobilityIndicators
    )) {
        // At least one indicator of the theme is checked
        if (section.treeviews.indicators.checked !== 0) {
            arrayCheckedStatusTreeviewsLayers.push(
                section.treeviews.layers.checked
            );
        }
    }

    const listYears = store.getState().years.listYears;
    const [currentYear, setCurrentYear] = useState(
        store.getState().years.currentYear
    );

    // Update of the current year in the store and in the Select
    const handleYearChange = (event) => {
        let year = event.target.value;
        setCurrentYear(year);
        dispatch(updateCurrentYear(year));
    };

    return (
        <Box sx={{ display: "flex" }}>
            <Drawer
                className="SideBar"
                sx={{
                    width: drawerWidth,
                    flexShrink: 0,
                    "& .MuiDrawer-paper": {
                        width: drawerWidth,
                        boxSizing: "border-box",
                        height: "calc(100% - 68px)",
                        top: "68px",
                        overflowX: "hidden",
                    },
                }}
                variant="permanent"
                anchor="left"
            >
                <p className="sidebar-title-section">
                    <BarChartIcon></BarChartIcon>Indicators
                </p>
                {/* One treeview for each theme */}
                <TreeViewCheckbox
                    data={storeMobilityIndicators.urbanStructure}
                    type="indicators"
                />
                <TreeViewCheckbox
                    data={storeMobilityIndicators.mobilityDemand}
                    type="indicators"
                />
                <TreeViewCheckbox
                    data={storeMobilityIndicators.transportSupply}
                    type="indicators"
                />
                <TreeViewCheckbox
                    data={storeMobilityIndicators.motorisation}
                    type="indicators"
                />
                <TreeViewCheckbox
                    data={storeMobilityIndicators.externalities}
                    type="indicators"
                />
                <TreeViewCheckbox
                    data={storeMobilityIndicators.economicsMobility}
                    type="indicators"
                />
                <Box id="box-select-year-indicator">
                    <p id="label-select-year-indicator">Year</p>
                    <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                        <Select
                            id="sidebar-select-year-indicator"
                            defaultValue={currentYear}
                            value={currentYear}
                            onChange={handleYearChange}
                        >
                            {listYears.map((year, i) => {
                                return (
                                    <MenuItem value={year} key={i}>
                                        {year}
                                    </MenuItem>
                                );
                            })}
                        </Select>
                    </FormControl>
                </Box>
                <Divider className="sidebar-divider" />
                <p className="sidebar-title-section">
                    <MapIcon></MapIcon>Map
                </p>
                {/*  Hide view the map if no layer is checked */}
                {arrayCheckedStatusTreeviewsLayers.every(
                    (statusChecked) => statusChecked === 0
                ) === false ? (
                    <div>
                        <FormControlLabel
                            className="sidebar-checkbox"
                            checked={
                                storeMapDisplay === true
                                    ? true
                                    : false
                            }
                            control={<Checkbox />}
                            label="View the map"
                            onClick={handleClickMap}
                        />
                        <div className="sidebar-city-boundary-container">
                            <p className="sidebar-city-boundary-symbol"></p>
                            <p>Metropolitan area</p>
                        </div>
                        <Divider
                            className="sidebar-divider"
                            sx={{ marginBottom: 1 }}
                        />
                    </div>
                ) : (
                    ""
                )}
                {/* One treeview for each theme if at least 1 indicator is checked*/}
                {storeMobilityIndicators.urbanStructure.treeviews.indicators
                    .checked !== 0 ? (
                    <TreeViewCheckbox
                        data={storeMobilityIndicators.urbanStructure}
                        type="layers"
                    />
                ) : (
                    ""
                )}
                {storeMobilityIndicators.mobilityDemand.treeviews.indicators
                    .checked !== 0 ? (
                    <TreeViewCheckbox
                        data={storeMobilityIndicators.mobilityDemand}
                        type="layers"
                    />
                ) : (
                    ""
                )}
                {storeMobilityIndicators.transportSupply.treeviews.indicators
                    .checked !== 0 ? (
                    <TreeViewCheckbox
                        data={storeMobilityIndicators.transportSupply}
                        type="layers"
                    />
                ) : (
                    ""
                )}
                {storeMobilityIndicators.motorisation.treeviews.indicators
                    .checked !== 0 ? (
                    <TreeViewCheckbox
                        data={storeMobilityIndicators.motorisation}
                        type="layers"
                    />
                ) : (
                    ""
                )}
                {storeMobilityIndicators.externalities.treeviews.indicators
                    .checked !== 0 ? (
                    <TreeViewCheckbox
                        data={storeMobilityIndicators.externalities}
                        type="layers"
                    />
                ) : (
                    ""
                )}
                {storeMobilityIndicators.economicsMobility.treeviews.indicators
                    .checked !== 0 ? (
                    <TreeViewCheckbox
                        data={storeMobilityIndicators.economicsMobility}
                        type="layers"
                    />
                ) : (
                    ""
                )}
            </Drawer>
        </Box>
    );
}
