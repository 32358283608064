import { useNavigate } from "react-router-dom";
import "../styles/Home.css";
import store from "../store";
import TextField from "@mui/material/TextField";
import { useState } from "react";
import logo from '../img/logo.png'
import img_speech from '../img/bg_speech.png'
import { Grid } from "@mui/material";

const { config } = require("../settings");

export default function Home() {
  const navigate = useNavigate();

  const changeRoute = (event) => {
    let cityName = event.target.name;
    let cityNameLowerCase = cityName.toLowerCase();
    navigate("/sump/mobility-indicators/" + cityNameLowerCase);
  };

  const listCities = Object.keys(store.getState().cities.listCities);
  const [filteredCities, changeFilteredCities] = useState(listCities);

  // Filter the cities according to the search
  const handleCityChange = (e) => {
    let cityName = e.target.value.toLowerCase();
    const resultFilteredCities = listCities.filter((word) =>
      word.includes(cityName)
    );
    changeFilteredCities(resultFilteredCities);
  };

  return (
    <div className="home-container">
        <div className="home-content">
            <div className="logo">
                <img src={logo} alt='logo' />
            </div>
            <p className="home-title">Urban Mobility Observatories</p>

            <Grid container spacing={2}>
              <Grid item xs={4}>
                <img className="img-speech" src={img_speech} alt="img_speech" />
              </Grid>
              <Grid item xs={8}>
                <div className="home-speech" dangerouslySetInnerHTML={{ __html: config.home.speech }}></div>
              </Grid>
            </Grid>

            <TextField
                id="home-search"
                placeholder="Search for a city (e.g. Kochi)"
                variant="outlined"
                onChange={handleCityChange}
            />
            <div className="home-cities-container">
                {filteredCities.length === 0 ? (
                    <p>No cities found</p>
                ) : (
                filteredCities.map((cityName, i) => (
                    <div key={i}>
                    <button
                        className="home-city-button"
                        style={{
                        backgroundImage: `url(${require(`../img/cities/${cityName}.jpg`)})`,
                        }}
                        onClick={changeRoute}
                        name={cityName}
                    >
                        <p className="home-city-title">
                            {cityName.replace(/\w/, (firstLetter) =>
                            firstLetter.toUpperCase()
                            )}
                        </p>
                    </button>
                    </div>
                ))
            )}
            </div>
        </div>
    </div>
  );
}
