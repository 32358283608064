import { createSlice } from "@reduxjs/toolkit";

const { api_url, config } = require("../settings");
const { popupInformation } = require("../settings-popup-information");
const part = "SumpTrajectory";
const configChartsColorPalettes = config.charts.colorPalettes;
const yRightTitleColor = configChartsColorPalettes["2"].borderColor["4"];
const legendLabelsBoxHeightLine = 3; // Line, Area
const legendLabelsBoxHeightOther = 12; // Bar, Pie

export const dashboardSumpTrajectory = createSlice({
    name: "dashboardSumpTrajectory",
    initialState: {
        indicators: {
            populationMobility: {
                checked: "true",
                idIndicator: "populationMobility",
                titleIndicator: "Population and mobility",
                popupInformation: {
                    status: "false",
                    text: popupInformation[part]["populationMobility"],
                },
                keyFigure: {
                    label: "",
                    url: "",
                    separator: "",
                },
                charts: [
                    {
                        typeChart: "line",
                        multiYear: true,
                        titleChart: "",
                        titleDisplay: "true",
                        legendDisplay: "true",
                        legendCustom: "false",
                        legendLabelsBoxHeight: legendLabelsBoxHeightLine,
                        maintainAspectRatio: false,
                        heightChart: "300",
                        widthChart: "400",
                        scales: {
                            x: {
                                display: true,
                                stacked: false,
                                titleDisplay: false,
                                titleText: "",
                                gridDisplay: false,
                                beginAtZero: false,
                            },
                            y: {
                                display: true,
                                stacked: false,
                                titleDisplay: false,
                                titleText: "",
                                gridDisplay: true,
                                beginAtZero: false,
                                max: null,
                            },
                            yRight: {
                                display: false,
                                stacked: false,
                                titleDisplay: false,
                                titleText: "",
                                titleColor: yRightTitleColor,
                                gridDisplay: false,
                                beginAtZero: true,
                            },
                        },
                        keyFigure: {
                            label: "",
                            url: "",
                            separator: "",
                            position: "",
                        },
                        datasets: [
                            {
                                type: "line",
                                label: "Population of metropolitan area",
                                data: [],
                                url:
                                    api_url +
                                    "charts/data/?part=sump-trajectory&indicator=population_mobility&trend=projection&chart=population&schema_city=",
                                idColorPalette: configChartsColorPalettes["0"],
                                idColor: "0",
                                borderWidth: 1,
                                pointStyle: "circle",
                                pointRadius: 3,
                                spanGaps: true,
                                legendCustomCssClass: "",
                                fill: false,
                                yAxisID: "y",
                                order: 2,
                                tooltipUnit: "",
                            },
                            {
                                type: "line",
                                label: "Total number of trips (Projection)",
                                data: [],
                                url:
                                    api_url +
                                    "charts/data/?part=sump-trajectory&indicator=population_mobility&trend=projection&chart=total_number_trips&schema_city=",
                                idColorPalette: configChartsColorPalettes["2"],
                                idColor: "4",
                                borderWidth: 1,
                                pointStyle: "circle",
                                pointRadius: 3,
                                spanGaps: true,
                                legendCustomCssClass: "",
                                fill: false,
                                yAxisID: "y",
                                order: 2,
                                tooltipUnit: "",
                            },
                            {
                                type: "line",
                                label: "Total number of daily trips (observed after 2022)",
                                data: [],
                                url:
                                    api_url +
                                    "charts/data/?part=sump-trajectory&indicator=population_mobility&trend=reality&chart=total_number_trips&schema_city=",
                                idColorPalette: configChartsColorPalettes["5"],
                                idColor: "0",
                                borderWidth: 1,
                                pointStyle: "circle",
                                pointRadius: 3,
                                spanGaps: true,
                                legendCustomCssClass: "",
                                fill: false,
                                yAxisID: "y",
                                order: 2,
                                tooltipUnit: "",
                            },
                        ],
                    },
                ],
            },
            dailyNumberTrips: {
                checked: "true",
                idIndicator: "dailyNumberTrips",
                titleIndicator: "Daily number of trips per mode",
                popupInformation: {
                    status: "false",
                    text: popupInformation[part]["dailyNumberTrips"],
                },
                keyFigure: {
                    label: "",
                    url: "",
                    separator: "",
                },
                charts: [
                    {
                        typeChart: "line",
                        multiYear: true,
                        titleChart: "",
                        titleDisplay: "true",
                        legendDisplay: "true",
                        legendCustom: "false",
                        legendLabelsBoxHeight: legendLabelsBoxHeightOther,
                        maintainAspectRatio: false,
                        heightChart: "300",
                        widthChart: "400",
                        scales: {
                            x: {
                                display: true,
                                stacked: false,
                                titleDisplay: false,
                                titleText: "",
                                gridDisplay: false,
                                beginAtZero: false,
                            },
                            y: {
                                display: true,
                                stacked: true,
                                titleDisplay: true,
                                titleText: "Number of daily trips",
                                gridDisplay: true,
                                beginAtZero: false,
                                max: null,
                            },
                            yRight: {
                                display: false,
                                stacked: false,
                                titleDisplay: false,
                                titleText: "",
                                titleColor: yRightTitleColor,
                                gridDisplay: false,
                                beginAtZero: true,
                            },
                        },
                        keyFigure: {
                            label: "",
                            url: "",
                            separator: "",
                            position: "",
                        },
                        datasets: [
                            {
                                type: "line",
                                label: "Walk",
                                data: [],
                                url:
                                    api_url +
                                    "charts/data/?part=sump-trajectory&indicator=daily_number_trips&trend=projection&chart=daily_number_trips&chart_filter=Number of trips per mode - Walk&schema_city=",
                                idColorPalette: configChartsColorPalettes["6"],
                                idColor: "5",
                                borderWidth: 1,
                                pointStyle: "circle",
                                pointRadius: 3,
                                spanGaps: true,
                                legendCustomCssClass: "",
                                fill: true,
                                yAxisID: "y",
                                order: 2,
                                tooltipUnit: "",
                            },
                            {
                                type: "line",
                                label: "Bicycle",
                                data: [],
                                url:
                                    api_url +
                                    "charts/data/?part=sump-trajectory&indicator=daily_number_trips&trend=projection&chart=daily_number_trips&chart_filter=Number of trips per mode - Bicycle&schema_city=",
                                idColorPalette: configChartsColorPalettes["6"],
                                idColor: "1",
                                borderWidth: 1,
                                pointStyle: "circle",
                                pointRadius: 3,
                                spanGaps: true,
                                legendCustomCssClass: "",
                                fill: true,
                                yAxisID: "y",
                                order: 2,
                                tooltipUnit: "",
                            },
                            {
                                type: "line",
                                label: "Two Wheeler",
                                data: [],
                                url:
                                    api_url +
                                    "charts/data/?part=sump-trajectory&indicator=daily_number_trips&trend=projection&chart=daily_number_trips&chart_filter=Number of trips per mode - Two-wheeler&schema_city=",
                                idColorPalette: configChartsColorPalettes["6"],
                                idColor: "4",
                                borderWidth: 1,
                                pointStyle: "circle",
                                pointRadius: 3,
                                spanGaps: true,
                                legendCustomCssClass: "",
                                fill: true,
                                yAxisID: "y",
                                order: 2,
                                tooltipUnit: "",
                            },
                            {
                                type: "line",
                                label: "Car",
                                data: [],
                                url:
                                    api_url +
                                    "charts/data/?part=sump-trajectory&indicator=daily_number_trips&trend=projection&chart=daily_number_trips&chart_filter=Number of trips per mode - Car&schema_city=",
                                idColorPalette: configChartsColorPalettes["6"],
                                idColor: "2",
                                borderWidth: 1,
                                pointStyle: "circle",
                                pointRadius: 3,
                                spanGaps: true,
                                legendCustomCssClass: "",
                                fill: true,
                                yAxisID: "y",
                                order: 2,
                                tooltipUnit: "",
                            },
                            {
                                type: "line",
                                label: "Auto Rickshaw",
                                data: [],
                                url:
                                    api_url +
                                    "charts/data/?part=sump-trajectory&indicator=daily_number_trips&trend=projection&chart=daily_number_trips&chart_filter=Number of trips per mode - Three-wheeler&schema_city=",
                                idColorPalette: configChartsColorPalettes["6"],
                                idColor: "0",
                                borderWidth: 1,
                                pointStyle: "circle",
                                pointRadius: 3,
                                spanGaps: true,
                                legendCustomCssClass: "",
                                fill: true,
                                yAxisID: "y",
                                order: 2,
                                tooltipUnit: "",
                            },
                            {
                                type: "line",
                                label: "Bus",
                                data: [],
                                url:
                                    api_url +
                                    "charts/data/?part=sump-trajectory&indicator=daily_number_trips&trend=projection&chart=daily_number_trips&chart_filter=Number of trips per mode - Bus&schema_city=",
                                idColorPalette: configChartsColorPalettes["6"],
                                idColor: "3",
                                borderWidth: 1,
                                pointStyle: "circle",
                                pointRadius: 3,
                                spanGaps: true,
                                legendCustomCssClass: "",
                                fill: true,
                                yAxisID: "y",
                                order: 2,
                                tooltipUnit: "",
                            },
                            {
                                type: "line",
                                label: "Ferry/Water metro",
                                data: [],
                                url:
                                    api_url +
                                    "charts/data/?part=sump-trajectory&indicator=daily_number_trips&trend=projection&chart=daily_number_trips&chart_filter=Number of trips per mode - Ferry/Water metro&schema_city=",
                                idColorPalette: configChartsColorPalettes["6"],
                                idColor: "7",
                                borderWidth: 1,
                                pointStyle: "circle",
                                pointRadius: 3,
                                spanGaps: true,
                                legendCustomCssClass: "",
                                fill: true,
                                yAxisID: "y",
                                order: 2,
                                tooltipUnit: "",
                            },
                            {
                                type: "line",
                                label: "Metro",
                                data: [],
                                url:
                                    api_url +
                                    "charts/data/?part=sump-trajectory&indicator=daily_number_trips&trend=projection&chart=daily_number_trips&chart_filter=Number of trips per mode - Metro&schema_city=",
                                idColorPalette: configChartsColorPalettes["6"],
                                idColor: "2",
                                borderWidth: 1,
                                pointStyle: "circle",
                                pointRadius: 3,
                                spanGaps: true,
                                legendCustomCssClass: "",
                                fill: true,
                                yAxisID: "y",
                                order: 2,
                                tooltipUnit: "",
                            },
                            {
                                type: "line",
                                label: "Train",
                                data: [],
                                url:
                                    api_url +
                                    "charts/data/?part=sump-trajectory&indicator=daily_number_trips&trend=projection&chart=daily_number_trips&chart_filter=Number of trips per mode - Train&schema_city=",
                                idColorPalette: configChartsColorPalettes["7"],
                                idColor: "0",
                                borderWidth: 1,
                                pointStyle: "circle",
                                pointRadius: 3,
                                spanGaps: true,
                                legendCustomCssClass: "",
                                fill: true,
                                yAxisID: "y",
                                order: 2,
                                tooltipUnit: "",
                            },
                        ],
                    },
                ],
            },
            modalShareEvolutionModes: {
                checked: "true",
                idIndicator: "modalShareEvolutionModes",
                titleIndicator:
                    "Modal share evolution between private modes and public modes",
                popupInformation: {
                    status: "false",
                    text: popupInformation[part]["modalShareEvolutionModes"],
                },
                keyFigure: {
                    label: "",
                    url: "",
                    separator: "",
                },
                charts: [
                    {
                        typeChart: "line",
                        multiYear: true,
                        titleChart: "",
                        titleDisplay: "true",
                        legendDisplay: "true",
                        legendCustom: "false",
                        legendLabelsBoxHeight: legendLabelsBoxHeightLine,
                        maintainAspectRatio: false,
                        heightChart: "280",
                        widthChart: "400",
                        scales: {
                            x: {
                                display: true,
                                stacked: false,
                                titleDisplay: false,
                                titleText: "",
                                gridDisplay: false,
                                beginAtZero: false,
                            },
                            y: {
                                display: true,
                                stacked: false,
                                titleDisplay: true,
                                titleText: "Evolution (%)",
                                gridDisplay: true,
                                beginAtZero: false,
                                max: null,
                            },
                            yRight: {
                                display: false,
                                stacked: false,
                                titleDisplay: false,
                                titleText: "",
                                titleColor: yRightTitleColor,
                                gridDisplay: false,
                                beginAtZero: true,
                            },
                        },
                        keyFigure: {
                            label: "",
                            url: "",
                            separator: "",
                            position: "",
                        },
                        datasets: [
                            {
                                type: "line",
                                label: "Public modes",
                                data: [],
                                url:
                                    api_url +
                                    "charts/data/?part=sump-trajectory&indicator=modal_share_evolution_modes&trend=projection&chart=public_modes&schema_city=",
                                idColorPalette: configChartsColorPalettes["2"],
                                idColor: "2",
                                borderWidth: 1,
                                pointStyle: "circle",
                                pointRadius: 3,
                                spanGaps: true,
                                legendCustomCssClass: "",
                                fill: false,
                                yAxisID: "y",
                                order: 2,
                                tooltipUnit: "",
                            },
                            {
                                type: "line",
                                label: "Private modes",
                                data: [],
                                url:
                                    api_url +
                                    "charts/data/?part=sump-trajectory&indicator=modal_share_evolution_modes&trend=projection&chart=private_modes&schema_city=",
                                idColorPalette: configChartsColorPalettes["0"],
                                idColor: "0",
                                borderWidth: 1,
                                pointStyle: "circle",
                                pointRadius: 3,
                                spanGaps: true,
                                legendCustomCssClass: "",
                                fill: false,
                                yAxisID: "y",
                                order: 2,
                                tooltipUnit: "",
                            },
                        ],
                    },
                ],
            },
            motorizationLevel: {
                checked: "true",
                idIndicator: "motorizationLevel",
                titleIndicator: "Motorization level",
                popupInformation: {
                    status: "false",
                    text: popupInformation[part]["motorizationLevel"],
                },
                keyFigure: {
                    label: "",
                    url: "",
                    separator: "",
                },
                charts: [
                    {
                        typeChart: "line",
                        multiYear: true,
                        titleChart: "",
                        titleDisplay: "true",
                        legendDisplay: "true",
                        legendCustom: "false",
                        legendLabelsBoxHeight: legendLabelsBoxHeightLine,
                        maintainAspectRatio: false,
                        heightChart: "400",
                        widthChart: "400",
                        scales: {
                            x: {
                                display: true,
                                stacked: false,
                                titleDisplay: false,
                                titleText: "",
                                gridDisplay: false,
                                beginAtZero: false,
                            },
                            y: {
                                display: true,
                                stacked: false,
                                titleDisplay: true,
                                titleText: "Number of vehicles",
                                gridDisplay: true,
                                beginAtZero: false,
                                max: null,
                            },
                            yRight: {
                                display: true,
                                stacked: false,
                                titleDisplay: true,
                                titleText: "GDP per inhabitant PPA ($ 2011)",
                                titleColor: yRightTitleColor,
                                gridDisplay: false,
                                beginAtZero: true,
                            },
                        },
                        keyFigure: {
                            label: "",
                            url: "",
                            separator: "",
                            position: "",
                        },
                        datasets: [
                            {
                                type: "line",
                                label: "Number of 4-wheelers",
                                data: [],
                                url:
                                    api_url +
                                    "charts/data/?part=sump-trajectory&indicator=motorization_level&trend=projection&chart=number_cars&schema_city=",
                                idColorPalette: configChartsColorPalettes["1"],
                                idColor: "2",
                                borderWidth: 1,
                                pointStyle: "circle",
                                pointRadius: 3,
                                spanGaps: true,
                                legendCustomCssClass: "",
                                fill: false,
                                yAxisID: "y",
                                order: 2,
                                tooltipUnit: "",
                            },
                            {
                                type: "line",
                                label: "Number of 2-wheelers",
                                data: [],
                                url:
                                    api_url +
                                    "charts/data/?part=sump-trajectory&indicator=motorization_level&trend=projection&chart=number_2w&schema_city=",
                                idColorPalette: configChartsColorPalettes["0"],
                                idColor: "0",
                                borderWidth: 1,
                                pointStyle: "circle",
                                pointRadius: 3,
                                spanGaps: true,
                                legendCustomCssClass: "",
                                fill: false,
                                yAxisID: "y",
                                order: 2,
                                tooltipUnit: "",
                            },
                            {
                                type: "line",
                                label: "GDP per inhabitant PPA ($2011)",
                                data: [],
                                url:
                                    api_url +
                                    "charts/data/?part=sump-trajectory&indicator=motorization_level&trend=projection&chart=gdp_inhabitant_ppa&schema_city=",
                                idColorPalette: configChartsColorPalettes["2"],
                                idColor: "4",
                                borderWidth: 1,
                                pointStyle: "circle",
                                pointRadius: 3,
                                spanGaps: true,
                                legendCustomCssClass: "",
                                fill: false,
                                yAxisID: "yRight",
                                order: 2,
                                tooltipUnit: "",
                            },
                        ],
                    },
                ],
            },
            evolutionCostTransportation: {
                checked: "true",
                idIndicator: "evolutionCostTransportation",
                titleIndicator:
                    "Evolution of the cost of transportation per mode",
                popupInformation: {
                    status: "false",
                    text: popupInformation[part]["evolutionCostTransportation"],
                },
                keyFigure: {
                    label: "",
                    url: "",
                    separator: "",
                },
                charts: [
                    {
                        typeChart: "line",
                        multiYear: true,
                        titleChart: "",
                        titleDisplay: "true",
                        legendDisplay: "true",
                        legendCustom: "true",
                        legendLabelsBoxHeight: legendLabelsBoxHeightLine,
                        maintainAspectRatio: false,
                        heightChart: "300",
                        widthChart: "400",
                        scales: {
                            x: {
                                display: true,
                                stacked: false,
                                titleDisplay: false,
                                titleText: "",
                                gridDisplay: false,
                                beginAtZero: false,
                            },
                            y: {
                                display: true,
                                stacked: true,
                                titleDisplay: true,
                                titleText: "Monthly expenditure on transport (Rs/month)",
                                gridDisplay: true,
                                beginAtZero: false,
                                max: null,
                            },
                            yRight: {
                                display: true,
                                stacked: false,
                                titleDisplay: true,
                                titleText:
                                    "Household budget dedicated to transportation (%)",
                                    titleColor: yRightTitleColor,
                                gridDisplay: false,
                                beginAtZero: true,
                            },
                        },
                        keyFigure: {
                            label: "",
                            url: "",
                            separator: "",
                            position: "",
                        },
                        datasets: [
                            {
                                type: "line",
                                label: "Two Wheeler (inc. depreciation)",
                                data: [],
                                url:
                                    api_url +
                                    "charts/data/?part=sump-trajectory&indicator=evolution_cost_transportation&trend=projection&chart=evolution_cost_transportation&chart_filter=Household budget for Two Wheeler (inc. depreciation)&schema_city=",
                                idColorPalette: configChartsColorPalettes["1"],
                                idColor: "4",
                                borderWidth: 1,
                                pointStyle: "circle",
                                pointpointRadius: 3,
                                spanGaps: true,
                                legendCustomCssClass: "chart-legend-custom-rectangle chart-legend-custom-color-156-46-132-fuchsia",
                                fill: true,
                                yAxisID: "y",
                                order: 2,
                                tooltipUnit: "",
                            },
                            {
                                type: "line",
                                label: "Car (thermal inc. depreciation)",
                                data: [],
                                url:
                                    api_url +
                                    "charts/data/?part=sump-trajectory&indicator=evolution_cost_transportation&trend=projection&chart=evolution_cost_transportation&chart_filter=Household budget for Car (thermal inc. depreciation)&schema_city=",
                                idColorPalette: configChartsColorPalettes["1"],
                                idColor: "2",
                                borderWidth: 1,
                                pointStyle: "circle",
                                pointRadius: 3,
                                spanGaps: true,
                                legendCustomCssClass: "chart-legend-custom-rectangle chart-legend-custom-color-250-50-150-pink",
                                fill: true,
                                yAxisID: "y",
                                order: 2,
                                tooltipUnit: "",
                            },
                            {
                                type: "line",
                                label: "Car (electrical inc. depreciation)",
                                data: [],
                                url:
                                    api_url +
                                    "charts/data/?part=sump-trajectory&indicator=evolution_cost_transportation&trend=projection&chart=evolution_cost_transportation&chart_filter=Household budget for Car (electrical inc. depreciation)&schema_city=",
                                idColorPalette: configChartsColorPalettes["1"],
                                idColor: "6",
                                borderWidth: 1,
                                pointStyle: "circle",
                                pointRadius: 3,
                                spanGaps: true,
                                legendCustomCssClass: "chart-legend-custom-rectangle chart-legend-custom-color-189-0-38-red",
                                fill: true,
                                yAxisID: "y",
                                order: 2,
                                tooltipUnit: "",
                            },
                            {
                                type: "line",
                                label: "Auto Rickshaw",
                                data: [],
                                url:
                                    api_url +
                                    "charts/data/?part=sump-trajectory&indicator=evolution_cost_transportation&trend=projection&chart=evolution_cost_transportation&chart_filter=Household budget for Three-wheeler&schema_city=",
                                idColorPalette: configChartsColorPalettes["1"],
                                idColor: "0",
                                borderWidth: 1,
                                pointStyle: "circle",
                                pointRadius: 3,
                                spanGaps: true,
                                legendCustomCssClass: "chart-legend-custom-rectangle chart-legend-custom-color-255-200-0-yellow",
                                fill: true,
                                yAxisID: "y",
                                order: 2,
                                tooltipUnit: "",
                            },
                            {
                                type: "line",
                                label: "Bus",
                                data: [],
                                url:
                                    api_url +
                                    "charts/data/?part=sump-trajectory&indicator=evolution_cost_transportation&trend=projection&chart=evolution_cost_transportation&chart_filter=Household budget for Bus&schema_city=",
                                idColorPalette: configChartsColorPalettes["1"],
                                idColor: "3",
                                borderWidth: 1,
                                pointStyle: "circle",
                                pointRadius: 3,
                                spanGaps: true,
                                legendCustomCssClass: "chart-legend-custom-rectangle chart-legend-custom-color-63-184-216-light-blue",
                                fill: true,
                                yAxisID: "y",
                                order: 2,
                                tooltipUnit: "",
                            },
                            {
                                type: "line",
                                label: "Ferry/Water metro",
                                data: [],
                                url:
                                    api_url +
                                    "charts/data/?part=sump-trajectory&indicator=evolution_cost_transportation&trend=projection&chart=evolution_cost_transportation&chart_filter=Household budget for Ferry/Water metro&schema_city=",
                                idColorPalette: configChartsColorPalettes["3"],
                                idColor: "7",
                                borderWidth: 1,
                                pointStyle: "circle",
                                pointRadius: 3,
                                spanGaps: true,
                                legendCustomCssClass: "chart-legend-custom-rectangle chart-legend-custom-color-44-62-80-dark-gray",
                                fill: true,
                                yAxisID: "y",
                                order: 2,
                                tooltipUnit: "",
                            },
                            {
                                type: "line",
                                label: "Metro",
                                data: [],
                                url:
                                    api_url +
                                    "charts/data/?part=sump-trajectory&indicator=evolution_cost_transportation&trend=projection&chart=evolution_cost_transportation&chart_filter=Household budget for Metro&schema_city=",
                                idColorPalette: configChartsColorPalettes["2"],
                                idColor: "2",
                                borderWidth: 1,
                                pointStyle: "circle",
                                pointRadius: 3,
                                spanGaps: true,
                                legendCustomCssClass: "chart-legend-custom-rectangle chart-legend-custom-color-52-152-219-medium-blue",
                                fill: true,
                                yAxisID: "y",
                                order: 2,
                                tooltipUnit: "",
                            },
                            {
                                type: "line",
                                label: "Train",
                                data: [],
                                url:
                                    api_url +
                                    "charts/data/?part=sump-trajectory&indicator=evolution_cost_transportation&trend=projection&chart=evolution_cost_transportation&chart_filter=Household budget for Train&schema_city=",
                                idColorPalette: configChartsColorPalettes["0"],
                                idColor: "0",
                                borderWidth: 1,
                                pointStyle: "circle",
                                pointRadius: 3,
                                spanGaps: true,
                                legendCustomCssClass: "chart-legend-custom-rectangle chart-legend-custom-color-101-69-132-purple",
                                fill: true,
                                yAxisID: "y",
                                order: 2,
                                tooltipUnit: "",
                            },
                            {
                                type: "line",
                                label: "Household budget dedicated to transportation in low-income families",
                                data: [],
                                url:
                                    api_url +
                                    "charts/data/?part=sump-trajectory&indicator=evolution_cost_transportation&trend=projection&chart=household_budget_transportation&schema_city=",
                                idColorPalette: configChartsColorPalettes["2"],
                                idColor: "4",
                                borderWidth: 1,
                                pointStyle: "circle",
                                pointRadius: 3,
                                spanGaps: true,
                                legendCustomCssClass: "chart-legend-custom-line-width-43px chart-legend-custom-color-230-126-34-orange",
                                fill: false,
                                yAxisID: "yRight",
                                order: 1,
                                tooltipUnit: "",
                            },
                        ],
                    },
                ],
            },
            co2Emissions: {
                checked: "true",
                idIndicator: "co2Emissions",
                titleIndicator: "CO2 emissions",
                popupInformation: {
                    status: "false",
                    text: popupInformation[part]["co2Emissions"],
                },
                keyFigure: {
                    label: "",
                    url: "",
                    separator: "",
                },
                charts: [
                    {
                        typeChart: "line",
                        multiYear: true,
                        titleChart: "",
                        titleDisplay: "true",
                        legendDisplay: "true",
                        legendCustom: "true",
                        legendLabelsBoxHeight: legendLabelsBoxHeightLine,
                        maintainAspectRatio: false,
                        heightChart: "300",
                        widthChart: "400",
                        scales: {
                            x: {
                                display: true,
                                stacked: false,
                                titleDisplay: false,
                                titleText: "",
                                gridDisplay: false,
                                beginAtZero: false,
                            },
                            y: {
                                display: true,
                                stacked: true,
                                titleDisplay: true,
                                titleText: "Emissions per mode (Kg CO2/year)",
                                gridDisplay: true,
                                beginAtZero: false,
                                max: null,
                            },
                            yRight: {
                                display: true,
                                stacked: false,
                                titleDisplay: true,
                                titleText:
                                    "Emissions per inhabitant (Kg Co2/year)",
                                    titleColor: yRightTitleColor,
                                gridDisplay: false,
                                beginAtZero: true,
                            },
                        },
                        keyFigure: {
                            label: "",
                            url: "",
                            separator: "",
                            position: "",
                        },
                        datasets: [
                            {
                                type: "line",
                                label: "Bus",
                                data: [],
                                url:
                                    api_url +
                                    "charts/data/?part=sump-trajectory&indicator=co2_emissions&trend=projection&chart=emissions_mode&chart_filter=Greenhouse gas emissions for Bus&schema_city=",
                                idColorPalette: configChartsColorPalettes["1"],
                                idColor: "3",
                                borderWidth: 1,
                                pointStyle: "circle",
                                pointRadius: 3,
                                spanGaps: true,
                                legendCustomCssClass: "chart-legend-custom-rectangle chart-legend-custom-color-63-184-216-light-blue",
                                fill: true,
                                yAxisID: "y",
                                order: 2,
                                tooltipUnit: "",
                            },
                            {
                                type: "line",
                                label: "Metro",
                                data: [],
                                url:
                                    api_url +
                                    "charts/data/?part=sump-trajectory&indicator=co2_emissions&trend=projection&chart=emissions_mode&chart_filter=Greenhouse gas emissions for Metro&schema_city=",
                                idColorPalette: configChartsColorPalettes["2"],
                                idColor: "2",
                                borderWidth: 1,
                                pointStyle: "circle",
                                pointRadius: 3,
                                spanGaps: true,
                                legendCustomCssClass: "chart-legend-custom-rectangle chart-legend-custom-color-52-152-219-medium-blue",
                                fill: true,
                                yAxisID: "y",
                                order: 2,
                                tooltipUnit: "",
                            },
                            {
                                type: "line",
                                label: "Ferry/Water metro",
                                data: [],
                                url:
                                    api_url +
                                    "charts/data/?part=sump-trajectory&indicator=co2_emissions&trend=projection&chart=emissions_mode&chart_filter=Greenhouse gas emissions for Ferry/Water metro&schema_city=",
                                idColorPalette: configChartsColorPalettes["3"],
                                idColor: "7",
                                borderWidth: 1,
                                pointStyle: "circle",
                                pointRadius: 3,
                                spanGaps: true,
                                legendCustomCssClass: "chart-legend-custom-rectangle chart-legend-custom-color-44-62-80-dark-gray",
                                fill: true,
                                yAxisID: "y",
                                order: 2,
                                tooltipUnit: "",
                            },
                            {
                                type: "line",
                                label: "Car",
                                data: [],
                                url:
                                    api_url +
                                    "charts/data/?part=sump-trajectory&indicator=co2_emissions&trend=projection&chart=emissions_mode&chart_filter=Greenhouse gas emissions for Car&schema_city=",
                                idColorPalette: configChartsColorPalettes["1"],
                                idColor: "2",
                                borderWidth: 1,
                                pointStyle: "circle",
                                pointRadius: 3,
                                spanGaps: true,
                                legendCustomCssClass: "chart-legend-custom-rectangle chart-legend-custom-color-250-50-150-pink",
                                fill: true,
                                yAxisID: "y",
                                order: 2,
                                tooltipUnit: "",
                            },
                            {
                                type: "line",
                                label: "Two Wheeler",
                                data: [],
                                url:
                                    api_url +
                                    "charts/data/?part=sump-trajectory&indicator=co2_emissions&trend=projection&chart=emissions_mode&chart_filter=Greenhouse gas emissions for Two-wheeler&schema_city=",
                                idColorPalette: configChartsColorPalettes["1"],
                                idColor: "4",
                                borderWidth: 1,
                                pointStyle: "circle",
                                pointRadius: 3,
                                spanGaps: true,
                                legendCustomCssClass: "chart-legend-custom-rectangle chart-legend-custom-color-156-46-132-fuchsia",
                                fill: true,
                                yAxisID: "y",
                                order: 2,
                                tooltipUnit: "",
                            },
                            {
                                type: "line",
                                label: "Auto Rickshaw",
                                data: [],
                                url:
                                    api_url +
                                    "charts/data/?part=sump-trajectory&indicator=co2_emissions&trend=projection&chart=emissions_mode&chart_filter=Greenhouse gas emissions for Three-wheeler&schema_city=",
                                idColorPalette: configChartsColorPalettes["1"],
                                idColor: "0",
                                borderWidth: 1,
                                pointStyle: "circle",
                                pointRadius: 3,
                                spanGaps: true,
                                legendCustomCssClass: "chart-legend-custom-rectangle chart-legend-custom-color-255-200-0-yellow",
                                fill: true,
                                yAxisID: "y",
                                order: 2,
                                tooltipUnit: "",
                            },
                            {
                                type: "line",
                                label: "Emissions per inhabitant per year (Kg Co2/ year)",
                                data: [],
                                url:
                                    api_url +
                                    "charts/data/?part=sump-trajectory&indicator=co2_emissions&trend=projection&chart=emissions_inhabitant&schema_city=",
                                idColorPalette: configChartsColorPalettes["2"],
                                idColor: "4",
                                borderWidth: 1,
                                pointStyle: "circle",
                                pointRadius: 3,
                                spanGaps: true,
                                legendCustomCssClass: "chart-legend-custom-line-width-30px chart-legend-custom-color-230-126-34-orange",
                                fill: false,
                                yAxisID: "yRight",
                                order: 1,
                                tooltipUnit: "",
                            },
                        ],
                    },
                ],
            },
        },
    },
});

export default dashboardSumpTrajectory.reducer;
