import React, { useEffect } from "react";
import SideBarMobilityIndicators from "./mobility-indicators/SideBarMobilityIndicators";
import MapWrapper from "./Map";
import DashboardMobilityIndicators from "./mobility-indicators/DashboardMobilityIndicators";
import "../styles/index.css";
import store from "../store";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { updateCurrentCity } from "../stores/Cities";

export default function MobilityIndicators() {
    const dispatch = useDispatch();
    const urlParams = useParams();
    const navigate = useNavigate();

    const listCities = Object.keys(store.getState().cities.listCities);

    // Check the validity of the city Name in the url
    useEffect(() => {
        const urlParamCityName = urlParams.cityName;
        const currentCity = store.getState().cities.currentCity;
        if (
            urlParamCityName !== currentCity &&
            listCities.includes(urlParamCityName)
        ) {
            // Update of the current city in the store
            dispatch(updateCurrentCity(urlParamCityName));
        } else {
            // Invalid city and no city yet explored
            if (currentCity === "") {
                navigate("/home");
            }
            // Redirection to the previous city explored
            else if (urlParamCityName !== currentCity) {
                navigate("/sump/mobility-indicators/" + currentCity);
            }
        }
    }, [urlParams, listCities, dispatch, navigate]);

    return (
        <div>
            <div className="main-container">
                <SideBarMobilityIndicators />
                <MapWrapper />
                <DashboardMobilityIndicators part="mobility-indicators"/>
            </div>
        </div>
    );
}
