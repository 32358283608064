import { createSlice } from "@reduxjs/toolkit";

const { api_url, config } = require("../settings");
const configChartsColorPalettes = config.charts.colorPalettes;
const { popupInformation } = require("../settings-popup-information");
const popupInformationSumpManagementSpeech = popupInformation.SumpManagement.Gantt.speech;
const defaultComponents = {
    "An integrated public transport network": {
        progressColor: configChartsColorPalettes["1"].borderColor[3],
        progressSelectedColor: configChartsColorPalettes["1"].borderColor[3],
    },
    "Modernization of individual transportation modes": {
        progressColor: configChartsColorPalettes["1"].borderColor[2],
        progressSelectedColor: configChartsColorPalettes["1"].borderColor[2],
    },
    "Modernization of paratransit sector": {
        progressColor: configChartsColorPalettes["1"].borderColor[0],
        progressSelectedColor: configChartsColorPalettes["1"].borderColor[0],
    },
    "A walkable and bicycle-friendly city for all": {
        progressColor: configChartsColorPalettes["1"].borderColor[5],
        progressSelectedColor: configChartsColorPalettes["1"].borderColor[5],
    },
    "Revision of the freight strategy": {
        progressColor: configChartsColorPalettes["1"].borderColor[4],
        progressSelectedColor: configChartsColorPalettes["1"].borderColor[4],
    },
    "Communication, sensitization, team building": {
        progressColor: configChartsColorPalettes["1"].borderColor[8],
        progressSelectedColor: configChartsColorPalettes["1"].borderColor[8],
    },
}

export const ganttSumpManagement = createSlice({
    name: "ganttSumpManagement",
    initialState: {
        url: api_url + "charts/data/gantt/?schema_city=",
        speech: popupInformationSumpManagementSpeech,
        cities: {
            kochi: {
                components: defaultComponents,
            },
            ahmedabad: {
                components: defaultComponents,
            },
            nagpur: {
                components: defaultComponents,
            },
        },
    },
});

export default ganttSumpManagement.reducer;
