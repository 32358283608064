import React from "react";
import { Box } from "@mui/material";

import "../styles/Credits.css";
const { credits } = require("../settings");

export default function Credits() {
  return (
    <div className="intro-text">
        <Box
            display = 'flex'
            justifyContent='center'
            p={1}
            m= {1}
            bgcolor="white"
        >
          <Box
            display="flex"
            width={'75%'}
            p={1}
          >
            <div className="home-speech" dangerouslySetInnerHTML={{ __html: credits.speech }}></div>
          </Box>
        </Box>
    </div>

  );
}
