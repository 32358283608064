import { useEffect, useState } from "react";
import { Gantt, ViewMode } from "gantt-task-react";
import "gantt-task-react/dist/index.css";
import "../../styles/GanttSumpManagement.css";
import store from "../../store";
import { useSelector } from "react-redux";
import PopupInformation from "../PopupInformation";
import { Box } from "@mui/material";

export default function GanttSumpManagement() {
    let storeCurrentCity = useSelector(
        (_state) => store.getState().cities.currentCity
    );

    const [urlApi] = useState([store.getState().ganttSumpManagement.url]);
    const [popupInformationSpeech] = useState([
        store.getState().ganttSumpManagement.speech,
    ]);
    const [citiesComponents] = useState([
        store.getState().ganttSumpManagement.cities,
    ]);
    const [currentComponents, setCurrentComponents] = useState([]);
    const [currentProjects, setCurrentProjects] = useState([]);

    // Retrieve data to generate the Gantt chart
    useEffect(() => {
        // Update data on components and projects
        let getData = async () => {
            // Fetch if a city has been selected
            if (storeCurrentCity.length !== 0) {
                let response = await fetch(urlApi + storeCurrentCity);

                // Valid api call
                if (response.status === 200) {
                    const data = await response.json();

                    const dataProjects = data.projects;

                    const dataComponents =
                        citiesComponents[0][storeCurrentCity].components;
                    let dataComponentsValid = [];
                    Object.keys(dataComponents).forEach(function (component) {
                        let projectComponent =
                            citiesComponents[0][storeCurrentCity].components[
                                component
                            ];
                        dataComponentsValid.push({
                            name: component,
                            color: projectComponent.progressColor,
                        });
                    });

                    let dataProjectsValid = [];
                    dataProjects.forEach(function (project) {
                        let projectComponent =
                            citiesComponents[0][storeCurrentCity].components[
                                project.component
                            ];
                        dataProjectsValid.push({
                            id: project.id,
                            name: project.project,
                            start: new Date(project.start),
                            end: new Date(project.end),
                            progress: project.progress,
                            type: "task",
                            styles: {
                                progressColor: projectComponent.progressColor,
                                progressSelectedColor:
                                    projectComponent.progressSelectedColor,
                            },
                        });
                    });
                    setCurrentComponents(dataComponentsValid);
                    setCurrentProjects(dataProjectsValid);
                }
                // No data for the Gantt chart or bad sql query on api side
                else {
                    setCurrentComponents([]);
                    setCurrentProjects([]);
                }
            }
        };
        getData();
    }, [storeCurrentCity, citiesComponents, urlApi]);

    return (
        <Box
            display="flex"
            justifyContent="center"
            p={0}
            bgcolor="white"
            width={"100%"}
            mt={1}
        >
            <Box
                // display="flex"
                width={"80%"}
                p={1}
                mt={"10px"}
            >
                <div className="gantt-sump-management-legend-container">
                    {currentComponents.map((component, i) => (
                        <div
                            className="gantt-sump-management-legend-row"
                            key={i}
                        >
                            <div
                                className="gantt-sump-management-legend-symbol"
                                style={{ backgroundColor: component.color }}
                            ></div>
                            <div className="gantt-sump-management-legend-name">
                                {component.name}
                            </div>
                        </div>
                    ))}
                </div>
                <Box marginLeft={2.5}>
                    <PopupInformation text={popupInformationSpeech} />
                </Box>
                <Box p={2}>
                    {currentProjects.length > 0 ? (
                        <div className="gantt-sump-management-chart">
                            <Gantt
                                tasks={currentProjects}
                                viewMode={ViewMode.Year}
                                listCellWidth=""
                                rowHeight={30}
                                columnWidth={70}
                                todayColor={"rgba(252, 248, 227, 1)"}
                                // TooltipContent={(task) => {
                                //     let taskName = task.task.name;
                                //     return <div>{taskName}</div>
                                // }}
                            />
                        </div>
                    ) : (
                        ""
                    )}
                </Box>
            </Box>
        </Box>
    );
}
