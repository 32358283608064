import { Route, Routes } from "react-router";
import "../styles/App.css";
import ResponsiveAppBar from "./AppBar";
import MobilityIndicators from "./MobilityIndicators";
import SumpTrajectory from "./SumpTrajectory";
import SumpManagement from "./SumpManagement";
import DigitalParticipation from "./DigitalParticipation";
import { Navigate } from "react-router-dom";
import Credits from "./Credits";
import { useEffect } from "react";

function App() {

    useEffect(() => {
        var _mtm = window._mtm = window._mtm || [];
        _mtm.push({'mtm.startTime': (new Date().getTime()), 'event': 'mtm.Start'});
        var d=document, g=d.createElement('script'), s=d.getElementsByTagName('script')[0];
        g.async=true; g.src='https://matomo.oslandia.io/js/container_EiCmBiYS.js'; s.parentNode.insertBefore(g,s);
    }, [])

    return (
        <div className="App">
            <div>
                <ResponsiveAppBar />
                <Routes>
                    <Route
                        path="/mobility-indicators"
                        element={<MobilityIndicators />}
                    >
                        <Route path=":cityName" element={<MobilityIndicators />} />
                    </Route>
                    <Route
                        path="/sump-trajectory"
                        element={<SumpTrajectory />}
                    >
                        <Route path=":cityName" element={<SumpTrajectory />} />
                    </Route>
                    <Route
                        path="/sump-management"
                        element={<SumpManagement />}
                    >
                        <Route path=":cityName" element={<SumpManagement />} />
                    </Route>
                    <Route
                        path="/digital-participation"
                        element={<DigitalParticipation />}
                    >
                        <Route path=":cityName" element={<DigitalParticipation />} />
                    </Route>
                    <Route
                        path="/credits"
                        element={<Credits />}
                    >
                    </Route>
                    <Route
                        path="/*"
                        element={
                            <Navigate replace to="/sump/mobility-indicators" />
                        }
                    ></Route>
                </Routes>
            </div>
        </div>
    );
}

export default App;
