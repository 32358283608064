import { Component } from "react";
import "../../styles/Dashboard.css";
import store from "../../store";
import Charts from "../Charts";
import PopupInformation from "../PopupInformation";

class SumpTrajectory extends Component {
    constructor(props) {
        super(props);

        this.part = props.part;

        this.state = {
            data: store.getState().dashboardSumpTrajectory.indicators,
        };

        this.listIndicators = [];
        for (let key of Object.keys(this.state.data)) {
            this.listIndicators.push(key);
        }

        store.subscribe(() => {
            this.setState({
                data: store.getState().dashboardSumpTrajectory.indicators,
            });
        });
    }

    render() {
        let statusIndicator = [];
        for (let ind of this.listIndicators) {
            statusIndicator[ind] = this.state.data[ind];
        }

        // Generic function to generate charts associated to a indicator
        const renderIndicatorCharts = (ind, _width = 200, _height = 180) => {
            if (ind.checked) {
                return (
                    <div className="dashboard-chart-container">
                        <div className="dashboard-chart-title-container">
                            <p className="dashboard-chart-title dashboard-chart-title-sump-trajectory">
                                {ind.titleIndicator}
                            </p>
                            <div>
                                <PopupInformation indicator={ind}/>
                            </div>
                        </div>

                        <Charts data={ind} part={this.part} />
                        {/* Empty div to separe the last chart of a section from the title of the next section */}
                        <div style={{ height: "30px" }}></div>
                    </div>
                );
            }
        };

        const listCharts = [];
        for (let key of Object.keys(this.state.data)) {
            listCharts.push(
                <div key={key}>
                    {renderIndicatorCharts(statusIndicator[key])}
                </div>
            );
        }

        return (
            <div className="dashboard-container-sump-trajectory">
                <div className="dashboard-content">
                    <div className="dashboard-charts-container dashboard-charts-container-sump-trajectory">
                        {listCharts}
                    </div>
                </div>
            </div>
        );
    }
}

export default SumpTrajectory;
