import GeoJSON from "ol/format/GeoJSON";
import { Tile as TileLayer, Vector as VectorLayer } from "ol/layer";
import Overlay from "ol/Overlay";
import Map from "ol/Map";
import "ol/ol.css";
import { fromLonLat } from "ol/proj";
import { OSM, Vector as VectorSource } from "ol/source";
import { Circle, Fill, Stroke, Style } from "ol/style";
import View from "ol/View";
import React, { Component } from "react";
import "../styles/Map.css";
import MapLegend from "./MapLegend";
import store from "../store";
import Stack from "@mui/material/Stack";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import IconButton from "@mui/material/IconButton";
import FullscreenIcon from "@mui/icons-material/Fullscreen";
import FullscreenExitIcon from "@mui/icons-material/FullscreenExit";
import { connect } from "react-redux";
import { reverseMapFullScreen } from "../stores/Map";
import { formatIndianNumberPopup } from "../utils";

const { config } = require("../settings");
const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

class MapWrapper extends Component {
    constructor(props) {
        super(props);

        this.configMap = config.map;
        this.state = {
            display: store.getState().map.display,
            fullScreen: store.getState().map.fullScreen,
            currentCity: store.getState().cities.currentCity,
            centerZoomCity: {
                zoom: [0, 0],
                center: 11,
            },
            data: store.getState().dashboardMobilityIndicators,
            alert: {
                open: false,
                layers: [],
            },
        };

        this.closeAlert = this.closeAlert.bind(this);

        store.subscribe(() => {
            this.setState({
                display: store.getState().map.display,
                fullScreen: store.getState().map.fullScreen,
                currentCity: store.getState().cities.currentCity,
                centerZoomCity:
                    store.getState().cities.listCities[
                        store.getState().cities.currentCity
                    ],
            });
        });
    }

    // Open the alert with the list of the layers that cannot be displayed
    openAlert(layerName) {
        this.setState((prevState) => ({
            alert: {
                ...prevState.alert,
                open: true,
                layers: [...prevState.alert.layers, layerName],
            },
        }));
    }
    // Close the alert with the list of the layers that cannot be displayed
    closeAlert() {
        this.setState((prevState) => ({
            alert: {
                ...prevState.alert,
                open: false,
            },
        }));
    }

    // Load layer
    async loadLayer(layer, layerId) {
        let currentCity = this.state.currentCity;
        let layerPopup = layer.popup;
        let layerPopupKeys = Object.keys(layerPopup);

        // Check if there is a specific configuration for this city
        if (layerPopupKeys.includes(currentCity) === true) {
            layerPopup = layerPopup[currentCity];
        } else {
            layerPopup = layerPopup["default"];
        }

        const layerPopupContent = layerPopup.content;
        const layerPopupSeparators = layerPopup.separators;
        const urlFieldsProperties =
            "&fields_properties=" +
            Object.keys(layerPopupContent).join("&fields_properties=");
        const response = await fetch(
            `${layer.url}${this.state.currentCity}${urlFieldsProperties}`
        )
            .then((response) => response.json())
            // API not available
            .catch((_error) => {});

        // API available but error in the url
        if (response === undefined || !response.data) {
            this.openAlert(layer.titleSideBar);
        }
        // Add the layer to the map
        else {
            const features = {
                type: "FeatureCollection",
                crs: {
                    type: "name",
                    properties: {
                        name: "EPSG:3857",
                    },
                },
                features: response.data,
            };
            const sourceLayer = new VectorSource({
                features: new GeoJSON().readFeatures(features),
            });

            let layerLegend = layer.legend;
            let layerLegendKeys = Object.keys(layerLegend);
            // Check if there is a specific configuration for this city
            if (layerLegendKeys.includes(currentCity) === true) {
                layerLegend = layerLegend[currentCity];
            } else {
                layerLegend = layerLegend["default"];
            }

            const styleLayer = (feature) => {
                let color = "";
                let strokeWidth = "";
                const attributeClassification = layerLegend.attribute;
                // Definition of the color and stroke width (the other properties are defined in the store, style key, FOR THE MOMENT)
                if (layer.type === "graduated") {
                    const featureValue = feature.get(attributeClassification);
                    for (const row of layerLegend.class) {
                        if (
                            featureValue >= row.min &&
                            featureValue <= row.max
                        ) {
                            color = row.color;
                            strokeWidth = row.strokeWidth;
                            break;
                        }
                    }
                } else {
                    color = layerLegend.class[0].color;
                    strokeWidth = layerLegend.class[0].strokeWidth;
                }

                const featureGeometry = feature.getGeometry().getType();
                const layerStyle = layer.style;
                const styleStroke = new Stroke({
                    color: layerStyle.strokeColor,
                    width: strokeWidth,
                    lineDash: layerStyle.lineDash,
                });
                const styleFill = new Fill({
                    color: color,
                });

                if (featureGeometry === "Point") {
                    return [
                        new Style({
                            image: new Circle({
                                radius: layerStyle.radius,
                                stroke: styleStroke,
                                fill: styleFill,
                            }),
                        }),
                    ];
                } else if (featureGeometry === "MultiLineString") {
                    return [
                        new Style({
                            stroke: styleStroke,
                        }),
                    ];
                } else {
                    return [
                        new Style({
                            stroke: styleStroke,
                            fill: styleFill,
                        }),
                    ];
                }
            };

            const vectorLayer = new VectorLayer({
                source: sourceLayer,
                style: styleLayer,
            });
            vectorLayer.set("id", layerId);
            vectorLayer.set("popupContent", layerPopupContent);
            vectorLayer.set("popupSeparators", layerPopupSeparators);
            vectorLayer.setZIndex(layer.zindex);
            if (layer.checked === false) {
                vectorLayer.setVisible(false);
            }
            this.map.addLayer(vectorLayer);
        }
    }

    // Loading of the layers associated with the selected city
    loadLayers() {
        let currentCity = this.state.currentCity;
        if (currentCity !== "") {
            for (let [keySection, section] of Object.entries(this.state.data)) {
                for (let [keyLayer, layer] of Object.entries(section.layers)) {
                    const layerId = keySection + keyLayer;
                    // Check that the layer is enabled
                    // Check that the layer is not excluded for this city
                    if (
                        layer.enable &&
                        !layer.excludeCities.includes(currentCity)
                    ) {
                        this.loadLayer(layer, layerId);
                    }
                }
            }

            this.loadLayer(
                store.getState().map.layerCityBoundary,
                "cityBoundary"
            );
        }
    }

    // Zoom and center the map on the current city
    updateMapView() {
        let currentCenterZoomCity =
            store.getState().cities.listCities[
                store.getState().cities.currentCity
            ];
        this.map.getView().setCenter(fromLonLat(currentCenterZoomCity.center));
        this.map.getView().setZoom(currentCenterZoomCity.zoom);
    }

    componentDidMount() {
        this.map = new Map({
            target: "map-container",
            layers: [
                new TileLayer({
                    source: new OSM(),
                }),
            ],
            view: new View({
                center: fromLonLat(this.state.centerZoomCity.center),
                zoom: this.state.centerZoomCity.zoom,
            }),
        });

        // Avoids a blank map when there is a city in the store
        // (e.g. when you switch from the sump trajectory part to mobility indicators)
        if (this.state.currentCity !== "") {
            this.updateMapView();
        }

        // Loading of the layers
        this.loadLayers();

        // Popup overlay
        const container = document.getElementById("popup");
        const content = document.getElementById("popup-content");
        this.popupOverlay = new Overlay({
            element: container,
            autoPan: false,
            // autoPan: {
            //     animation: {
            //         duration: 250,
            //     },
            // },
        });
        this.map.addOverlay(this.popupOverlay);

        this.map.on("pointermove", (event) => {
            let val = undefined;
            let listPopup = [];
            let contentPopup = [];
            this.map.forEachFeatureAtPixel(
                event.pixel,
                (feature, _layer) => {
                    // Retrieval and creation of the popup content
                    let layerPopupContent = _layer.get("popupContent");
                    let layerPopupSeparators = _layer.get("popupSeparators");
                    let layerZIndex = _layer.get("zIndex");
                    let contentPopupPixel = [];
                    for (let [field, label] of Object.entries(
                        layerPopupContent
                    )) {
                        val = feature.get(field);
                        if (val) {
                            val = formatIndianNumberPopup(
                                val,
                                field,
                                layerPopupSeparators
                            );
                            contentPopupPixel.push(`<div class="popup-row">
                    <div class="popup-label">${label}</div>
                    <div class="popup-value">${val}</div>
                </div>`);
                        }
                    }

                    if (contentPopupPixel.length > 0) {
                        contentPopup.push(contentPopupPixel);
                        listPopup.push({
                            zIndex: layerZIndex,
                            popup: contentPopupPixel,
                        });
                    }
                },
                {
                    hitTolerance: 5,
                }
            );

            // Display the popup of the layer with the highest zIndex
            let sortListPopup = listPopup.sort((a, b) => b.zIndex - a.zIndex);
            if (contentPopup.length > 0) {
                content.innerHTML = sortListPopup[0].popup.join("");
                this.popupOverlay.setPosition(event.coordinate);
            } else {
                this.popupOverlay.setPosition(undefined);
            }
        });
    }

    // Update the visibility of the layers
    componentDidUpdate(_prevProps, prevState) {
        if (this.state.display === true) {
            this.map.updateSize();
        }

        if (prevState.currentCity !== store.getState().cities.currentCity) {
            // Loading of the layers of the new selected city
            this.loadLayers();

            // Zoom and center on the new city
            this.updateMapView();
        }

        for (let [keySection, section] of Object.entries(this.state.data)) {
            for (let [keyLayer, _layer] of Object.entries(section.layers)) {
                const layerChecked =
                    store.getState().dashboardMobilityIndicators[keySection]
                        .layers[keyLayer].checked;
                if (
                    prevState.data[keySection].layers[keyLayer].checked !==
                    layerChecked
                ) {
                    const layerId = keySection + keyLayer;
                    this.map.getLayers().forEach(function (layer) {
                        if (layer.get("id") === layerId) {
                            if (layerChecked) {
                                layer.setVisible(true);
                            } else {
                                layer.setVisible(false);
                            }
                        }
                    });
                    this.setState({
                        data: store.getState().dashboardMobilityIndicators,
                    });
                }
            }
        }
    }

    render() {
        // Classes related to the map container
        let classNameMap =
            this.state.display === true
                ? "map-container"
                : "map-container-hidden";
        classNameMap =
            this.state.fullScreen === true
                ? classNameMap + " map-container-full-screen"
                : classNameMap;

        // Classes related to the map question contaienr
        let classNameMapQuestion = "map-question-container";
        classNameMapQuestion =
            this.state.fullScreen === true
                ? classNameMapQuestion + " map-question-container-full-screen"
                : classNameMapQuestion;

        // If one theme is display, display the associated question
        let arrayCheckedStatusTreeviewsIndicators = [];
        for (let section of Object.values(
            store.getState().dashboardMobilityIndicators
        )) {
            if (section.treeviews.indicators.checked !== 0) {
                arrayCheckedStatusTreeviewsIndicators.push(
                    section.questionSection
                );
            }
        }
        const textQuestionSection =
            arrayCheckedStatusTreeviewsIndicators.length === 1
                ? arrayCheckedStatusTreeviewsIndicators[0].split("?")
                : [];
        // Replacement of strings in questions
        for (var i = 0; i < textQuestionSection.length; i++) {
            textQuestionSection[i] = textQuestionSection[i].replace(
                "REPLACECITYNAME",
                this.state.currentCity.charAt(0).toUpperCase() +
                    this.state.currentCity.slice(1)
            );
        }
        const visibilitytextQuestionSection =
            this.state.display === true ? "visible" : "hidden";

        // Full screen mode
        const iconFullScreen =
            this.state.fullScreen === false ? (
                <IconButton
                    title="Full screen"
                    onClick={this.props.handleReverseFullScreenMode}
                >
                    <FullscreenIcon
                        className="map-icon-screen"
                        fontSize="medium"
                    ></FullscreenIcon>
                </IconButton>
            ) : (
                <IconButton
                    title="Exit full screen mode"
                    onClick={this.props.handleReverseFullScreenMode}
                >
                    <FullscreenExitIcon
                        className="map-icon-screen"
                        fontSize="medium"
                    ></FullscreenExitIcon>
                </IconButton>
            );

        return (
            <div
                id="map-container"
                className={classNameMap}
                style={{ visibility: visibilitytextQuestionSection }}
            >
                {/* Display full screen icon */}
                <div className="map-icon">{iconFullScreen}</div>
                {/* Display an error with the list of the layers that cannot be displayed (API error)*/}
                <div>
                    <Stack spacing={2} sx={{ width: "100%" }}>
                        <Snackbar
                            open={this.state.alert.open}
                            autoHideDuration={5000}
                            onClose={this.closeAlert}
                        >
                            <Alert
                                onClose={this.closeAlert}
                                severity="error"
                                sx={{ width: "100%" }}
                            >
                                {"Unable to load the following layers: " +
                                    this.state.alert.layers.join(", ")}
                            </Alert>
                        </Snackbar>
                    </Stack>
                </div>
                {/* Display the question(s) associated with a section */}
                <div className={classNameMapQuestion}>
                    {arrayCheckedStatusTreeviewsIndicators.length === 1
                        ? textQuestionSection.map((question, i) => (
                              <p key={i} className="map-question">
                                  {question.length > 0 ? question + "?" : ""}
                              </p>
                          ))
                        : ""}
                </div>
                {/* Display the legend(s) */}
                <div>
                    <MapLegend />
                </div>
                <div id="popup" className="ol-popup">
                    <div id="popup-content"></div>
                </div>
            </div>
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        // Reverse the full screen mode
        handleReverseFullScreenMode: () => {
            dispatch(reverseMapFullScreen());
        },
    };
};

export default connect(null, mapDispatchToProps)(MapWrapper);
