import { Component } from "react";
import DashboardIndicator from "./DashboardIndicator";
import "../../styles/Dashboard.css";
import store from "../../store";
import logo from "../../img/logo-large.png";

class DashboardMobilityIndicators extends Component {
    constructor(props) {
        super(props);

        this.part = props.part;

        this.state = store.getState().map;

        store.subscribe(() => {
            this.setState(store.getState().map);
        });
    }

    render() {
        // Classes related to the map container
        let classNameDashboard = "dashboard-container";
        classNameDashboard =
        this.state.display === false
            ? classNameDashboard + " dashboard-container-no-map"
            : classNameDashboard;
        classNameDashboard =
            this.state.fullScreen === true
                ? classNameDashboard + " dashboard-container-map-full-screen"
                : classNameDashboard;

        // Number of unchecked indicators treeviews
        let arrayCheckedStatusTreeviewsIndicators = [];
        for (let section of Object.values(
            store.getState().dashboardMobilityIndicators
        )) {
            if (section.treeviews.indicators.checked !== 0) {
                arrayCheckedStatusTreeviewsIndicators.push(
                    section.treeviews.layers.checked
                );
            }
        }

        return (
            <div className={classNameDashboard}>
                {/* At least one indicator is selected */}
                {arrayCheckedStatusTreeviewsIndicators.length !== 0 && (
                    <div>
                        <DashboardIndicator id="urbanStructure" part="mobility-indicators"/>
                        <DashboardIndicator id="mobilityDemand" part="mobility-indicators"/>
                        <DashboardIndicator id="transportSupply" part="mobility-indicators"/>
                        <DashboardIndicator id="motorisation" part="mobility-indicators"/>
                        <DashboardIndicator id="externalities" part="mobility-indicators"/>
                        <DashboardIndicator id="economicsMobility" part="mobility-indicators"/>
                    </div>
                )}
                {/* No indicator selected: display a message insteaf of a blank screen */}
                {arrayCheckedStatusTreeviewsIndicators.length === 0 && (
                    <div className="dashboard-container-empty">
                        <div className="dashboard-container-empty-subcontainer">
                            <p className="dashboard-container-empty-message">
                                Select at least one indicator in the side panel
                                to display the mobility indicators
                            </p>
                            <img
                                className="dashboard-container-empty-logo"
                                src={logo}
                                alt="logo"
                            />
                        </div>
                    </div>
                )}
            </div>
        );
    }
}

export default DashboardMobilityIndicators;
