import { Component } from "react";
import InfoIcon from "@mui/icons-material/Info";
import "../styles/PopupInformation.css";
import parse from "html-react-parser";

class PopupInformation extends Component {
    constructor(props) {
        super(props);

        this.indicator = props.indicator;
        this.text = props.text;
        let displayStatus = false;
        if (this.indicator) {
            this.popupInformation = this.indicator.popupInformation;
            this.popupInformationText = this.popupInformation.text;
            this.popupInformationTextSpeech = this.popupInformationText.speech;
            this.popupInformationTextSource = this.popupInformationText.source;
            displayStatus = this.popupInformation.status;
        }
        this.state = {
            display: displayStatus,
        };
    }

    // Reverse the display status of the information popup of the indicator when hovering on the icon
    onPopupInformationHover() {
        this.setState({
            display: "true",
        });
    }

    // Reverse the display status of the information popup of the indicator when hovering off the icon
    onPopupInformationLeave() {
        this.setState({
            display: "false",
        });
    }

    render() {
        // Information popup about the indicator
        const renderPopupInformationText = () => {
            if (this.state.display === "true") {
                if (this.text) {
                    return (
                        <div className="popup-information-container">
                            <div className="popup-information-single-text">
                                <div
                                    dangerouslySetInnerHTML={{
                                        __html: this.text,
                                    }}
                                />
                            </div>
                        </div>
                    );
                } else {
                    return (
                        <div className="popup-information-container">
                            <div className="">
                                <p className="popup-information-title">
                                    {this.indicator.titleIndicator}
                                </p>
                            </div>
                            <div className="popup-information-section-container">
                                <p className="popup-information-text">
                                    {parse(this.popupInformationTextSpeech)}
                                </p>
                            </div>
                            {this.popupInformationTextSource.length > 0 && (
                                <div className="popup-information-section-container">
                                    <p className="popup-information-section">
                                        Data source
                                    </p>
                                    <p className="popup-information-text">
                                        {this.popupInformationTextSource}
                                    </p>
                                </div>
                            )}
                        </div>
                    );
                }
            }
        };

        // Generic function to generate the information popup
        const renderPopupInformation = () => {
            return (
                <div>
                    <div
                        onMouseEnter={(e) => this.onPopupInformationHover(e)}
                        onMouseLeave={(e) => this.onPopupInformationLeave(e)}
                    >
                        <InfoIcon
                            sx={{ color: "#357aa4", fontSize: 27 }}
                        ></InfoIcon>
                        <div>{renderPopupInformationText()}</div>
                    </div>
                </div>
            );
        };

        const renderPopup = renderPopupInformation();

        return <div className="popup-information-icon">{renderPopup}</div>;
    }
}

export default PopupInformation;
